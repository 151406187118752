import * as Http from './Http';
import * as Errors from './Errors';
import { ApplicationError } from './Errors';
import * as Result from './Result';
import {
    AdvancedDistribution,
    AnalysisCostBenefit,
    AnalysisLeadPerformance,
    AnalysisMakeModel,
    AnalysisOverview,
    ComparisonAdvicePrice,
    CompetingVehicle,
    CompetitionProfile,
    CompetitionProfileDealer,
    CompetitionProfileDraft,
    Dealer,
    DealerDashboard,
    DealerLeads,
    DealerLeadsSorting,
    DealerProfileFiltersMetadata,
    DealerReach,
    Distribution,
    NotificationSettings,
    Prospects,
    Scenario,
    ScenarioCluster,
    ScenarioDraft,
    ScenarioSnippet,
    StockSettings,
    Taxation,
    TaxationComparisonGeographic,
    TaxationComparisonPriceHistory,
    TaxationSettings,
    Vehicle,
    VehicleAlertType,
    VehicleComparisonGeographic,
    VehicleComparisonPriceHistory,
    VehicleCompetition,
    VehicleDashboard,
    VehicleReachOverview,
    VehicleReachPriceEffect,
    VehicleReachPriceEffectType,
    VehicleSettings,
} from './Model';
import { apiDomain } from './Environment';
import * as JD from './Json/Decode';
import { WithRequired } from './TypeUtil';

//---- Fetch ----//

const defaultHeaders = (locale: string): Headers =>
    new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': locale,
    });

const authorizedHeaders = (locale: string, token: string): Headers => {
    const headers = defaultHeaders(locale);
    headers.append('Authorization', `Bearer ${token}`);
    return headers;
};

type UndefinedKeys<T> = {
    [K in keyof T]: T[K] extends undefined ? never : K;
}[keyof T];

type WithoutUndefinedKeys<T> = Omit<T, UndefinedKeys<T>>;

function removeUndefinedFromRecord<record extends Record<string, unknown>>(
    record: record,
): WithoutUndefinedKeys<record> {
    const clonedRecord = structuredClone(record);

    Object.keys(clonedRecord).forEach(
        (key) => clonedRecord[key] === undefined && delete clonedRecord[key],
    );

    return clonedRecord;
}

//---- Requests ----//

const AtaApiEndpoints = {
    session: (): string => apiDomain + '/info',

    authenticate: (): string => apiDomain + '/oauth/access_token',
    authenticateSHA: (): string => apiDomain + `/login`,

    settingsStock: (): string => apiDomain + '/settings_stock/0',
    settingsCompetingVehicles: (id: string): string =>
        apiDomain + `/settings_competingvehicles/${id}`,
    settingsNotifications: (): string =>
        apiDomain + '/settings_notifications/0',
    settingsDealerProfiles: (): string =>
        apiDomain + '/settings_competingdealers',
    settingsDealerProfile: (id: string): string =>
        apiDomain + `/settings_competingdealers/${id}`,
    settingsDealerFilter: (): string => apiDomain + '/settings_dealerfilter/0',
    settingsDealerFilterScenario: (id: string): string =>
        apiDomain + `/settings_dealerfilter/${id}`,
    settingsScenarioDealers: (): string => apiDomain + `/settings_dealers`,
    settingsScenarios: (): string => apiDomain + `/settings_scenarios`,
    settingsScenario: (id: string): string =>
        apiDomain + `/settings_scenarios/${id}`,
    settingsTestMail: (): string => apiDomain + '/settings_notificationsmail/0',

    dealerSearch: (): string => apiDomain + '/settings_competingdealers_search',
    dealerVehicles: (): string => apiDomain + '/stock',
    dealerCompetition: (): string => apiDomain + '/competition',
    dealerDashboard: (): string => apiDomain + '/dashboard',
    dealerReach: (): string => apiDomain + '/reach',
    dealerLeads: (): string => apiDomain + '/leads',
    dealerAnalysisCostBenefit: (): string => apiDomain + '/costbenefits',
    dealerAnalysisStock: (): string => apiDomain + '/analysisstock',
    dealerAnalysisMakeModel: (): string => apiDomain + '/analysismakemodel',
    dealerAnalysisLeadsPerformance: (): string =>
        apiDomain + '/analysisperformance',
    dealerProspects: (): string => apiDomain + '/prospects',

    vehicle: (id: string): string => apiDomain + `/vehicle/${id}`,
    vehicleDashboard: (id: string): string =>
        apiDomain + `/vehicle/${id}/dashboard`,
    vehicleReach: (id: string): string => apiDomain + `/vehicle/${id}/reach`,
    vehicleCompetition: (id: string): string =>
        apiDomain + `/vehicle/${id}/competingvehicles`,
    vehiclePriceEffect: (id: string): string =>
        apiDomain + `/vehicle/${id}/priceeffect`,

    vehiclePriceHistory: (id: string): string =>
        apiDomain + `/vehicle/${id}/pricehistory`,
    vehicleGeography: (id: string): string =>
        apiDomain + `/vehicle/${id}/geography`,

    taxation: (id?: string): string =>
        apiDomain + (id ? `/taxation/${id}` : '/taxation'),
    taxationDashboard: (id: string): string =>
        apiDomain + `/taxation/${id}/dashboard`,
    taxationReach: (id: string): string => apiDomain + `/taxation/${id}/reach`,
    taxationCompetition: (id: string): string =>
        apiDomain + `/taxation/${id}/competingvehicles`,

    taxationPriceHistory: (id: string): string =>
        apiDomain + `/taxation/${id}/pricehistory`,
    taxationGeography: (id: string): string =>
        apiDomain + `/taxation/${id}/geography`,
    taxationSettings: (id: string): string =>
        apiDomain + `/settings_taxations/${id}`,

    vehicleMakes: (): string => apiDomain + '/vehiclemake',
    vehicleModels: (): string => apiDomain + '/vehiclemodel',
    settingsDealerProfileFilters: (): string =>
        apiDomain + '/settings_competingdealers_ui',

    // TODO: remove when competition arrives in one call
    dealerCompetitionPrice: (): string => apiDomain + '/comparedealerprofiles',
    dealerCompetitionGeo: (): string =>
        apiDomain + '/comparedealerprofilesgeography',
};

const dateDecoder: JD.Decoder<Date> = JD.map(
    [JD.oneOf([JD.number, JD.string])],
    (a) => new Date(a),
);

const valueDecoder = <a>(
    decoder: JD.Decoder<a>,
): JD.Decoder<{ value: a; humanvalue: string }> =>
    JD.record({
        value: decoder,
        humanvalue: JD.string,
    });

const extractValueDecoder = <a>(decoder: JD.Decoder<a>): JD.Decoder<a> =>
    JD.field('value', decoder);

const vehicleDecoder: JD.Decoder<Vehicle> = JD.record({
    advertisementquality: valueDecoder(JD.number),
    advertisementqualityitems: valueDecoder(
        JD.record({
            accuracy: JD.array(JD.string),
            nap: JD.array(JD.string),
            photo: JD.array(JD.string),
            warranty: JD.array(JD.string),
        }),
    ),
    alerts: extractValueDecoder(
        JD.array(
            JD.oneOf([
                JD.map(
                    [JD.literal('Prijs gewijzigd')],
                    (_) => 'lastupdate' as VehicleAlertType,
                ),
                JD.map(
                    [JD.literal('Statijd')],
                    (_) => 'daysinstock' as VehicleAlertType,
                ),
                JD.map(
                    [JD.literal('Bekeken per dag')],
                    (_) => 'viewed' as VehicleAlertType,
                ),
            ]),
        ),
    ),
    aturl: valueDecoder(JD.string),
    body: JD.record({
        value: JD.string,
        humanvalue: JD.string,
        label: JD.string,
    }),
    btwmarge: valueDecoder(JD.string),
    clicks: JD.record({
        daily: valueDecoder(JD.number),
        total: valueDecoder(JD.number),
        total15: valueDecoder(JD.number),
    }),
    daysinstock: JD.record({
        amount: valueDecoder(JD.number),
        benchmarkaverage: valueDecoder(JD.number),
    }),
    dealer: JD.record({
        name: valueDecoder(JD.string),
    }),
    enginecapacity: JD.record({
        value: JD.string,
        humanvalue: JD.string,
        label: JD.string,
    }),
    enginepowerhp: JD.record({
        value: JD.string,
        humanvalue: JD.string,
        label: JD.string,
    }),
    found: JD.record({
        amount: valueDecoder(JD.number),
        amount15: valueDecoder(JD.number),
        daily: valueDecoder(JD.number),
    }),
    fueltype: JD.record({
        value: JD.string,
        humanvalue: JD.string,
        label: JD.string,
    }),
    id: valueDecoder(JD.string),
    isnew: valueDecoder(JD.bool),
    lastdateofownership: JD.record({
        value: JD.string,
        humanvalue: JD.string,
        label: JD.string,
    }),
    licenseplate: valueDecoder(JD.string),
    licenseplateformatted: valueDecoder(JD.string),
    maintenancebooklet: valueDecoder(JD.bool),
    make: valueDecoder(JD.string),
    manufacture: JD.record({
        bouwjaar: valueDecoder(JD.string),
        month: valueDecoder(JD.string),
        year: JD.record({
            value: JD.string,
            humanvalue: JD.string,
            label: JD.string,
        }),
    }),
    mileage: valueDecoder(JD.number),
    model: valueDecoder(JD.string),
    nap: valueDecoder(JD.bool),
    numberofdoors: JD.record({
        value: JD.string,
        humanvalue: JD.string,
        label: JD.string,
    }),
    occasiontype: JD.oneOf([JD.string, JD.succeed(null)]),
    paintcolor: JD.record({
        value: JD.string,
        humanvalue: JD.string,
        label: JD.string,
    }),
    photo15askingprice: valueDecoder(JD.number),
    photo15date: valueDecoder(JD.string),
    photo: valueDecoder(JD.string),
    photocount: valueDecoder(JD.number),
    price: JD.record({
        asking: valueDecoder(JD.number),
        changes: valueDecoder(JD.number),
        lastupdate: JD.record({ days: valueDecoder(JD.number) }),
        lowest: valueDecoder(JD.number),
        lowestdealer: valueDecoder(JD.string),
        recommended: JD.record({
            difference: JD.record({
                amount: valueDecoder(JD.number),
                percentage: valueDecoder(JD.number),
            }),
            amount: valueDecoder(JD.number),
        }),
    }),
    pricedetails: valueDecoder(
        JD.oneOf([
            JD.record({
                info: JD.string,
                options: JD.nullable(
                    JD.record({
                        total: JD.record({
                            label: JD.string,
                            humanvalue: JD.string,
                        }),
                        residualvalue: JD.record({
                            label: JD.string,
                            humanvalue: JD.string,
                        }),
                    }),
                ),
                price: JD.record({
                    residualvalue: JD.record({
                        label: JD.string,
                        humanvalue: JD.string,
                    }),
                    total: JD.record({
                        label: JD.string,
                        humanvalue: JD.string,
                    }),
                }),
                recommendedprice: JD.record({
                    label: JD.string,
                    humanvalue: JD.string,
                }),
            }),
            JD.succeed(null),
        ]),
    ),
    qpq_sold: valueDecoder(JD.number),
    recalc: valueDecoder(JD.bool),
    similarvehicles: valueDecoder(JD.number),
    similarvehiclesissetting: valueDecoder(JD.bool),
    transmission: JD.record({
        value: JD.string,
        humanvalue: JD.string,
        label: JD.string,
    }),
    trimpackage: valueDecoder(JD.string),
    video: JD.record({
        value: JD.string,
        humanvalue: JD.string,
        label: JD.string,
    }),
    viewed: JD.record({
        amount: valueDecoder(JD.number),
        amount15: valueDecoder(JD.number),
        daily: valueDecoder(JD.number),
    }),
    warranties: JD.record({
        count: valueDecoder(JD.number),
        items: valueDecoder(JD.array(JD.string)),
    }),
});

const competingVehicleDecoder: JD.Decoder<CompetingVehicle> = JD.record({
    aturl: valueDecoder(JD.string),
    clicks: JD.record({
        daily: valueDecoder(JD.number),
        total: valueDecoder(JD.number),
        total15: valueDecoder(JD.number),
    }),
    daysinstock: JD.record({
        amount: valueDecoder(JD.number),
        benchmarkaverage: valueDecoder(JD.number),
    }),
    dealer: JD.record({
        name: valueDecoder(JD.string),
    }),
    found: JD.record({
        amount: valueDecoder(JD.number),
        amount15: valueDecoder(JD.number),
        daily: valueDecoder(JD.number),
    }),
    id: valueDecoder(JD.string),
    licenseplate: valueDecoder(JD.string),
    make: valueDecoder(JD.string),
    manufacture: JD.record({
        bouwjaar: valueDecoder(JD.string),
        month: valueDecoder(JD.string),
        year: JD.record({
            value: JD.string,
            humanvalue: JD.string,
            label: JD.string,
        }),
    }),
    mileage: valueDecoder(JD.number),
    model: valueDecoder(JD.string),
    nap: valueDecoder(JD.bool),
    occasiontype: valueDecoder(JD.string),
    photo: valueDecoder(JD.string),
    photocount: valueDecoder(JD.number),
    price: JD.record({
        asking: valueDecoder(JD.number),
        changes: valueDecoder(JD.number),
        lastupdate: JD.record({ days: valueDecoder(JD.number) }),
        lowest: valueDecoder(JD.number),
        lowestdealer: valueDecoder(JD.string),
        recommended: JD.record({
            difference: JD.record({
                amount: valueDecoder(JD.number),
                percentage: valueDecoder(JD.number),
            }),
            amount: valueDecoder(JD.number),
        }),
    }),
    trimpackage: valueDecoder(JD.string),
    viewed: JD.record({
        amount: valueDecoder(JD.number),
        amount15: valueDecoder(JD.number),
        daily: valueDecoder(JD.number),
    }),
    warranties: JD.record({
        count: valueDecoder(JD.number),
        items: valueDecoder(JD.array(JD.string)),
    }),
});

export function authenticateWithToken<msg>(
    payload: {
        token: string;
        dealer?: string;
        ma?: string;
        session?: string;
        reference?: string;
        type?: string;
        master: boolean;
    },
    toMsg: (value: {
        accessToken: string;
        accountName: string;
        accountType: 'Dealer' | 'Holding' | 'Group';
        isMasterAccount: boolean;
    }) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        headers: defaultHeaders('nl-NL'),
        path: AtaApiEndpoints.authenticateSHA(),
        method: Http.RequestMethod.Get,
        query: removeUndefinedFromRecord({
            SHA: payload.token,
            d: payload.dealer,
            ma: payload.ma,
            sessie: payload.session,
            rf: payload.reference,
            t: payload.type,
            master: payload.master ? '1' : '0',
        }) as Record<string, string>,
        expect: async (response) => {
            const data = (await response.json())?.data.data;
            return toMsg({
                accountName: data['accountname'] as string,
                accountType: ((): 'Dealer' | 'Holding' | 'Group' => {
                    switch (data['accounttype']) {
                        case 1:
                            return 'Dealer';
                        case 2:
                            return 'Holding';
                        case 3:
                            return 'Group';
                        default:
                            throw new Error('Unknown account type');
                    }
                })(),
                accessToken: data['access_token'] as string,
                isMasterAccount: data['ismaster'] as boolean,
            });
        },
    });
}

export function authenticateWithCredentials<msg>(
    username: string,
    password: string,
    captcha: string | null,
    toMsg: (
        value: Result.Result<
            { accessToken: string; expiration: Date },
            ApplicationError
        >,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        headers: defaultHeaders('nl-NL'),
        path: AtaApiEndpoints.authenticate(),
        method: Http.RequestMethod.Post,
        query: {},
        body: removeUndefinedFromRecord({
            grant_type: 'password',
            client_id: 'autotrack-web',
            client_secret: 'JBDy7q#LkphkT@F28XDtG=2-8Fx%HBN!^szzhQ7vgA',
            scope: 'web',
            username: username,
            password: password,
            'g-recaptcha-response': captcha ?? undefined,
        }) as Record<string, string>,
        expect: async (response) => {
            if (!response.ok) {
                if (response.status >= 400 && response.status < 500) {
                    try {
                        const json = await response.json();
                        const errors = JD.field(
                            'errors',
                            JD.array(
                                JD.record({
                                    error: JD.number,
                                    error_description: JD.string,
                                }),
                            ),
                        )(json);

                        if (
                            errors.find((error) => error.error === 901) !==
                            undefined
                        ) {
                            return toMsg(Result.err(Errors.captchaRequired()));
                        }

                        return toMsg(
                            Result.err(Errors.loginWithCredentialsFailed()),
                        );
                    } catch {
                        return toMsg(
                            Result.err(Errors.loginWithCredentialsFailed()),
                        );
                    }
                } else {
                    return toMsg(Result.err(Errors.unexpectedError()));
                }
            }

            const json = await response.json();

            const data = json?.data;
            return toMsg(
                Result.ok({
                    accessToken: data['access_token'] as string,
                    expiration: new Date(
                        Date.now() + data['expires_in'] * 1000,
                    ),
                }),
            );
        },
    });
}

export function retrieveSession<msg>(
    authentication: string,
    toMsg: (
        value: Result.Result<
            {
                accountId: string;
                accountName: string;
                accountType: 'Dealer' | 'Holding' | 'Group';
                isPremiumAccount: boolean;
                isReachFiltered: boolean;
                totalStock: number;
                filteredStock: number;
                hasTaxation: boolean;
                lastUpdate: Date;
            },
            ApplicationError
        >,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        headers: authorizedHeaders('nl-NL', authentication),
        path: AtaApiEndpoints.session(),
        method: Http.RequestMethod.Get,
        query: {},
        expect: async (response) => {
            const data = (await response.json())?.data;
            return toMsg(
                Result.ok({
                    accountId: String(data['accountid']),
                    accountName: String(data['accountname']),
                    accountType: ((): 'Dealer' | 'Holding' | 'Group' => {
                        switch (data['accounttype']) {
                            case 1:
                                return 'Dealer';
                            case 2:
                                return 'Holding';
                            case 3:
                                return 'Group';
                            default:
                                throw new Error('Unknown account type');
                        }
                    })(),
                    // autotracksession: null
                    isPremiumAccount: Boolean(data['premium']),
                    isReachFiltered: Boolean(data['reachfiltered']),
                    totalStock: Number(data['stock']['total']),
                    filteredStock: Number(data['stock']['filtered']),
                    hasTaxation: Boolean(data['taxation']),
                    lastUpdate: new Date(data['lastupdate'] * 1000),
                }),
            );
        },
    });
}

export function unauthenticate<msg>(
    authentication: string,
    toMsg: (wasSuccessful: boolean) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        headers: authorizedHeaders('nl-NL', authentication),
        path: AtaApiEndpoints.authenticate(),
        method: Http.RequestMethod.Delete,
        query: {},
        expect: async (response) => {
            return toMsg(response.ok);
        },
    });
}

export function searchVehicle<msg>(
    authentication: string,
    query: string,
    toMsg: () => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.dealerVehicles(),
        method: Http.RequestMethod.Get,
        query: {
            limit: String(10),
            search: query,
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async () => toMsg(),
    });
}

/** Retrieves list of vehicle makes */
export function retrieveVehicleMakes<msg>(
    authentication: string,
    toMsg: () => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.vehicleMakes(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async () => toMsg(),
    });
}

/** Retrieves list of vehicle models */
export function retrieveVehicleModels<msg>(
    authentication: string,
    toMsg: () => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.vehicleModels(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async () => toMsg(),
    });
}

/**
 * Retrieves a dealer's vehicles
 *
 * @param authentication
 * @param options
 * @param toMsg
 */
export function retrieveDealerVehicles<msg>(
    authentication: string,
    options: {
        page: number;
        amountPerPage: number;
        sort: string;
        sortDirection?: string;
        search?: string;
        onlyWithWarnings?: boolean;
    }, //TODO: ApiListOptions
    toMsg: (
        result: Result.Result<
            { vehicles: Vehicle[]; totalVehicles: number },
            Errors.ApplicationError
        >,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.dealerVehicles(),
        method: Http.RequestMethod.Get,
        query: removeUndefinedFromRecord({
            crr_page: String(options.page),
            limit: String(options.amountPerPage),
            col: options.sort,
            sort: options.sortDirection ?? 'ASC',
            search: options?.search,
            filter: options?.onlyWithWarnings ? 'alerts' : undefined,
        }) as Record<string, string>,
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const data = (await response.json())?.data;

            const decoder = JD.map(
                [
                    JD.oneOf([
                        JD.field('vehicle', JD.array(vehicleDecoder)),
                        JD.succeed([]),
                    ]),
                    JD.field('vehicle_count', extractValueDecoder(JD.number)),
                ],
                (vehicles, totalVehicles) => ({
                    vehicles,
                    totalVehicles,
                }),
            );

            try {
                return toMsg(Result.ok(decoder(data)));
            } catch {
                return toMsg(Result.err(Errors.unexpectedError()));
            }
        },
    });
}

/**
 * Retrieves a dealer's dashboard
 *
 * @param authentication - Access token
 * @param dealer - ID of dealer
 * @param toMsg
 */
export function retrieveDealerDashboard<msg>(
    authentication: string,
    toMsg: (value: Result.Result<DealerDashboard, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.dealerDashboard(),
        method: Http.RequestMethod.Get,
        query: {
            period: '30d',
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const data = (await response.json())?.data;

            const decoder: JD.Decoder<DealerDashboard> = JD.record({
                competition: JD.succeed([]),
                contact: JD.record({
                    total: valueDecoder(JD.number),
                    graph: extractValueDecoder(
                        JD.array(
                            JD.map(
                                [
                                    JD.index(0, JD.number),
                                    JD.index(1, JD.number),
                                ],
                                (x, y): [number, number] => [x, y],
                            ),
                        ),
                    ),
                }),
                found: JD.record({
                    total: valueDecoder(JD.number),
                    graph: extractValueDecoder(
                        JD.array(
                            JD.map(
                                [
                                    JD.index(0, JD.number),
                                    JD.index(1, JD.number),
                                ],
                                (x, y): [number, number] => [x, y],
                            ),
                        ),
                    ),
                }),
                viewed: JD.record({
                    total: valueDecoder(JD.number),
                    graph: extractValueDecoder(
                        JD.array(
                            JD.map(
                                [
                                    JD.index(0, JD.number),
                                    JD.index(1, JD.number),
                                ],
                                (x, y): [number, number] => [x, y],
                            ),
                        ),
                    ),
                }),
                performance: JD.record({
                    advertisementquality: valueDecoder(JD.number),
                    askingprice: valueDecoder(JD.number),
                    daysinstock: valueDecoder(JD.number),
                }),
                pricehistory: JD.succeed([]),
                stock: JD.oneOf([
                    JD.record({
                        vehicle: JD.array(vehicleDecoder),
                        vehicle_count: extractValueDecoder(JD.number),
                        vehicle_hasrecalc: extractValueDecoder(JD.bool),
                    }),
                    JD.succeed(null),
                ]),
                stocksummary: JD.array(
                    JD.record({
                        advertisementquality: valueDecoder(JD.number),
                        askingpricedifferencepercentage: valueDecoder(
                            JD.number,
                        ),
                        askingpricetoohigh: valueDecoder(JD.number),
                        askingpricetotal: valueDecoder(JD.number),
                        daysinstockaverage: valueDecoder(JD.number),
                        daysinstockdistribution: valueDecoder(
                            JD.array(
                                JD.array(JD.oneOf([JD.string, JD.number])),
                            ),
                        ),
                        found: valueDecoder(JD.number),
                        id: valueDecoder(JD.string),
                        name: valueDecoder(JD.string),
                        place: valueDecoder(JD.string),
                        turnoveraveragedaysinstock: valueDecoder(JD.number),
                        vehiclecount: valueDecoder(JD.number),
                        viewed: valueDecoder(JD.number),
                    }),
                ),
            });

            try {
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieves a dealer's reach statistics
 *
 * @param authentication - Access token
 * @param {string} period - Time period of reach statistics
 * @param toMsg
 */
export function retrieveDealerReach<msg>(
    authentication: string,
    period: { startDate: string; endDate: string },
    toMsg: (value: Result.Result<DealerReach, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        headers: authorizedHeaders('nl-NL', authentication),
        path: AtaApiEndpoints.dealerReach(),
        method: Http.RequestMethod.Get,
        query: {
            startdate: period.startDate,
            enddate: period.endDate,
        },
        expect: async (response) => {
            const data = (await response.json())?.data;

            const decoder: JD.Decoder<DealerReach> = JD.record({
                dealerviews: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                found: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                clicks: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                viewed: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                stockviews: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                reviewviews: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                contact: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                    distribution: JD.keyValuePairs(
                        JD.field(
                            'total',
                            JD.map(
                                [
                                    JD.record({
                                        value: JD.number,
                                        label: JD.string,
                                    }),
                                ],
                                ({ value: total, label }) => ({ total, label }),
                            ),
                        ),
                    ),
                }),
                share: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                    distribution: JD.keyValuePairs(
                        JD.field(
                            'total',
                            JD.map(
                                [
                                    JD.record({
                                        value: JD.number,
                                        label: JD.string,
                                    }),
                                ],
                                ({ value: total, label }) => ({ total, label }),
                            ),
                        ),
                    ),
                }),
            });

            try {
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

export function retrieveDealerLeads<msg>(
    authentication: string,
    config: {
        sort: DealerLeadsSorting;
        period: { startDate: string; endDate: string };
        search: string;
        filter: Set<string>;
        page: number;
        amountPerPage: number;
    },
    toMsg: (value: Result.Result<DealerLeads, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.dealerLeads(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: removeUndefinedFromRecord({
            col: config.sort,
            sort: 'DESC',
            startdate: config.period.startDate,
            enddate: config.period.endDate,
            limit: String(config.amountPerPage),
            crr_page: String(config.page),
            search: config.search === '' ? undefined : config.search,
            type:
                config.filter.size === 0
                    ? undefined
                    : Array.from(config.filter).join(','),
        }),
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<DealerLeads> = JD.record({
                records: extractValueDecoder(
                    JD.array(
                        JD.record({
                            type: JD.string,
                            date: dateDecoder,
                            source: JD.string,
                            sourceid: JD.nullable(JD.string),
                            amount: JD.number,
                            label: JD.string,
                        }),
                    ),
                ),
                total: extractValueDecoder(JD.number),
                graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                distribution: JD.keyValuePairs(
                    JD.oneOf([
                        JD.map(
                            [
                                JD.at(['total', 'label'], JD.string),
                                JD.at(['total', 'value'], JD.number),
                                JD.at(
                                    ['sub'],
                                    JD.keyValuePairs(
                                        JD.map(
                                            [
                                                JD.at(
                                                    ['total', 'label'],
                                                    JD.string,
                                                ),
                                                JD.at(
                                                    ['total', 'value'],
                                                    JD.number,
                                                ),
                                            ],
                                            (label, total) => ({
                                                label,
                                                total,
                                            }),
                                        ),
                                    ),
                                ),
                            ],
                            (label, total, sub) => ({
                                label,
                                total,
                                children: sub,
                            }),
                        ),
                        JD.map(
                            [
                                JD.at(['total', 'label'], JD.string),
                                JD.at(['total', 'value'], JD.number),
                            ],
                            (label, total) => ({ label, total }),
                        ),
                    ]),
                ),
                totalrecords: extractValueDecoder(JD.number),
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieves a dealer's analysis overview
 *
 * @param dealer - ID of dealer
 * @param profile
 */
export function retrieveDealerAnalysisOverview<msg>(
    authentication: string,
    profile: string,
    toMsg: (result: Result.Result<AnalysisOverview, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.dealerAnalysisStock(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {
            benchmark: profile,
        },
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const benchmarkDecoder = JD.record({
                own: extractValueDecoder(JD.number),
                benchmark: extractValueDecoder(JD.number),
            });

            const decoder: JD.Decoder<AnalysisOverview> = JD.record({
                stock: benchmarkDecoder,
                askingprice: benchmarkDecoder,
                daysinstock: benchmarkDecoder,
                foundperday: benchmarkDecoder,
                viewedperday: benchmarkDecoder,
                averageaskingprice: benchmarkDecoder,
                daysinstockdistribution: extractValueDecoder(
                    JD.map(
                        [
                            JD.index(0, JD.array(JD.string)),
                            JD.index(
                                1,
                                JD.array(JD.oneOf([JD.string, JD.number])),
                            ),
                            JD.index(
                                2,
                                JD.array(JD.oneOf([JD.string, JD.number])),
                            ),
                            JD.index(
                                3,
                                JD.array(JD.oneOf([JD.string, JD.number])),
                            ),
                            JD.index(
                                4,
                                JD.array(JD.oneOf([JD.string, JD.number])),
                            ),
                        ],
                        (top, ...rows): AdvancedDistribution => {
                            return top.slice(1).reduce((acc, header, index) => {
                                acc[header] = {
                                    label: header,
                                    children: rows.map((row) => ({
                                        label: String(row[0]),
                                        total: Number(row[index + 1]),
                                    })),
                                };

                                return acc;
                            }, {} as AdvancedDistribution);
                        },
                    ),
                ),
                askingpricedistribution: extractValueDecoder(
                    JD.map(
                        [
                            JD.array(
                                JD.map(
                                    [
                                        JD.index(0, JD.string),
                                        JD.index(1, JD.number),
                                    ],
                                    (label, total) => ({ label, total }),
                                ),
                            ),
                        ],
                        (items) =>
                            items.reduce((acc, item) => {
                                acc[item.label] = item;
                                return acc;
                            }, {} as Distribution),
                    ),
                ),
                profiles: extractValueDecoder(
                    JD.array(
                        JD.record({
                            label: JD.string,
                            value: JD.string,
                        }),
                    ),
                ),
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

export function retrieveLeadsPerformanceAnalysis<msg>(
    authentication: string,
    period: { startDate: string; endDate: string },
    toMsg: (
        value: Result.Result<AnalysisLeadPerformance, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.dealerAnalysisLeadsPerformance(),
        method: Http.RequestMethod.Get,
        query: {
            startdate: period.startDate,
            enddate: period.endDate,
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<AnalysisLeadPerformance> = JD.record({
                dealerData: JD.keyValuePairs(
                    JD.record({
                        id: JD.string,
                        name: JD.string,
                    }),
                ),
                table: JD.record({
                    totals: JD.keyValuePairs(
                        JD.record({
                            found: JD.number,
                            seenAdverts: JD.number,
                            hardLeads: JD.number,
                            vehicleCount: JD.number,
                            avgFound: JD.number,
                            avgSeenAdverts: JD.number,
                        }),
                    ),
                }),
                graph: JD.record({
                    foundSeries: JD.array(
                        JD.array(JD.oneOf([JD.number, JD.string])),
                    ),
                    seenAdvertsSeries: JD.array(
                        JD.array(JD.oneOf([JD.number, JD.string])),
                    ),
                    hardLeadsSeries: JD.array(
                        JD.array(JD.oneOf([JD.number, JD.string])),
                    ),
                    vehicleCountSeries: JD.array(
                        JD.array(JD.oneOf([JD.number, JD.string])),
                    ),
                }),
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieves a dealer's analysis of vehicle makes and models
 *
 * @param dealer - ID of dealer
 */
export function retrieveDealerAnalysisMakeModel<msg>(
    authentication: string,
    toMsg: (result: Result.Result<AnalysisMakeModel, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.dealerAnalysisMakeModel(),
        method: Http.RequestMethod.Get,
        query: {},
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const data = (await response.json())?.data;
            const decoder: JD.Decoder<AnalysisMakeModel> = JD.record({
                askingprice: JD.array(
                    JD.array(JD.oneOf([JD.string, JD.number])),
                ),
                daysinstock: JD.array(
                    JD.array(JD.oneOf([JD.string, JD.number])),
                ),
                found: JD.array(JD.array(JD.oneOf([JD.string, JD.number]))),
                viewed: JD.array(JD.array(JD.oneOf([JD.string, JD.number]))),
            });

            try {
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieves a dealer's analysis of cost benefit
 *
 * @param dealer - ID of dealer
 */
export function retrieveDealerAnalysisCostBenefit<msg>(
    authentication: string,
    toMsg: (
        result: Result.Result<AnalysisCostBenefit, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.dealerAnalysisCostBenefit(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder = JD.record({
                totalvehicles: extractValueDecoder(JD.number),
                totalaskingprice: extractValueDecoder(JD.number),
                averagedaysinstock: extractValueDecoder(JD.number),
                goaldaysinstock: extractValueDecoder(JD.number),
                costaveragedaysinstock: extractValueDecoder(JD.number),
                costgoaldaysinstock: extractValueDecoder(JD.number),
                costsaving: extractValueDecoder(JD.number),
                grossmargin: extractValueDecoder(JD.number),
                benefitsgoaldaysinstock: extractValueDecoder(JD.number),
                turnoveraveragedaysinstock: extractValueDecoder(JD.number),
                turnovergoaldaysinstock: extractValueDecoder(JD.number),
                turnoverimprovement: extractValueDecoder(JD.number),
                morevehiclesgoaldaysinstock: extractValueDecoder(JD.number),
                interestrate: extractValueDecoder(JD.number),
                interestperday: extractValueDecoder(JD.number),
                writeoffrate: extractValueDecoder(JD.number),
                writeoffperday: extractValueDecoder(JD.number),
                costperday: extractValueDecoder(JD.number),
                graph: JD.record({
                    cost: JD.array(JD.array(JD.oneOf([JD.number, JD.string]))),
                    benefits: JD.array(
                        JD.array(JD.oneOf([JD.number, JD.string])),
                    ),
                }),
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieve a vehicle
 *
 * @param id - ID of vehicle
 */
export function retrieveVehicle<msg>(
    authentication: string,
    id: string,
    toMsg: (result: Result.Result<Vehicle, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.vehicle(id),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder = JD.at(['vehicle', '0'], vehicleDecoder);

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieve a vehicle's dashboard
 *
 * @param authentication
 * @param vehicle - ID of vehicle
 * @param toMsg
 */
export function retrieveVehicleDashboard<msg>(
    authentication: string,
    vehicle: string,
    toMsg: (value: Result.Result<VehicleDashboard, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.vehicleDashboard(vehicle),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<VehicleDashboard> = JD.record({
                competition: JD.record({
                    amount: extractValueDecoder(JD.number),
                    highest: JD.record({
                        askingprice: extractValueDecoder(JD.number),
                        name: extractValueDecoder(JD.string),
                    }),
                    lowest: JD.record({
                        askingprice: extractValueDecoder(JD.number),
                        name: extractValueDecoder(JD.string),
                    }),
                }),
                contact: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(
                        JD.array(
                            JD.map(
                                [
                                    JD.index(0, JD.number),
                                    JD.index(1, JD.number),
                                ],
                                (x, y): [number, number] => [x, y],
                            ),
                        ),
                    ),
                }),
                found: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(
                        JD.array(
                            JD.map(
                                [
                                    JD.index(0, JD.number),
                                    JD.index(1, JD.number),
                                ],
                                (x, y): [number, number] => [x, y],
                            ),
                        ),
                    ),
                }),
                viewed: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(
                        JD.array(
                            JD.map(
                                [
                                    JD.index(0, JD.number),
                                    JD.index(1, JD.number),
                                ],
                                (x, y): [number, number] => [x, y],
                            ),
                        ),
                    ),
                }),
                performance: JD.record({
                    advertisementquality: extractValueDecoder(JD.number),
                    askingprice: extractValueDecoder(JD.number),
                    daysinstock: extractValueDecoder(JD.number),
                }),
                pricehistory: JD.array(
                    JD.record({
                        currentaskingprice: extractValueDecoder(JD.number),
                        date: extractValueDecoder(JD.number),
                        previousaskingprice: extractValueDecoder(JD.number),
                    }),
                ),
                stock: JD.oneOf([
                    JD.record({
                        vehicle: JD.array(vehicleDecoder),
                        vehicle_count: extractValueDecoder(JD.number),
                        vehicle_hasrecalc: extractValueDecoder(JD.bool),
                    }),
                    JD.succeed(null),
                ]),
                stocksummary: JD.array(
                    JD.record({
                        advertisementquality: extractValueDecoder(JD.number),
                        askingpricedifferencepercentage: extractValueDecoder(
                            JD.number,
                        ),
                        askingpricetoohigh: extractValueDecoder(JD.number),
                        askingpricetotal: extractValueDecoder(JD.number),
                        daysinstockaverage: extractValueDecoder(JD.number),
                        daysinstockdistribution: extractValueDecoder(
                            JD.array(
                                JD.array(JD.oneOf([JD.string, JD.number])),
                            ),
                        ),
                        found: extractValueDecoder(JD.number),
                        id: extractValueDecoder(JD.string),
                        name: extractValueDecoder(JD.string),
                        place: extractValueDecoder(JD.string),
                        turnoveraveragedaysinstock: extractValueDecoder(
                            JD.number,
                        ),
                        vehiclecount: extractValueDecoder(JD.number),
                        viewed: extractValueDecoder(JD.number),
                    }),
                ),
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieve a vehicle's price effect
 *
 * @param authentication
 * @param vehicle - ID of vehicle
 * @param type - Subject of price effect
 * @param toMsg
 */
export function retrieveVehiclePriceEffect<msg>(
    authentication: string,
    vehicle: string,
    type: VehicleReachPriceEffectType,
    toMsg: (
        result: Result.Result<VehicleReachPriceEffect, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.vehiclePriceEffect(vehicle),
        method: Http.RequestMethod.Get,
        query: {
            type: type,
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<VehicleReachPriceEffect> = JD.record({
                graph: JD.array(JD.array(JD.oneOf([JD.string, JD.number]))),
                total: JD.number,
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieve a vehicle's competing vehicles
 *
 * @param authentication
 * @param vehicle - ID of vehicle
 * @param isSold - Get sold vehicles
 * @param toMsg
 */
export function retrieveVehicleCompetition<msg>(
    authentication: string,
    vehicle: string,
    isSold: boolean,
    toMsg: (value: Result.Result<VehicleCompetition, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.vehicleCompetition(vehicle),
        method: Http.RequestMethod.Get,
        query: {
            sold: isSold ? '1' : '0',
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<VehicleCompetition> = JD.map(
                [
                    JD.field('vehicle', JD.array(competingVehicleDecoder)),
                    JD.field('vehicle_count', JD.number),
                ],
                (vehicles, amountOfVehicles) => ({
                    vehicles,
                    amountOfVehicles,
                }),
            );

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieve a vehicle's price history
 *
 * @param authentication - Access token
 * @param vehicle - ID of vehicle
 * @param isSold - Get sold vehicles
 * @param toMsg
 * @returns
 */
export function retrieveVehiclePriceHistory<msg>(
    authentication: string,
    vehicle: string,
    isSold: boolean,
    toMsg: (
        value: Result.Result<VehicleComparisonPriceHistory, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.vehiclePriceHistory(vehicle),
        method: Http.RequestMethod.Get,
        query: {
            sold: isSold ? '1' : '0',
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<VehicleComparisonPriceHistory> = JD.map(
                [
                    JD.field(
                        'graph',
                        JD.array(
                            JD.array(
                                JD.oneOf([
                                    JD.nullable(JD.string),
                                    JD.nullable(JD.number),
                                ]),
                            ),
                        ),
                    ),
                    JD.field(
                        'vehicle',
                        JD.array(
                            JD.record({
                                id: JD.number,
                                pricehistory: JD.array(
                                    JD.record({
                                        date: JD.number,
                                        price: JD.number,
                                    }),
                                ),
                            }),
                        ),
                    ),
                ],
                (graph, vehicles) => ({
                    graph,
                    vehicleHistory: new Map(
                        vehicles.map(({ id, pricehistory }) => [
                            id,
                            pricehistory,
                        ]),
                    ),
                }),
            );

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieve a vehicle's geographical information
 *
 * @param authentication - Access token
 * @param vehicle - ID of vehicle
 * @param isSold - Get sold vehicles
 * @param toMsg
 * @returns
 */
export function retrieveVehicleGeographic<msg>(
    authentication: string,
    vehicle: string,
    isSold: boolean,
    toMsg: (
        value: Result.Result<VehicleComparisonGeographic, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.vehicleGeography(vehicle),
        method: Http.RequestMethod.Get,
        query: {
            sold: isSold ? '1' : '0',
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<VehicleComparisonGeographic> = JD.map(
                [
                    JD.field(
                        'graph',
                        JD.array(
                            JD.array(
                                JD.oneOf([
                                    JD.nullable(JD.string),
                                    JD.nullable(JD.number),
                                ]),
                            ),
                        ),
                    ),
                    JD.field(
                        'vehicle',
                        JD.array(
                            JD.record({
                                id: JD.string,
                                latitude: JD.number,
                                longitude: JD.number,
                            }),
                        ),
                    ),
                ],
                (graph, vehicles) => ({
                    graph,
                    vehicleLocations: new Map(
                        vehicles.map(({ id, latitude, longitude }) => [
                            id,
                            { latitude, longitude },
                        ]),
                    ),
                }),
            );

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieves a vehicle's reach
 *
 * @param authentication
 * @param vehicle - ID of vehicle
 * @param toMsg
 */
export function retrieveVehicleReach<msg>(
    authentication: string,
    vehicle: string,
    toMsg: (
        result: Result.Result<VehicleReachOverview, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.vehicleReach(vehicle),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<VehicleReachOverview> = JD.record({
                dealerviews: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                found: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                clicks: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                viewed: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                stockviews: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                reviewviews: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                }),
                contact: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                    distribution: JD.keyValuePairs(
                        JD.field(
                            'total',
                            JD.map(
                                [
                                    JD.record({
                                        value: JD.number,
                                        label: JD.string,
                                    }),
                                ],
                                ({ value: total, label }) => ({ total, label }),
                            ),
                        ),
                    ),
                }),
                share: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(JD.array(JD.array(JD.number))),
                    distribution: JD.keyValuePairs(
                        JD.field(
                            'total',
                            JD.map(
                                [
                                    JD.record({
                                        value: JD.number,
                                        label: JD.string,
                                    }),
                                ],
                                ({ value: total, label }) => ({ total, label }),
                            ),
                        ),
                    ),
                }),
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

export function retrieveTmpDealerCompetition<
    msg,
>(): Http.CancelablePromise<msg> {
    // toMsg: () => msg, // options?, // dealer,
    throw new Error('Not implemented');
    // const priceRequestOptions: RequestOptionsArgs = {
    //     path: AtaApiEndpoints.dealerCompetitionPrice(),
    //     method: Http.RequestMethod.Post,
    //     query: {
    //         type: 'price',
    //     },
    //     body: {
    //         data: options,
    //     },
    // };
    //
    // const geoRequestOptions: RequestOptionsArgs = {
    //     path: AtaApiEndpoints.dealerCompetitionGeo(),
    //     method: Http.RequestMethod.Post,
    //     body: {
    //         data: options,
    //     },
    // };
    //
    // const priceResponse$ = Observable.of(constructRequest(priceRequestOptions))
    //     .switchMap((request) => this.http.request(request))
    //     .catch(AtaApiService.convertErrorResponse)
    //     .map(AtaApiService.convertResponse);
    //
    // const geoResponse$ = Observable.of(constructRequest(geoRequestOptions))
    //     .switchMap((request) => this.http.request(request))
    //     .catch(AtaApiService.convertErrorResponse)
    //     .map(AtaApiService.convertResponse);
    //
    // return Observable.combineLatest(priceResponse$, geoResponse$)
    //     .catch((error) => [])
    //     .map(([price, geo]) => {
    //         const result = {
    //             data: {
    //                 vehicleMakes: [],
    //                 vehicleModels: [],
    //                 dealers: [],
    //             },
    //         };
    //
    //         if (price && price.data) {
    //             if (price.data.graph && geo && geo.data && geo.data.graph) {
    //                 result.data.dealers = price.data.graph.reduce(
    //                     (mResult, priceRow, index) => {
    //                         const geoRow =
    //                             geo.data.graph.find(
    //                                 (data) =>
    //                                     data['dealerid'] ===
    //                                     priceRow['AANBIEDER_ID']
    //                             ) || {};
    //
    //                         return [
    //                             ...mResult,
    //                             {
    //                                 id: priceRow['AANBIEDER_ID'],
    //                                 isOwn: priceRow['zichzelf'],
    //                                 name: priceRow['d_naam'],
    //                                 place: priceRow['d_plaats'],
    //                                 latitude: geoRow['latitude'],
    //                                 longitude: geoRow['longitude'],
    //                                 countOfVehicles: Number.parseInt(
    //                                     priceRow['aantal']
    //                                 ),
    //                                 averageDifference:
    //                                     Number.parseFloat(
    //                                         priceRow['verschil']
    //                                     ) / 100,
    //                                 averageDifferenceString:
    //                                     priceRow['verschil'],
    //                                 averageDaysInStock: Number.parseInt(
    //                                     priceRow['statijd']
    //                                 ),
    //                                 averageViewed: geoRow[
    //                                     'avgofviewed'
    //                                 ] as number,
    //                                 averageFound: geoRow[
    //                                     'avgoffound'
    //                                 ] as number,
    //                             },
    //                         ];
    //                     },
    //                     []
    //                 );
    //             }
    //
    //             if (price.data.makes) {
    //                 result.data.vehicleMakes = [
    //                     ...result.data.vehicleMakes,
    //                     ...price.data.makes.map((make) => ({
    //                         key: make,
    //                         value: make,
    //                         isPopular: false,
    //                     })),
    //                 ];
    //             }
    //
    //             if (price.data.makes) {
    //                 result.data.vehicleMakes = [
    //                     ...result.data.vehicleMakes,
    //                     ...price.data.makespopular.map((make) => ({
    //                         key: make,
    //                         value: make,
    //                         isPopular: true,
    //                     })),
    //                 ];
    //             }
    //
    //             if (price.data.models) {
    //                 result.data.vehicleModels = price.data.models.map(
    //                     (model) => ({
    //                         key: model.model,
    //                         value: model.model,
    //                         vehicleMake: model.make,
    //                     })
    //                 );
    //             }
    //         }
    //
    //         return result;
    //     })
    //     .catch((error) => {
    //         return Observable.of([]);
    //     });
}

/**
 * Downloads a CSV list of specified dealer data
 *
 * @param authentication - Access token
 * @param path - URI path
 * @param dateRange - Period for which to generate leads
 * @param toMsg
 * @returns Promise
 */
export function downloadDealerCSV<msg>(
    authentication: string,
    path: string,
    dateRange: [startdate: string, enddate: string] | null,
    toMsg: (wasSuccessful: boolean) => msg,
): Http.CancelablePromise<msg> {
    const [startdate, enddate] = dateRange ?? [];

    return Http.request({
        path: `${apiDomain}/${path}`,
        method: Http.RequestMethod.Get,
        headers: {
            'Content-Type': 'text/csv; charset=utf-8',
            ...authorizedHeaders('nl-NL', authentication),
        },
        query: {
            _F: 'csv',
            token: authentication,
            ...(startdate && enddate ? { startdate, enddate } : {}),
        },
        expect: async (response: Response) => {
            const data = await response.blob();

            const csv = window.URL.createObjectURL(data);
            window.location.assign(csv);

            return toMsg(response.ok);
        },
    });
}

/**
 * Retrieve a vehicle's stock settings
 *
 * @param authentication - Access token
 * @param toMsg
 * @returns Promise
 */
export function retrieveStockSettings<msg>(
    authentication: string,
    toMsg: (value: Result.Result<StockSettings, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsStock(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<StockSettings> = JD.record({
                excludedvehicleids: JD.array(JD.string),
                mileagemax: JD.nullable(JD.number),
                mileagemin: JD.nullable(JD.number),
                reachfiltered: JD.bool,
                vehicleimported: JD.bool,
                vehiclenew: JD.bool,
                vehiclenotimported: JD.bool,
            });

            try {
                const data = (await response.json())?.data?.stock;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Updates vehicle stock settings
 *
 * @param authentication - Access token
 * @param data - Vehicle stock data
 * @param toMsg
 * @returns Promise
 */
export function updateStockSettings<msg>(
    authentication: string,
    data: StockSettings,
    toMsg: (result: Result.Result<{ id: string }, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsStock(),
        method: Http.RequestMethod.Patch,
        body: { data },
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<{ id: string }> = JD.record({
                id: JD.oneOf([JD.string, JD.map([JD.number], String)]),
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Reset vehicle stock settings
 *
 * @param authentication - Access token
 * @param toMsg
 * @returns Promise
 */
export function resetStockSettings<msg>(
    authentication: string,
    toMsg: (wasSuccessful: boolean) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsStock(),
        method: Http.RequestMethod.Delete,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => toMsg(response.ok),
    });
}

/**
 * Retrieves competing vehicles settings
 *
 * @param authentication - Access token
 * @param id - Vehicle id
 * @param toMsg
 * @returns Promise
 */
export function retrieveVehicleCompetitionSettings<msg>(
    authentication: string,
    id: string,
    toMsg: (value: Result.Result<VehicleSettings, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsCompetingVehicles(id),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<VehicleSettings> = JD.record({
                agedifference: JD.number,
                agesameyear: JD.bool,
                aircon: JD.bool,
                bovagmember: JD.bool,
                climatecontrol: JD.bool,
                leather: JD.bool,
                maintenancebooklet: JD.bool,
                makedealer: JD.bool,
                mileagedifference: JD.number,
                navigation: JD.bool,
                universaldealer: JD.bool,
                vehiclecolor: JD.bool,
                vehicledoors: JD.bool,
                vehicleenginecapacity: JD.bool,
                vehiclefuel: JD.bool,
                vehiclemake: JD.bool,
                vehiclemodel: JD.bool,
                vehiclepower: JD.bool,
                vehicletransmission: JD.bool,
                vehicletrimpackage: JD.bool,
                vehicleweight: JD.bool,
                warrantyautopas: JD.bool,
                warrantybovag: JD.bool,
                warrantybrand: JD.bool,
                warrantycarfax: JD.bool,
                warrantyhome: JD.bool,
            });

            try {
                const data = (await response.json())?.data?.competingvehicles;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Update competing vehicles settings
 *
 * @param authentication - Access token
 * @param id - Vehicle ID
 * @param data - Competing Vehicles properties
 * @param toMsg
 * @returns Promise
 */
export function updateVehicleCompetitionSettings<msg>(
    authentication: string,
    id: string,
    data: VehicleSettings,
    toMsg: (result: Result.Result<{ id: string }, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsCompetingVehicles(id),
        method: Http.RequestMethod.Patch,
        body: { data },
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<{ id: string }> = JD.record({
                id: JD.string,
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Reset competing vehicles settings
 *
 * @param authentication - Access token
 * @param id - Vehicle id
 * @param toMsg
 * @returns Promise
 */
export function resetVehicleCompetitionSettings<msg>(
    authentication: string,
    id: string,
    toMsg: (wasSuccessful: boolean) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsCompetingVehicles(id),
        method: Http.RequestMethod.Delete,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => toMsg(response.ok),
    });
}

/**
 * Retrieve car notification settings
 *
 * @param authentication - Access token
 * @param toMsg
 * @returns Promise
 */
export function retrieveNoticationsSettings<msg>(
    authentication: string,
    toMsg: (
        value: Result.Result<NotificationSettings, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsNotifications(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<NotificationSettings> = JD.record({
                alertadvertisementseen: JD.number,
                alertdaysinstock: JD.number,
                alertpricechange: JD.number,
                emailclicks: JD.bool,
                emaildaysinstock: JD.bool,
                emailfrequency: JD.string,
                emailpricechange: JD.bool,
                emailrecipients: JD.string,
                goaldaysinstock: JD.number,
            });

            try {
                const data = (await response.json())?.data?.notifications;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Updates vehicle notifications settings
 *
 * @param authentication - Access token
 * @param data - Vehicle notification settings
 * @param toMsg
 * @returns Promise
 */
export function updateNotificationsSettings<msg>(
    authentication: string,
    data: NotificationSettings,
    toMsg: (result: Result.Result<{ id: string }, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsNotifications(),
        method: Http.RequestMethod.Patch,
        body: { data },
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<{ id: string }> = JD.record({
                id: JD.oneOf([JD.map([JD.number], String), JD.string]),
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Resets dealer notification settings
 *
 * @param authentication - Access token
 * @param toMsg
 * @returns Promise
 */
export function resetNotificationsSettings<msg>(
    authentication: string,
    toMsg: (wasSuccessful: boolean) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsNotifications(),
        method: Http.RequestMethod.Delete,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => toMsg(response.ok),
    });
}

/**
 * @todo(Geert): Add ID to profile
 * @todo(Geert): Empty state should return []
 */
export function retrieveDealerProfilesSettings<msg>(
    authentication: string,
    toMsg: (
        result: Result.Result<CompetitionProfile[], ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsDealerProfiles(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<CompetitionProfile[]> = JD.oneOf([
                JD.field(
                    'competingdealers',
                    JD.array(
                        JD.map(
                            [
                                JD.record({
                                    name: JD.string,
                                    id: JD.string,
                                    dealerlist: JD.array(
                                        JD.record({
                                            id: JD.string,
                                            name: JD.string,
                                        }),
                                    ),
                                }),
                            ],
                            ({ id, name, dealerlist: dealers }) => ({
                                id,
                                name,
                                dealers,
                            }),
                        ),
                    ),
                ),
                JD.array(JD.succeed(null as never)),
            ]);

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Creates/Updates dealer profile
 *
 * @param authentication - Access token
 * @param profile - Dealer profile data
 * @param toMsg
 * @returns Promise
 */
export function updateDealerProfile<msg>(
    authentication: string,
    profile: CompetitionProfile | CompetitionProfileDraft,
    toMsg: (result: Result.Result<{ id: string }, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: profile?.id
            ? AtaApiEndpoints.settingsDealerProfile(profile?.id)
            : AtaApiEndpoints.settingsDealerProfiles(),
        method: profile?.id
            ? Http.RequestMethod.Patch
            : Http.RequestMethod.Post,
        body: {
            data: {
                id: profile?.id ?? null,
                name: profile.name,
                dealerlist: profile.dealers.map((dealer) => ({
                    id: dealer.id,
                    name: dealer.name,
                })),
                dealerids: profile.dealers.map((dealer) => dealer.id),
            },
        },
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<{ id: string }> = JD.record({
                id: JD.string,
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Removes dealer profile
 *
 * @param authentication - Access token
 * @param profile - Dealer profile data
 * @param toMsg
 * @returns Promise
 */
export function removeDealerProfile<msg>(
    authentication: string,
    profile: CompetitionProfile,
    toMsg: (wasSuccessful: boolean) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsDealerProfile(profile?.id),
        method: Http.RequestMethod.Delete,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => toMsg(response.ok),
    });
}

/**
 * Delete a scenario
 *
 * @param authentication - Access token
 * @param scenario - Scenario ID
 * @param toMsg
 * @returns Promise
 */
export function removeScenario<msg>(
    authentication: string,
    scenario: ScenarioSnippet,
    toMsg: (wasSuccessful: boolean) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsScenario(scenario.id),
        method: Http.RequestMethod.Delete,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => toMsg(response.ok),
    });
}

/**
 * Send test email to designated recipients
 *
 * @param authentication - Access token
 * @param toMsg
 * @returns Promise
 */
export function sendTestMail<msg>(
    authentication: string,
    toMsg: (result: Result.Result<{ error: boolean }, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsTestMail(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<{ error: boolean }> = JD.record({
                error: JD.bool,
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Parametrically search list of competitors
 *
 * @param authentication - Access token
 * @param filters - Dealer search parameters
 * @param toMsg
 * @returns Promise
 */
export function searchDealers<msg>(
    authentication: string,
    filters: {
        search?: string;
        dealerTypes?: Set<string>;
        provinces?: Set<string>;
        dealerMakes?: Set<string>;
        stockMakes?: Set<string>;
    },
    toMsg: (
        result: Result.Result<CompetitionProfileDealer[], ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.dealerSearch(),
        method: Http.RequestMethod.Post,
        body: {
            data: {
                dealernaam: filters.search ?? '',
                regio: Array.from(filters.provinces?.values() ?? []),
                soortaanbieder: Array.from(filters.dealerTypes?.values() ?? []),
                dealermerk: Array.from(filters.dealerMakes?.values() ?? []),
                merk: Array.from(filters.stockMakes?.values() ?? []),
            },
        },
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<CompetitionProfileDealer[]> = JD.array(
                JD.map(
                    [
                        JD.record({
                            key: JD.string,
                            value: JD.string,
                        }),
                    ],
                    (item) => ({
                        id: item.key,
                        name: item.value,
                    }),
                ),
            );

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Define dealer scenario
 *
 * @param authentication - Access token
 * @param scenario
 * @param toMsg
 * @returns Promise
 */
export function addSettingsScenario<msg>(
    authentication: string,
    scenario: ScenarioDraft,
    toMsg: (response: Result.Result<Scenario, ApplicationError>) => msg,
): Promise<msg> {
    const createScenarioRequest = Http.request({
        path: AtaApiEndpoints.settingsScenarios(),
        method: Http.RequestMethod.Post,
        body: { name: scenario.name },
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return Result.err(Errors.unexpectedError());
            }

            const decoder: JD.Decoder<string> = JD.field('id', JD.string);

            try {
                const data = (await response.json())?.data;
                return Result.ok(decoder(data));
            } catch (error) {
                return Result.err(Errors.unexpectedError(error));
            }
        },
    });

    return (async (): Promise<msg> =>
        Result.match(await createScenarioRequest, {
            Err: (error): Promise<msg> =>
                Promise.resolve(toMsg(Result.err(error))),
            Ok: (scenarioId): Promise<msg> =>
                updateSettingsScenario(
                    authentication,
                    {
                        ...scenario,
                        id: scenarioId,
                    },
                    toMsg,
                ),
        }))();
}

/**
 * Update scenario settings
 *
 * @param authentication - Access token
 * @param scenario - Scenario object
 * @param toMsg
 * @returns Promise
 */
export function updateSettingsScenario<msg>(
    authentication: string,
    scenario: WithRequired<ScenarioDraft, 'id'>,
    toMsg: (result: Result.Result<Scenario, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: scenario.id
            ? AtaApiEndpoints.settingsScenario(scenario.id)
            : AtaApiEndpoints.settingsScenarios(),
        method: Http.RequestMethod.Patch,
        body: {
            data: scenario,
        },
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<ScenarioCluster[]> = JD.array(
                JD.record({
                    id: JD.string,
                    name: JD.string,
                    dealers: JD.array(JD.string),
                }),
            );

            try {
                const data = (await response.json())?.data?.clusters;
                const decodedData = decoder(data);

                const updatedScenario: Scenario = {
                    ...scenario,
                    clusters: decodedData,
                };

                return toMsg(Result.ok(updatedScenario));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

export function retrieveSettingsDealerProfileFilters<msg>(
    authentication: string,
    toMsg: (
        result: Result.Result<DealerProfileFiltersMetadata, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsDealerProfileFilters(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const itemDecoder = JD.record({
                key: JD.string,
                value: JD.string,
            });

            const decoder: JD.Decoder<DealerProfileFiltersMetadata> = JD.map(
                [
                    JD.field('merken', JD.array(itemDecoder)),
                    JD.field('provincies', JD.array(itemDecoder)),
                    JD.field('soortaanbieders', JD.array(itemDecoder)),
                ],
                (makes, provinces, dealerTypes) => ({
                    makes,
                    provinces,
                    dealerTypes,
                }),
            );

            try {
                const json = (await response.json())?.data;
                return toMsg(Result.ok(decoder(json)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Search for vehicle-specific taxation information
 *
 * @param authentication - Access token
 * @param licenseplate - Vehicle license plate
 * @param mileage - Vehicle mileage
 * @param toMsg
 * @returns Promise
 */
export function searchTaxation<msg>(
    authentication: string,
    licenseplate: string,
    mileage: number,
    toMsg: (result: Result.Result<{ id: string }, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.taxation(),
        method: Http.RequestMethod.Post,
        body: {
            data: {
                licenseplate: licenseplate,
                mileage: mileage,
            },
        },
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            if (response.status === 204) {
                return toMsg(Result.err(Errors.taxationNotFound()));
            }

            const decoder: JD.Decoder<{ id: string }> = JD.record({
                id: JD.string,
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedBody(error)));
            }
        },
    });
}

/**
 * Retrieve a vehicle's taxation information
 *
 * @param authentication - Access token
 * @param id - ID of vehicle
 * @param toMsg
 * @returns Promise
 */
export function retrieveTaxation<msg>(
    authentication: string,
    id: string,
    toMsg: (result: Result.Result<Vehicle, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.taxation(id),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<Taxation> = JD.record({
                id: JD.string,
                vehicle: JD.array(vehicleDecoder),
            });

            try {
                const json = await response.json();
                return toMsg(
                    Result.ok(
                        decoder({
                            ...json?.data,
                            id: json?.id,
                        })?.vehicle[0],
                    ),
                );
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieve a vehicle's dashboard
 *
 * @param authentication - Access token
 * @param vehicle - ID of vehicle
 * @param toMsg
 * @returns Promise
 */
export function retrieveTaxationDashboard<msg>(
    authentication: string,
    vehicle: string,
    toMsg: (result: Result.Result<VehicleDashboard, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.taxationDashboard(vehicle),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<VehicleDashboard> = JD.record({
                competition: JD.record({
                    amount: extractValueDecoder(JD.number),
                    highest: JD.record({
                        askingprice: extractValueDecoder(JD.number),
                        name: extractValueDecoder(JD.string),
                    }),
                    lowest: JD.record({
                        askingprice: extractValueDecoder(JD.number),
                        name: extractValueDecoder(JD.string),
                    }),
                }),
                contact: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(
                        JD.array(
                            JD.map(
                                [
                                    JD.index(0, JD.number),
                                    JD.index(1, JD.number),
                                ],
                                (x, y): [number, number] => [x, y],
                            ),
                        ),
                    ),
                }),
                found: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(
                        JD.array(
                            JD.map(
                                [
                                    JD.index(0, JD.number),
                                    JD.index(1, JD.number),
                                ],
                                (x, y): [number, number] => [x, y],
                            ),
                        ),
                    ),
                }),
                viewed: JD.record({
                    total: extractValueDecoder(JD.number),
                    graph: extractValueDecoder(
                        JD.array(
                            JD.map(
                                [
                                    JD.index(0, JD.number),
                                    JD.index(1, JD.number),
                                ],
                                (x, y): [number, number] => [x, y],
                            ),
                        ),
                    ),
                }),
                performance: JD.oneOf([
                    JD.record({
                        advertisementquality: extractValueDecoder(JD.number),
                        askingprice: extractValueDecoder(JD.number),
                        daysinstock: extractValueDecoder(JD.number),
                    }),
                    JD.succeed([]),
                ]),
                pricehistory: JD.array(
                    JD.record({
                        currentaskingprice: extractValueDecoder(JD.number),
                        date: extractValueDecoder(JD.number),
                        previousaskingprice: extractValueDecoder(JD.number),
                    }),
                ),
                stock: JD.oneOf([
                    JD.record({
                        vehicle: JD.array(vehicleDecoder),
                        vehicle_count: extractValueDecoder(JD.number),
                        vehicle_hasrecalc: extractValueDecoder(JD.bool),
                    }),
                    JD.succeed(null),
                ]),
                stocksummary: JD.oneOf([
                    JD.array(
                        JD.record({
                            advertisementquality: extractValueDecoder(
                                JD.number,
                            ),
                            askingpricedifferencepercentage:
                                extractValueDecoder(JD.number),
                            askingpricetoohigh: extractValueDecoder(JD.number),
                            askingpricetotal: extractValueDecoder(JD.number),
                            daysinstockaverage: extractValueDecoder(JD.number),
                            daysinstockdistribution: extractValueDecoder(
                                JD.array(
                                    JD.array(JD.oneOf([JD.string, JD.number])),
                                ),
                            ),
                            found: extractValueDecoder(JD.number),
                            id: extractValueDecoder(JD.string),
                            name: extractValueDecoder(JD.string),
                            place: extractValueDecoder(JD.string),
                            turnoveraveragedaysinstock: extractValueDecoder(
                                JD.number,
                            ),
                            vehiclecount: extractValueDecoder(JD.number),
                            viewed: extractValueDecoder(JD.number),
                        }),
                    ),
                    JD.succeed([]),
                ]),
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieve a vehicle's competing vehicles
 *
 * @param vehicle - ID of vehicle
 * @param isSold - Get sold vehicles
 */
export function retrieveTaxationCompetition<msg>(
    authentication: string,
    vehicle: string,
    isSold: boolean,
    toMsg: (result: Result.Result<VehicleCompetition, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.taxationCompetition(vehicle),
        method: Http.RequestMethod.Get,
        query: {
            sold: isSold ? '1' : '0',
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<VehicleCompetition> = JD.map(
                [
                    JD.field('vehicle', JD.array(competingVehicleDecoder)),
                    JD.field('vehicle_count', JD.number),
                ],
                (vehicles, amountOfVehicles) => ({
                    vehicles,
                    amountOfVehicles,
                }),
            );

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieves vehicle taxation price history
 *
 * @param authentication - Access token
 * @param vehicle - Vehicle ID
 * @param isSold
 * @param toMsg
 * @returns Promise
 */
export function retrieveTaxationPriceHistory<msg>(
    authentication: string,
    vehicle: string,
    isSold: boolean,
    toMsg: (
        result: Result.Result<TaxationComparisonPriceHistory, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.taxationPriceHistory(vehicle),
        method: Http.RequestMethod.Get,
        query: {
            sold: isSold ? '1' : '0',
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<TaxationComparisonPriceHistory> = JD.map(
                [
                    JD.field(
                        'graph',
                        JD.array(
                            JD.array(
                                JD.oneOf([
                                    JD.nullable(JD.string),
                                    JD.nullable(JD.number),
                                ]),
                            ),
                        ),
                    ),
                    JD.field(
                        'vehicle',
                        JD.array(
                            JD.record({
                                id: JD.number,
                                pricehistory: JD.array(
                                    JD.record({
                                        date: JD.number,
                                        price: JD.number,
                                    }),
                                ),
                            }),
                        ),
                    ),
                ],
                (graph, vehicles) => ({
                    graph,
                    vehicleHistory: new Map(
                        vehicles.map(({ id, pricehistory }) => [
                            id,
                            pricehistory,
                        ]),
                    ),
                }),
            );

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieve a vehicle's tax-related geographical information
 *
 * @param authentication - Access token
 * @param vehicle - ID of vehicle
 * @param isSold - Get sold vehicles
 * @param toMsg
 * @returns Promise
 */
export function retrieveTaxationGeography<msg>(
    authentication: string,
    vehicle: string,
    isSold: boolean,
    toMsg: (
        result: Result.Result<TaxationComparisonGeographic, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.taxationGeography(vehicle),
        method: Http.RequestMethod.Get,
        query: {
            sold: isSold ? '1' : '0',
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<TaxationComparisonGeographic> = JD.map(
                [
                    JD.field(
                        'graph',
                        JD.array(
                            JD.array(
                                JD.oneOf([
                                    JD.nullable(JD.string),
                                    JD.nullable(JD.number),
                                ]),
                            ),
                        ),
                    ),
                    JD.field(
                        'vehicle',
                        JD.array(
                            JD.record({
                                id: JD.string,
                                latitude: JD.number,
                                longitude: JD.number,
                            }),
                        ),
                    ),
                ],
                (graph, vehicles) => ({
                    graph,
                    vehicleLocations: new Map(
                        vehicles.map(({ id, latitude, longitude }) => [
                            id,
                            { latitude, longitude },
                        ]),
                    ),
                }),
            );

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieves vehicle taxation settings
 *
 * @param authentication - Access token
 * @param id - Vehicle id
 * @param toMsg
 * @returns Promise
 */
export function retrieveTaxationSettings<msg>(
    authentication: string,
    id: string,
    toMsg: (response: Result.Result<TaxationSettings, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.taxationSettings(id),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<TaxationSettings> = JD.record({
                competingvehicles: JD.record({
                    agedifference: JD.number,
                    agesameyear: JD.bool,
                    aircon: JD.bool,
                    bovagmember: JD.bool,
                    climatecontrol: JD.bool,
                    leather: JD.bool,
                    maintenancebooklet: JD.bool,
                    makedealer: JD.bool,
                    mileagedifference: JD.number,
                    navigation: JD.bool,
                    universaldealer: JD.bool,
                    vehiclecolor: JD.bool,
                    vehicledoors: JD.bool,
                    vehicleenginecapacity: JD.bool,
                    vehiclefuel: JD.bool,
                    vehiclemake: JD.bool,
                    vehiclemodel: JD.bool,
                    vehiclepower: JD.bool,
                    vehicletransmission: JD.bool,
                    vehicletrimpackage: JD.bool,
                    vehicleweight: JD.bool,
                    warrantyautopas: JD.bool,
                    warrantybovag: JD.bool,
                    warrantybrand: JD.bool,
                    warrantycarfax: JD.bool,
                    warrantyhome: JD.bool,
                }),
                id: JD.string,
            });

            try {
                const data = await response.json();
                return toMsg(
                    Result.ok(
                        decoder({
                            ...data?.data,
                            id: data?.id,
                        }),
                    ),
                );
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Updates vehicle taxation settings
 *
 * @param authentication - Access token
 * @param id - Vehicle id
 * @param data - Vehicle settings data
 * @param toMsg
 * @returns Promise
 */
export function updateTaxationSettings<msg>(
    authentication: string,
    id: string,
    data: VehicleSettings,
    toMsg: (result: Result.Result<{ id: string }, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.taxationSettings(id),
        method: Http.RequestMethod.Patch,
        body: { data: data },
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<{ id: string }> = JD.record({
                id: JD.string,
            });

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Resets vehicle taxation settings
 *
 * @param authentication - Access token
 * @param id - Vehicle id
 * @param toMsg
 * @returns Promise
 */
export function resetTaxationSettings<msg>(
    authentication: string,
    id: string,
    toMsg: (wasSuccessful: boolean) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.taxationSettings(id),
        method: Http.RequestMethod.Delete,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => toMsg(response.ok),
    });
}

/**
 * Retrieves dealer scenario settings
 *
 * @param authentication - Access token
 * @param toMsg
 * @returns Promise
 */
export function retrieveSettingsScenarioDealers<msg>(
    authentication: string,
    toMsg: (result: Result.Result<Dealer[], ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsScenarioDealers(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<Dealer[]> = JD.array(
                JD.record({
                    id: JD.string,
                    name: JD.string,
                    place: JD.string,
                }),
            );

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

export function retrieveComparison<msg>(
    authentication: string,
    config: {
        profile: string;
        makes: Set<string>;
        models: Set<string>;
    },
    toMsg: (
        result: Result.Result<ComparisonAdvicePrice, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    const geoRequest = Http.request({
        path: AtaApiEndpoints.dealerCompetitionGeo(),
        method: Http.RequestMethod.Post,
        query: {},
        headers: authorizedHeaders('nl-NL', authentication),
        body: {
            data: {
                profile: config.profile,
                make: Array.from(config.makes),
                model: Array.from(config.models),
            },
        },
        expect: async (response) => {
            if (!response.ok) {
                return Result.err(Errors.unexpectedError());
            }

            const decoder = JD.field(
                'graph',
                JD.array(
                    JD.map(
                        [
                            JD.record({
                                avgoffound: JD.number,
                                avgofviewed: JD.number,
                                dealerid: JD.string,
                                latitude: JD.number,
                                longitude: JD.number,
                            }),
                        ],
                        (record) => ({
                            dealerId: record.dealerid,
                            averageFound: record.avgoffound,
                            averageViewed: record.avgofviewed,
                            latitude: record.latitude,
                            longitude: record.longitude,
                        }),
                    ),
                ),
            );

            try {
                const data = (await response.json()).data;
                return Result.ok(decoder(data));
            } catch (error) {
                return Result.err(Errors.unexpectedError(error));
            }
        },
    });

    const priceRequest = Http.request({
        path: AtaApiEndpoints.dealerCompetitionPrice(),
        method: Http.RequestMethod.Post,
        query: {
            type: 'price',
        },
        headers: authorizedHeaders('nl-NL', authentication),
        body: {
            data: {
                profile: config.profile,
                make: Array.from(config.makes),
                model: Array.from(config.models),
            },
        },
        expect: async (response) => {
            if (!response.ok) {
                return Result.err(Errors.unexpectedError());
            }

            const stringToNumberDecoder = JD.map([JD.string], (value) =>
                Number(value),
            );

            const decoder = JD.record({
                goaldaysinstock: stringToNumberDecoder,
                graph: JD.array(
                    JD.map(
                        [
                            JD.record({
                                AANBIEDER_ID: JD.string,
                                d_naam: JD.string,
                                d_plaats: JD.string,
                                aantal: stringToNumberDecoder,
                                statijd: stringToNumberDecoder,
                                verschil: stringToNumberDecoder,
                                zichzelf: stringToNumberDecoder,
                            }),
                        ],
                        (record) => ({
                            dealerId: record.AANBIEDER_ID,
                            dealerName: record.d_naam,
                            dealerPlace: record.d_plaats,
                            vehicleCount: record.aantal,
                            averageDaysInStock: record.statijd,
                            averageDifference: record.verschil,
                            isOwn: record.zichzelf,
                        }),
                    ),
                ),
                makes: JD.array(JD.string),
                makespopular: JD.array(JD.string),
                models: JD.array(
                    JD.record({
                        make: JD.string,
                        model: JD.string,
                    }),
                ),
                profiles: JD.array(
                    JD.record({
                        id: JD.string,
                        name: JD.string,
                    }),
                ),
            });

            try {
                const data = (await response.json()).data;
                return Result.ok(decoder(data));
            } catch (error) {
                return Result.err(Errors.unexpectedError(error));
            }
        },
    });

    return Promise.all([geoRequest, priceRequest]).then(
        ([geoResult, priceResult]) => {
            const result = Result.andThen(geoResult, (geo) => {
                const dealerMap = new Map(geo.map((x) => [x.dealerId, x]));

                return Result.map(priceResult, (price) => {
                    const dealers = price.graph.reduce(
                        (acc, dealer) => {
                            const foundDealer = dealerMap.get(dealer.dealerId);

                            acc.push({
                                ...dealer,
                                ...(foundDealer ?? {}),
                            });

                            return acc;
                        },
                        [] as ComparisonAdvicePrice['graph'],
                    );

                    return {
                        ...price,
                        graph: dealers,
                    } as ComparisonAdvicePrice;
                });
            });

            return toMsg(result);
        },
    ) as Http.CancelablePromise<msg>;
}

/**
 * Retrieves scenario settings
 *
 * @param authentication - Access token
 * @param toMsg
 * @returns Promise
 */
export function retrieveSettingsScenarios<msg>(
    authentication: string,
    toMsg: (result: Result.Result<ScenarioSnippet[], ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsScenarios(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<ScenarioSnippet[]> = JD.array(
                JD.record({
                    id: JD.string,
                    name: JD.string,
                }),
            );

            try {
                const data = (await response.json())?.data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieve scenario settings for specific dealer
 *
 * @param authentication - Access token
 * @param id - Dealer ID
 * @param toMsg
 * @returns Promise
 */
export function retrieveSettingsScenario<msg>(
    authentication: string,
    id: string,
    toMsg: (result: Result.Result<Scenario, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsScenario(id),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<Scenario> = JD.record({
                id: JD.string,
                name: JD.string,
                clusters: JD.array(
                    JD.record({
                        id: JD.string,
                        name: JD.string,
                        dealers: JD.array(JD.string),
                    }),
                ),
            });

            try {
                const json = await response.json();
                return toMsg(
                    Result.ok(
                        decoder({
                            ...json?.data,
                            id: json?.id,
                            name: json?.name,
                        }),
                    ),
                );
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Retrieves dealer filter
 *
 * @param authentication - Access token
 * @param toMsg
 * @returns Promise
 */
export function retrieveDealerFilter<msg>(
    authentication: string,
    toMsg: (
        result: Result.Result<
            {
                scenario: Scenario['id'];
                dealers: Record<Dealer['id'], boolean>;
            },
            ApplicationError
        >,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsDealerFilter(),
        method: Http.RequestMethod.Get,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const dealersDecoder: JD.Decoder<Record<Dealer['id'], boolean>> =
                JD.field(
                    'data',
                    JD.index(
                        0,
                        JD.field(
                            'dealers',
                            JD.map(
                                [
                                    JD.array(
                                        JD.record({
                                            dealerid: JD.string,
                                            checked: JD.bool,
                                        }),
                                    ),
                                ],
                                (items) =>
                                    items.reduce(
                                        (acc, item) => ({
                                            ...acc,
                                            [item.dealerid]: item.checked,
                                        }),
                                        {},
                                    ),
                            ),
                        ),
                    ),
                );

            const decoder = JD.map(
                [JD.field('id', JD.string), dealersDecoder],
                (scenario, dealers) => ({ scenario, dealers }),
            );

            try {
                const data = await response.json();
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

/**
 * Update dealer filter
 *
 * @param authentication - Access token
 * @param dealersChecked - List of selected dealers
 * @param toMsg
 * @returns Promise
 */
export function updateDealerFilter<msg>(
    authentication: string,
    dealersChecked: Record<Dealer['id'], boolean>,
    toMsg: (
        result: Result.Result<Record<Dealer['id'], boolean>, ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsDealerFilter(),
        method: Http.RequestMethod.Patch,
        body: {
            data: {
                dealerfilter: Object.entries(dealersChecked).map(
                    ([dealerId, checked]) => ({
                        dealerid: dealerId,
                        checked,
                    }),
                    {},
                ),
            },
        },
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        expect: async (response: Response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<Record<Dealer['id'], boolean>> = JD.map(
                [
                    JD.field(
                        'data',
                        JD.index(
                            0,
                            JD.field(
                                'dealers',
                                JD.array(
                                    JD.record({
                                        dealerid: JD.string,
                                        checked: JD.bool,
                                    }),
                                ),
                            ),
                        ),
                    ),

                    // JD.record({
                    //     id: JD.string,
                    //     name: JD.string,
                    //     filter: JD.array(
                    //         JD.record({
                    //             dealers: JD.array(
                    //                 JD.record({
                    //                     checked: JD.bool,
                    //                     dealerid: JD.string,
                    //                     name: JD.string,
                    //                     place: JD.string,
                    //                 }),
                    //             ),
                    //             name: JD.string,
                    //             place: JD.string,
                    //         }),
                    //     ),
                    // }),
                ],
                (dealers) =>
                    dealers.reduce(
                        (acc, dealer) => ({
                            ...acc,
                            [dealer.dealerid]: dealer.checked,
                        }),
                        {},
                    ),
            );

            try {
                const json = await response.json();
                return toMsg(Result.ok(decoder(json)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

export function retrieveDealerProspects<msg>(
    authentication: string,
    config: {
        period: { startDate: string; endDate: string };
    },
    toMsg: (value: Result.Result<Prospects, ApplicationError>) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.dealerProspects(),
        method: Http.RequestMethod.Get,
        query: {
            startdate: config.period.startDate,
            enddate: config.period.endDate,
        },
        headers: authorizedHeaders('nl-NL', authentication),
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const data = (await response.json())?.data;

            const decoder: JD.Decoder<Prospects> = JD.record({
                records: JD.array(
                    JD.record({
                        telefoon: JD.string,
                        email: JD.string,
                        datum: JD.map([JD.string], (value) => {
                            const formatted = value.match(
                                /([0-9]{2})-([0-9]{2})-([0-9]{4})/,
                            );

                            const [, day, month, year] = formatted ?? [];

                            return new Date(`${year}-${month}-${day}`);
                        }),
                        leads: JD.string,
                        aantal: JD.string,
                        kenteken: JD.string,
                        merk: JD.string,
                        status: JD.string,
                        dealerNaam: JD.string,
                    }),
                ),
                totals: JD.record({
                    totalNrOfProspects: JD.number,
                    totalNrOfHardLead: JD.record({
                        phone: JD.number,
                        email: JD.number,
                        chatWhatsapp: JD.number,
                    }),
                }),
                totalrecords: JD.number,
            });

            try {
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

export function addSettingsCompetingDealersProfile<msg>(
    authentication: string,
    profile: CompetitionProfileDraft,
    toMsg: (
        result: Result.Result<CompetitionProfile['id'], ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    if (profile.id !== undefined) {
        return Promise.resolve(
            toMsg(Result.err(Errors.unexpectedError())),
        ) as Http.CancelablePromise<msg>;
    }

    return Http.request({
        path: AtaApiEndpoints.settingsDealerProfiles(),
        method: Http.RequestMethod.Post,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        body: {
            data: {
                dealerids: profile.dealers.map((dealer) => dealer.id),
                dealerlist: profile.dealers,
                id: null,
                name: profile.name,
            },
        },
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<CompetitionProfile['id']> = JD.field(
                'id',
                JD.string,
            );

            try {
                const data = (await response.json()).data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

export function updateSettingsCompetingDealersProfile<msg>(
    authentication: string,
    profile: WithRequired<CompetitionProfileDraft, 'id'>,
    toMsg: (
        result: Result.Result<CompetitionProfile['id'], ApplicationError>,
    ) => msg,
): Http.CancelablePromise<msg> {
    return Http.request({
        path: AtaApiEndpoints.settingsDealerProfile(profile.id),
        method: Http.RequestMethod.Patch,
        headers: authorizedHeaders('nl-NL', authentication),
        query: {},
        body: {
            data: {
                dealerids: profile.dealers.map((dealer) => dealer.id),
                dealerlist: profile.dealers,
                id: profile.id,
                name: profile.name,
            },
        },
        expect: async (response) => {
            if (!response.ok) {
                return toMsg(Result.err(Errors.unexpectedError()));
            }

            const decoder: JD.Decoder<CompetitionProfile['id']> = JD.field(
                'id',
                JD.string,
            );

            try {
                const data = (await response.json()).data;
                return toMsg(Result.ok(decoder(data)));
            } catch (error) {
                return toMsg(Result.err(Errors.unexpectedError(error)));
            }
        },
    });
}

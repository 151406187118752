import {
    application,
    ApplicationDocument,
    Cmd,
    Dispatch,
    Html,
} from './Application';
import { html, nothing } from 'lit';
import {
    CompetitionProfile,
    Model,
    NotificationSettings,
    SettingsStock,
    TaxationSettings,
    Vehicle,
    VehicleCompetitionStatus,
    VehicleSettings,
} from './Model';
import { Msg } from './Msg';
import * as Api from './Api';
import { retrieveNoticationsSettings } from './Api';
import Navigo, { Match } from 'navigo';
import { Route } from './Route';
import * as Maybe from './Maybe';
import * as Result from './Result';
import * as Async from './Async';
import shell from './Ui/Templates/Shell';
import mainShell from './Ui/Templates/MainShell';
import vehicleShell from './Ui/Templates/VehicleShell';
import { denseFilledButton, denseStrokedButton } from './Ui/Templates/Button';
import Icon from './Ui/Atoms/Icon';
import { exhaustiveCheck } from './ExhaustiveCheck';
import CacheMap from './CacheMap';
import * as ScenarioUtil from './Model/Scenario';
import * as CompetitionProfileUtil from './Model/CompetitionProfile';
import * as SettingsStockUtil from './Model/SettingsStock';
import * as Changes from './Changes';
import { hasChanges } from './Changes';
import isEqual from 'fast-deep-equal';
import { toISODateString } from './DateRange';
import taxationShell from './Ui/Templates/TaxationShell';
import { premiumPeak } from './Ui/Templates/PremiumPeak';
import viewNotFoundPage from './Pages/NotFound';
import { loadingLogo } from './Ui/Templates/Loading';
import * as PermanentStorage from './PermanentStorage';

const modules = {
    AnalysisCostBenefit: () => import('./Pages/AnalysisCostBenefit'),
    AnalysisLeadPerformance: () => import('./Pages/AnalysisLeadPerformance'),
    AnalysisMakeModel: () => import('./Pages/AnalysisMakeModel'),
    AnalysisOverview: () => import('./Pages/AnalysisOverview'),
    ComparisonAdvicePrice: () => import('./Pages/ComparisonAdvicePrice'),
    ComparisonDaysInStock: () => import('./Pages/ComparisonDaysInStock'),
    ComparisonGeographic: () => import('./Pages/ComparisonGeographic'),
    ComparisonOverview: () => import('./Pages/ComparisonOverview'),
    Dashboard: () => import('./Pages/Dashboard'),
    Leads: () => import('./Pages/Leads'),
    Login: () => import('./Pages/Login'),
    Prospects: () => import('./Pages/Prospects'),
    Reach: () => import('./Pages/Reach'),
    SettingsBranches: () => import('./Pages/SettingsBranches'),
    SettingsCompetingCompanies: () =>
        import('./Pages/SettingsCompetingCompanies'),
    SettingsCompetingVehicles: () =>
        import('./Pages/SettingsCompetingVehicles'),
    SettingsNotifications: () => import('./Pages/SettingsNotifications'),
    SettingsScenarios: () => import('./Pages/SettingsScenarios'),
    SettingsStock: () => import('./Pages/SettingsStock'),
    Stock: () => import('./Pages/Stock'),
    TaxationDashboard: () => import('./Pages/TaxationDashboard'),
    TaxationSearch: () => import('./Pages/TaxationSearch'),
    TaxationSettings: () => import('./Pages/TaxationSettings'),
    TaxationComparisonOverview: () =>
        import('./Pages/TaxationComparisonOverview'),
    TaxationComparisonGeographic: () =>
        import('./Pages/TaxationComparisonGeographic'),
    TaxationComparisonPriceAnalysis: () =>
        import('./Pages/TaxationComparisonPriceAnalysis'),
    TaxationComparisonPriceHistory: () =>
        import('./Pages/TaxationComparisonPriceHistory'),
    VehicleComparisonGeographic: () =>
        import('./Pages/VehicleComparisonGeographic'),
    VehicleComparisonOverview: () =>
        import('./Pages/VehicleComparisonOverview'),
    VehicleComparisonPriceAnalysis: () =>
        import('./Pages/VehicleComparisonPriceAnalysis'),
    VehicleComparisonPriceHistory: () =>
        import('./Pages/VehicleComparisonPriceHistory'),
    VehicleDashboard: () => import('./Pages/VehicleDashboard'),
    VehicleReachOverview: () => import('./Pages/VehicleReachOverview'),
    VehicleReachPriceEffect: () => import('./Pages/VehicleReachPriceEffect'),
    VehicleSettings: () => import('./Pages/VehicleSettings'),
} as const;

type ModuleKey = keyof typeof modules;
type ModuleTypes = {
    [K in ModuleKey]: Awaited<ReturnType<(typeof modules)[K]>>;
};

const loadedModules: Partial<ModuleTypes> = {};
const app = application<Msg, Model>({
    root: document.querySelector('[app]')!,
    init: init({
        authentication: ((): Flags['authentication'] => {
            const item = sessionStorage.getItem('authentication');
            return item === null ? null : JSON.parse(item);
        })(),
        deviceLayout: window.matchMedia('(min-width: 1200px)').matches
            ? 'Desktop'
            : 'Mobile',
        permanentSettings: {
            stockAmountPerPage: PermanentStorage.load('stockAmountPerPage'),
            leadsAmountPerPage: PermanentStorage.load('leadsAmountPerPage'),
            prospectsAmountPerPage: PermanentStorage.load(
                'prospectsAmountPerPage',
            ),
        },
    }),
    view,
    update,
});

const router = setUpRoutingSubscription();
setUpDeviceLayoutSubscription();

//---- Router ----//

function setUpRoutingSubscription(): Navigo {
    const router = new Navigo('/', { strategy: 'ONE' })
        .hooks({
            before: (done, match) => {
                if (match.params && 'SHA' in match.params) {
                    app.send(
                        Msg.Application_LoginWithToken({
                            token: match.params['SHA'],
                            dealer: match.params['d'],
                            ma: match.params['ma'],
                            session: match.params['sessie'],
                            reference: match.params['rf'],
                            type: match.params['t'],
                            master: match.params['master'] === 'true',
                        }),
                    );

                    const queryParams = new URLSearchParams(match.params);
                    queryParams.delete('SHA');
                    queryParams.delete('d');
                    queryParams.delete('ma');
                    queryParams.delete('sessie');
                    queryParams.delete('rf');
                    queryParams.delete('t');
                    queryParams.delete('master');

                    router.navigate(
                        match.url +
                            (queryParams.toString().length > 0
                                ? '?' + queryParams.toString()
                                : '') +
                            (match.hashString ? '#' + match.hashString : ''),
                        { historyAPIMethod: 'replaceState' },
                    );
                }

                done();
            },
        })
        .on({
            '/': {
                uses: async () => {
                    router.navigate('/dashboard', {
                        historyAPIMethod: 'replaceState',
                    });
                },
            },
            '/login': {
                as: 'Login',
                uses: async () => {
                    loadedModules['Login'] = await modules.Login();
                    app.send(Msg.OnRoute(Route.Login()));
                },
            },
            '/logout': {
                as: 'Logout',
                uses: () => {
                    app.send(Msg.OnRoute(Route.Logout()));
                },
            },
            '/dashboard': {
                as: 'Dashboard',
                uses: async () => {
                    loadedModules['Dashboard'] = await modules.Dashboard();
                    app.send(Msg.OnRoute(Route.Dashboard()));
                },
            },
            '/voorraad': {
                as: 'Stock',
                uses: async () => {
                    loadedModules['Stock'] = await modules.Stock();
                    app.send(Msg.OnRoute(Route.Stock()));
                },
            },
            '/bereik': {
                as: 'Reach',
                uses: async () => {
                    loadedModules['Reach'] = await modules.Reach();
                    app.send(Msg.OnRoute(Route.Reach()));
                },
            },
            '/leads': {
                as: 'Leads',
                uses: async () => {
                    loadedModules['Leads'] = await modules.Leads();
                    app.send(Msg.OnRoute(Route.Leads()));
                },
            },
            '/analyse/overzicht': {
                as: 'AnalysisOverview',
                uses: async () => {
                    loadedModules['AnalysisOverview'] =
                        await modules.AnalysisOverview();
                    app.send(Msg.OnRoute(Route.AnalysisOverview()));
                },
            },
            '/analyse/leadsprestatie': {
                as: 'AnalysisLeadPerformance',
                uses: async () => {
                    loadedModules['AnalysisLeadPerformance'] =
                        await modules.AnalysisLeadPerformance();
                    app.send(Msg.OnRoute(Route.AnalysisLeadPerformance()));
                },
            },
            '/analyse/merkmodel': {
                as: 'AnalysisMakeModel',
                uses: async () => {
                    loadedModules['AnalysisMakeModel'] =
                        await modules.AnalysisMakeModel();
                    app.send(Msg.OnRoute(Route.AnalysisMakeModel()));
                },
            },
            '/analyse/kostenbaten': {
                as: 'AnalysisCostBenefit',
                uses: async () => {
                    loadedModules['AnalysisCostBenefit'] =
                        await modules.AnalysisCostBenefit();
                    app.send(Msg.OnRoute(Route.AnalysisCostBenefit()));
                },
            },
            '/prospects': {
                as: 'Prospects',
                uses: async () => {
                    loadedModules['Prospects'] = await modules.Prospects();
                    app.send(Msg.OnRoute(Route.Prospects()));
                },
            },
            '/vergelijk/overzicht': {
                as: 'ComparisonOverview',
                uses: async () => {
                    loadedModules['ComparisonOverview'] =
                        await modules.ComparisonOverview();
                    app.send(Msg.OnRoute(Route.ComparisonOverview()));
                },
            },
            '/vergelijk/adviesprijs': {
                as: 'ComparisonAdvicePrice',
                uses: async () => {
                    loadedModules['ComparisonAdvicePrice'] =
                        await modules.ComparisonAdvicePrice();
                    app.send(Msg.OnRoute(Route.ComparisonAdvicePrice()));
                },
            },
            '/vergelijk/statijd': {
                as: 'ComparisonDaysInStock',
                uses: async () => {
                    loadedModules['ComparisonDaysInStock'] =
                        await modules.ComparisonDaysInStock();
                    app.send(Msg.OnRoute(Route.ComparisonDaysInStock()));
                },
            },
            '/vergelijk/geografisch': {
                as: 'ComparisonGeographic',
                uses: async () => {
                    loadedModules['ComparisonGeographic'] =
                        await modules.ComparisonGeographic();
                    app.send(Msg.OnRoute(Route.ComparisonGeographic()));
                },
            },
            '/voertuig/:vehicle': {
                uses: async ({ data }: Match) => {
                    router.navigate(`/voertuig/${data!.vehicle}/dashboard`, {
                        historyAPIMethod: 'replaceState',
                    });
                },
            },
            '/voertuig/:vehicle/dashboard': {
                as: 'VehicleDashboard',
                uses: async ({ data }: Match) => {
                    loadedModules['VehicleDashboard'] =
                        await modules.VehicleDashboard();
                    app.send(
                        Msg.OnRoute(
                            Route.VehicleDashboard({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            '/voertuig/:vehicle/bereik/overzicht': {
                as: 'VehicleReachOverview',
                uses: async ({ data }: Match) => {
                    loadedModules['VehicleReachOverview'] =
                        await modules.VehicleReachOverview();
                    app.send(
                        Msg.OnRoute(
                            Route.VehicleReachOverview({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            '/voertuig/:vehicle/bereik/prijseffect': {
                as: 'VehicleReachPriceEffect',
                uses: async ({ data }: Match) => {
                    loadedModules['VehicleReachPriceEffect'] =
                        await modules.VehicleReachPriceEffect();
                    app.send(
                        Msg.OnRoute(
                            Route.VehicleReachPriceEffect({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            '/voertuig/:vehicle/vergelijk/overzicht': {
                as: 'VehicleComparisonOverview',
                uses: async ({ data }: Match) => {
                    loadedModules['VehicleComparisonOverview'] =
                        await modules.VehicleComparisonOverview();
                    app.send(
                        Msg.OnRoute(
                            Route.VehicleComparisonOverview({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            '/voertuig/:vehicle/vergelijk/prijsanalyse': {
                as: 'VehicleComparisonPriceAnalysis',
                uses: async ({ data }: Match) => {
                    loadedModules['VehicleComparisonPriceAnalysis'] =
                        await modules.VehicleComparisonPriceAnalysis();
                    app.send(
                        Msg.OnRoute(
                            Route.VehicleComparisonPriceAnalysis({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            '/voertuig/:vehicle/vergelijk/prijshistorie': {
                as: 'VehicleComparisonPriceHistory',
                uses: async ({ data }: Match) => {
                    loadedModules['VehicleComparisonPriceHistory'] =
                        await modules.VehicleComparisonPriceHistory();
                    app.send(
                        Msg.OnRoute(
                            Route.VehicleComparisonPriceHistory({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            '/voertuig/:vehicle/vergelijk/geografisch': {
                as: 'VehicleComparisonGeographic',
                uses: async ({ data }: Match) => {
                    loadedModules['VehicleComparisonGeographic'] =
                        await modules.VehicleComparisonGeographic();
                    app.send(
                        Msg.OnRoute(
                            Route.VehicleComparisonGeographic({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            '/voertuig/:vehicle/settings': {
                as: 'VehicleSettings',
                uses: async ({ data }: Match) => {
                    loadedModules['VehicleSettings'] =
                        await modules.VehicleSettings();
                    app.send(
                        Msg.OnRoute(
                            Route.VehicleSettings({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            '/taxation/zoek': {
                as: 'TaxationSearch',
                uses: async () => {
                    loadedModules['TaxationSearch'] =
                        await modules.TaxationSearch();
                    app.send(Msg.OnRoute(Route.TaxationSearch()));
                },
            },
            '/taxatie/:vehicle/dashboard': {
                as: 'TaxationDashboard',
                uses: async ({ data }: Match) => {
                    loadedModules['TaxationDashboard'] =
                        await modules.TaxationDashboard();
                    app.send(
                        Msg.OnRoute(
                            Route.TaxationDashboard({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            'taxatie/:vehicle/vergelijk/overzicht': {
                as: 'TaxationComparisonOverview',
                uses: async ({ data }: Match) => {
                    loadedModules['TaxationComparisonOverview'] =
                        await modules.TaxationComparisonOverview();
                    app.send(
                        Msg.OnRoute(
                            Route.TaxationComparisonOverview({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            'taxatie/:vehicle/vergelijk/prijsanalyse': {
                as: 'TaxationComparisonPriceAnalysis',
                uses: async ({ data }: Match) => {
                    loadedModules['TaxationComparisonPriceAnalysis'] =
                        await modules.TaxationComparisonPriceAnalysis();
                    app.send(
                        Msg.OnRoute(
                            Route.TaxationComparisonPriceAnalysis({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            'taxatie/:vehicle/vergelijk/prijshistorie': {
                as: 'TaxationComparisonPriceHistory',
                uses: async ({ data }: Match) => {
                    loadedModules['TaxationComparisonPriceHistory'] =
                        await modules.TaxationComparisonPriceHistory();
                    app.send(
                        Msg.OnRoute(
                            Route.TaxationComparisonPriceHistory({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            'taxatie/:vehicle/vergelijk/geografisch': {
                as: 'TaxationComparisonGeographic',
                uses: async ({ data }: Match) => {
                    loadedModules['TaxationComparisonGeographic'] =
                        await modules.TaxationComparisonGeographic();
                    app.send(
                        Msg.OnRoute(
                            Route.TaxationComparisonGeographic({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            'taxatie/:vehicle/settings': {
                as: 'TaxationSettings',
                uses: async ({ data }: Match) => {
                    loadedModules['TaxationSettings'] =
                        await modules.TaxationSettings();
                    app.send(
                        Msg.OnRoute(
                            Route.TaxationSettings({
                                vehicleId: data!.vehicle,
                            }),
                        ),
                    );
                },
            },
            '/instellingen/voorraad': {
                as: 'SettingsStock',
                uses: async () => {
                    loadedModules['SettingsStock'] =
                        await modules.SettingsStock();
                    app.send(Msg.OnRoute(Route.SettingsStock()));
                },
            },
            '/instellingen/concurrentievoertuigen': {
                as: 'SettingsCompetingVehicles',
                uses: async () => {
                    loadedModules['SettingsCompetingVehicles'] =
                        await modules.SettingsCompetingVehicles();
                    app.send(Msg.OnRoute(Route.SettingsCompetingVehicles()));
                },
            },
            '/instellingen/concurrentiebedrijven': {
                as: 'SettingsCompetingCompanies',
                uses: async () => {
                    loadedModules['SettingsCompetingCompanies'] =
                        await modules.SettingsCompetingCompanies();
                    app.send(Msg.OnRoute(Route.SettingsCompetingCompanies()));
                },
            },
            '/instellingen/concurrentiebedrijven/:profile': {
                as: 'SettingsCompetingCompaniesProfile',
                uses: async ({ data }: Match) => {
                    loadedModules['SettingsCompetingCompanies'] =
                        await modules.SettingsCompetingCompanies();
                    app.send(
                        Msg.OnRoute(
                            Route.SettingsCompetingCompaniesProfile({
                                profileId:
                                    data?.profile === 'new'
                                        ? null
                                        : data?.profile ?? null,
                            }),
                        ),
                    );
                },
            },
            '/instellingen/doelenmeldingen': {
                as: 'SettingsNotifications',
                uses: async () => {
                    loadedModules['SettingsNotifications'] =
                        await modules.SettingsNotifications();
                    app.send(Msg.OnRoute(Route.SettingsNotifications()));
                },
            },
            '/instellingen/vestigingen': {
                as: 'SettingsBranches',
                uses: async () => {
                    loadedModules['SettingsBranches'] =
                        await modules.SettingsBranches();
                    app.send(Msg.OnRoute(Route.SettingsBranches()));
                },
            },
            '/instellingen/scenarios': {
                as: 'SettingsScenarios',
                uses: async () => {
                    loadedModules['SettingsScenarios'] =
                        await modules.SettingsScenarios();
                    app.send(Msg.OnRoute(Route.SettingsScenarios()));
                },
            },
            '/instellingen/scenarios/:scenarioId': {
                as: 'SettingsScenario',
                uses: async ({ data }: Match) => {
                    loadedModules['SettingsScenarios'] =
                        await modules.SettingsScenarios();
                    app.send(
                        Msg.OnRoute(
                            Route.SettingsScenario({
                                scenarioId:
                                    data?.scenarioId === 'new'
                                        ? null
                                        : data?.scenarioId ?? null,
                            }),
                        ),
                    );
                },
            },
        })
        .notFound(() => {
            app.send(Msg.OnRoute(Route.NotFound()));
        });

    router.resolve();

    return router;
}

function setUpDeviceLayoutSubscription(): void {
    window
        .matchMedia('(min-width: 1200px)')
        .addEventListener('change', (event: MediaQueryListEvent) => {
            app.send(Msg.DeviceLayout(event.matches ? 'Desktop' : 'Mobile'));
        });
}

//---- Init ----//

type Flags = {
    authentication: {
        token: string;
        expiration: Date;
        isMaster?: boolean;
    } | null;
    deviceLayout: 'Mobile' | 'Desktop';
    permanentSettings: {
        stockAmountPerPage?: number;
        leadsAmountPerPage?: number;
        prospectsAmountPerPage?: number;
    };
};

function defaultPeriod(): { startDate: string; endDate: string } {
    const days = (amount: number): number => 1000 * 60 * 60 * 24 * amount;
    const toDate = (unix: number): string =>
        new Date(unix).toISOString().split('T')[0];

    return {
        startDate: toDate(Date.now() - days(30)),
        endDate: toDate(Date.now() - days(1)),
    };
}

function init(flags: Flags): [Model, Promise<Awaited<Msg>>[]] {
    return [
        {
            deviceLayout: flags.deviceLayout,
            route: Route.NotFound(),
            isInitialized: false,
            isNavigationVisible: false,
            isAuthenticating: flags.authentication !== null,
            isRetrievingSession: false,
            account: null,
            cachedVehicles: new Map(),
            taxationWidget: {
                licensePlate: '',
                mileage: '',
                errorMessage: null,
            },
            settings: {
                stockCount: null,
                dealerCount: null,
                daysInStockGoal: Async.init(),
                competingDealers: null,
                dealers: Async.init(),
                dealerFilter: Async.init(),
            },
            page: {
                Login: {
                    username: '',
                    password: '',
                    captcha: null,
                    isCaptchaRequired: false,
                    error: null,
                },
                Dashboard: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    sorting: 'name',
                    data: null,
                },
                Stock: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    search: '',
                    sort: 'merk_model_uitv',
                    sortDirection: 'ASC',
                    onlyWithWarnings: false,
                    page: 1,
                    amountPerPage:
                        flags.permanentSettings.stockAmountPerPage ?? 10,
                    vehicles: [],
                    amountOfVehicles: 0,
                },
                Reach: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    period: defaultPeriod(),
                    data: null,
                },
                Leads: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    isFilterVisible: false,
                    page: 1,
                    amountPerPage:
                        flags.permanentSettings.leadsAmountPerPage ?? 50,
                    sort: 'date',
                    period: defaultPeriod(),
                    search: '',
                    filter: new Set(),
                    data: null,
                },
                Prospects: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    period: defaultPeriod(),
                    page: 1,
                    amountPerPage:
                        flags.permanentSettings.prospectsAmountPerPage ?? 50,
                    data: null,
                },
                AnalysisOverview: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    profile: '0',
                    data: null,
                },
                AnalysisLeadPerformance: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    period: defaultPeriod(),
                    data: null,
                },
                AnalysisCostBenefit: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    data: null,
                },
                AnalysisMakeModel: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    tab: 'askingprice',
                    data: null,
                },
                ComparisonAdvicePrice: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    activeProfile: null,
                    makeFilter: new Set(),
                    modelFilter: new Set(),
                    data: null,
                },
                ComparisonDaysInStock: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    activeProfile: null,
                    makeFilter: new Set(),
                    modelFilter: new Set(),
                    data: null,
                },
                ComparisonGeographic: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    activeTab: 'adviceprice',
                    activeProfile: null,
                    data: null,
                },
                ComparisonOverview: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    data: null,
                },
                SettingsBranches: {
                    isSaving: false,
                    scenarioList: Async.init(),
                    scenario: Async.init(),
                    dealerFilter: Async.init(),
                    changes: {},
                },
                SettingsCompetingCompanies: {
                    profiles: Async.init(),
                },
                SettingsCompetingCompaniesProfile: {
                    isSaving: false,
                    activeView: 'Profile',
                    filtersMetadata: Async.init(),
                    filters: {
                        search: '',
                        dealerTypes: new Set(),
                        provinces: new Set(),
                        dealerMakes: new Set(),
                        stockMakes: new Set(),
                    },
                    dealers: Async.init(),
                    profile: Async.init(),
                    changes: {
                        name: null,
                        dealers: Changes.init(),
                    },
                },
                SettingsCompetingVehicles: {
                    isInfoBlockVisible: false,
                    isSaving: false,
                    isResetting: false,
                    data: Async.init(),
                    changes: {},
                },
                SettingsNotifications: {
                    isSaving: false,
                    isResetting: false,
                    isSendingTestMail: false,
                    isInfoBlockVisible: false,
                    data: Async.init(),
                    changes: {},
                },
                SettingsScenarios: {
                    data: Async.init(),
                },
                SettingsScenario: {
                    isSaving: false,
                    selectedAvailableDealerPerCluster: new Map(),
                    data: Async.init(),
                    changes: {},
                },
                SettingsStock: {
                    isSaving: false,
                    isResetting: false,
                    isInfoBlockVisible: false,
                    stockCount: Async.init(),
                    searchVehicles: Async.init(),
                    data: Async.init(),
                    changes: {},
                },
                TaxationDashboard: {
                    isInfoBlockVisible: false,
                    data: Async.init(),
                },
                TaxationComparisonGeographic: {
                    isInfoBlockVisible: false,
                    vehicleStatus: 'Actual',
                    vehicleCompetition: Async.init(),
                    data: Async.init(),
                },
                TaxationComparisonOverview: {
                    isInfoBlockVisible: false,
                    vehicleStatus: 'Actual',
                    sort: 'vraagprijs',
                    sortDirection: 'DESC',
                    page: 1,
                    amountPerPage: 10,
                    vehicleCompetition: Async.init(),
                },
                TaxationComparisonPriceAnalysis: {
                    isInfoBlockVisible: false,
                    vehicleStatus: 'Actual',
                    vehicleCompetition: Async.init(),
                },
                TaxationComparisonPriceHistory: {
                    isInfoBlockVisible: false,
                    vehicleStatus: 'Actual',
                    vehicleCompetition: Async.init(),
                    data: Async.init(),
                },
                TaxationSearch: {
                    isRetrieving: false,
                    isInfoBlockVisible: false,
                    data: null,
                },
                TaxationSettings: {
                    isInfoBlockVisible: false,
                    isSaving: false,
                    isResetting: false,
                    data: Async.init(),
                    changes: {},
                },
                VehicleDashboard: {
                    isInfoBlockVisible: false,
                    data: Async.init(),
                },
                VehicleComparisonOverview: {
                    isInfoBlockVisible: false,
                    vehicleStatus: 'Actual',
                    sort: 'vraagprijs',
                    sortDirection: 'ASC',
                    page: 1,
                    amountPerPage: 10,
                    vehicleCompetition: Async.init(),
                },
                VehicleComparisonPriceAnalysis: {
                    isInfoBlockVisible: false,
                    vehicleStatus: 'Actual',
                    vehicleCompetition: Async.init(),
                },
                VehicleComparisonPriceHistory: {
                    isInfoBlockVisible: false,
                    vehicleStatus: 'Actual',
                    vehicleCompetition: Async.init(),
                    data: Async.init(),
                },
                VehicleComparisonGeographic: {
                    isInfoBlockVisible: false,
                    vehicleStatus: 'Actual',
                    vehicleCompetition: Async.init(),
                    data: Async.init(),
                },
                VehicleReachOverview: {
                    isInfoBlockVisible: false,
                    data: Async.init(),
                },
                VehicleReachPriceEffect: {
                    isInfoBlockVisible: false,
                    activeTab: 'clicks',
                    data: {
                        found: Async.init(),
                        viewed: Async.init(),
                        clicks: Async.init(),
                        contact: Async.init(),
                        share: Async.init(),
                    },
                },
                VehicleSettings: {
                    isInfoBlockVisible: false,
                    isSaving: false,
                    isResetting: false,
                    data: Async.init(),
                    changes: {},
                },
            },
        },
        flags.authentication
            ? [
                  Promise.resolve<Msg>(
                      Msg.Incoming_Token({
                          accessToken: flags.authentication.token,
                          expiration: flags.authentication.expiration,
                          isMasterAccount:
                              flags.authentication.isMaster ?? false,
                      }),
                  ),
              ]
            : [],
    ];
}

//---- View ----//

function view(dispatch: Dispatch<Msg>, state: Model): ApplicationDocument {
    // console.log('State', state);

    if (!state.isInitialized) {
        return {
            title: 'AutoTrack Analytics',
            body: viewTransitioningPage(state, html``),
        };
    }

    if (state.route.id !== 'Login' && state.isAuthenticating) {
        return {
            title: 'AutoTrack Analytics',
            body: viewTransitioningPage(state, html`U wordt ingelogd`),
        };
    }

    if (state.isRetrievingSession) {
        return {
            title: 'AutoTrack Analytics',
            body: viewTransitioningPage(state, html`U wordt ingelogd`),
        };
    }

    switch (state.route.id) {
        case 'Login': {
            return {
                title: 'Login | AutoTrack Analytics',
                body: shell(
                    state.deviceLayout,
                    loadedModules['Login']!.view(dispatch, state),
                ),
            };
        }

        case 'Dashboard': {
            return {
                title: 'Dashboard | AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Dashboard',
                    content: loadedModules['Dashboard']!.view(dispatch, state),
                    infoBlock: {
                        onToggle: dispatch(Msg.Dashboard_OnToggleInfoBlock),
                        isVisible: state.page.Dashboard.isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.Dashboard_OnPrint),
                }),
            };
        }

        case 'Stock': {
            return {
                title: 'Voorraad | AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Voorraad',
                    content: loadedModules['Stock']!.view(dispatch, state),
                    onDownloadExcel: dispatch(Msg.Stock_OnDownloadExcel),
                    infoBlock: {
                        onToggle: dispatch(Msg.Stock_OnToggleInfoBlock),
                        isVisible: state.page.Stock.isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.Stock_OnPrint),
                    pageNavigations: {
                        previous: {
                            href: '/dashboard',
                            label: 'Dashboard',
                        },
                        next: {
                            href: '/bereik',
                            label: 'Bereik',
                        },
                    },
                }),
            };
        }

        case 'Reach':
            return {
                title: 'Bereik | AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Netto bereik',
                    content: loadedModules['Reach']!.view(dispatch, state),
                    infoBlock: {
                        onToggle: dispatch(Msg.Reach_OnToggleInfoBlock),
                        isVisible: state.page.Reach.isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.Reach_OnPrint),
                    pageNavigations: {
                        previous: {
                            href: '/voorraad',
                            label: 'Voorraad',
                        },
                        next: {
                            href: '/leads',
                            label: 'Leads',
                        },
                    },
                }),
            };

        case 'Leads': {
            return {
                title: 'Leads | AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Leads',
                    content: loadedModules['Leads']!.view(dispatch, state),
                    onDownloadExcel: dispatch(Msg.Leads_OnDownloadExcel),
                    infoBlock: {
                        onToggle: dispatch(Msg.Leads_OnToggleInfoBlock),
                        isVisible: state.page.Leads.isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.Leads_OnPrint),
                    pageNavigations: {
                        previous: {
                            href: '/bereik',
                            label: 'Bereik',
                        },
                        next: {
                            href: '/analyse/overzicht',
                            label: 'Analyse overzicht',
                        },
                    },
                }),
            };
        }

        case 'Prospects': {
            return {
                title: 'Prospects | AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Prospects',
                    content: loadedModules['Prospects']!.view(dispatch, state),
                    onDownloadExcel: dispatch(Msg.Prospects_OnDownloadExcel),
                    infoBlock: {
                        onToggle: dispatch(Msg.Prospects_OnToggleInfoBlock),
                        isVisible: state.page.Prospects.isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.Prospects_OnPrint),
                    pageNavigations: {
                        previous: {
                            href: '/leads',
                            label: 'Leads',
                        },
                        next: {
                            href: '/analyse/overzicht',
                            label: 'Analyse overzicht',
                        },
                    },
                }),
            };
        }

        case 'AnalysisOverview': {
            return {
                title: 'Analyse | AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Analyse overzicht',
                    content: state.account?.isPremium
                        ? loadedModules['AnalysisOverview']!.view(
                              dispatch,
                              state,
                          )
                        : premiumPeak(
                              'Uw voorraad analyseren?',
                              loadedModules['AnalysisOverview']!.view(
                                  dispatch,
                                  state,
                              ),
                          ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.AnalysisOverview_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.AnalysisOverview.isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.AnalysisOverview_OnPrint),
                    pageNavigations: {
                        previous: {
                            href: '/leads',
                            label: 'Leads',
                        },
                        next: {
                            href: '/analyse/leadsprestatie',
                            label: 'Leads prestatie',
                        },
                    },
                }),
            };
        }

        case 'AnalysisLeadPerformance': {
            return {
                title: 'Analyse | AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Leads prestatie',
                    content: loadedModules['AnalysisLeadPerformance']!.view(
                        dispatch,
                        state,
                    ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.AnalysisLeadPerformance_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.AnalysisLeadPerformance
                                .isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.AnalysisLeadPerformance_OnPrint),
                    pageNavigations: {
                        previous: {
                            href: '/analyse/overzicht',
                            label: 'Overzicht',
                        },
                        next: {
                            href: '/analyse/merkmodel',
                            label: 'Merk/model',
                        },
                    },
                }),
            };
        }

        case 'AnalysisMakeModel': {
            return {
                title: 'Analyse | AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Merk/Model-analyse',
                    content: state.account?.isPremium
                        ? loadedModules['AnalysisMakeModel']!.view(
                              dispatch,
                              state,
                          )
                        : premiumPeak(
                              'Uw voorraad analyseren?',
                              loadedModules['AnalysisMakeModel']!.view(
                                  dispatch,
                                  state,
                              ),
                          ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.AnalysisMakeModel_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.AnalysisMakeModel.isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.AnalysisMakeModel_OnPrint),
                    pageNavigations: {
                        previous: {
                            href: '/analyse/leadsprestatie',
                            label: 'Leads prestatie',
                        },
                        next: {
                            href: '/analyse/kostenbaten',
                            label: 'Kosten en baten',
                        },
                    },
                }),
            };
        }

        case 'AnalysisCostBenefit': {
            return {
                title: 'Analyse | AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Kosten en baten',
                    content: state.account?.isPremium
                        ? loadedModules['AnalysisCostBenefit']!.view(
                              dispatch,
                              state,
                          )
                        : premiumPeak(
                              'De kosten en baten berekening bekijken?',
                              loadedModules['AnalysisCostBenefit']!.view(
                                  dispatch,
                                  state,
                              ),
                          ),
                    onPrint: dispatch(Msg.AnalysisCostBenefit_OnPrint),
                    pageNavigations: {
                        previous: {
                            href: '/analyse/merkmodel',
                            label: 'Merk/model',
                        },
                        next: {
                            href: '/vergelijk/adviesprijs',
                            label: 'Adviesprijs',
                        },
                    },
                }),
            };
        }

        case 'ComparisonOverview': {
            return {
                title: '',
                body: loadedModules['ComparisonOverview']!.view(
                    dispatch,
                    state,
                ),
            };
        }

        case 'ComparisonAdvicePrice': {
            return {
                title: 'Adviesprijs | AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Adviesprijs',
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.ComparisonAdvicePrice_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.ComparisonAdvicePrice.isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.ComparisonAdvicePrice_OnPrint),
                    content: state.account?.isPremium
                        ? loadedModules['ComparisonAdvicePrice']!.view(
                              dispatch,
                              state,
                          )
                        : premiumPeak(
                              'Alle bedrijven vergelijken?',
                              loadedModules['ComparisonAdvicePrice']!.view(
                                  dispatch,
                                  state,
                              ),
                          ),
                    pageNavigations: {
                        previous: {
                            href: '/bereik',
                            label: 'Bereik',
                        },
                        next: {
                            href: '/vergelijk/statijd',
                            label: 'Statijd',
                        },
                    },
                }),
            };
        }

        case 'ComparisonDaysInStock': {
            return {
                title: 'Statijd | Autotrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Statijd',
                    content: state.account?.isPremium
                        ? loadedModules['ComparisonDaysInStock']!.view(
                              dispatch,
                              state,
                          )
                        : premiumPeak(
                              'Alle bedrijven vergelijken?',
                              loadedModules['ComparisonDaysInStock']!.view(
                                  dispatch,
                                  state,
                              ),
                          ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.ComparisonDaysInStock_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.ComparisonDaysInStock.isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: '/vergelijk/adviesprijs',
                            label: 'Adviesprijs',
                        },
                        next: {
                            href: '/vergelijk/geografisch',
                            label: 'Geografisch',
                        },
                    },
                    onPrint: dispatch(Msg.ComparisonDaysInStock_OnPrint),
                }),
            };
        }

        case 'ComparisonGeographic': {
            return {
                title: 'Geographical | Autotrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Geografisch',
                    content: state.account?.isPremium
                        ? loadedModules['ComparisonGeographic']!.view(
                              dispatch,
                              state,
                          )
                        : premiumPeak(
                              'Bekijken waar uw concurrenten zich bevinden?',
                              loadedModules['ComparisonGeographic']!.view(
                                  dispatch,
                                  state,
                              ),
                          ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.ComparisonGeographic_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.ComparisonGeographic.isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: '/vergelijk/statijd',
                            label: 'Statijd',
                        },
                    },
                    onPrint: dispatch(Msg.ComparisonGeographic_OnPrint),
                }),
            };
        }

        case 'VehicleDashboard': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            return {
                title: 'AutoTrack Analytics',
                body: viewVehicleShell(dispatch, state, vehicle, {
                    pageTitle: 'Dashboard',
                    content: loadedModules['VehicleDashboard']!.view(
                        dispatch,
                        vehicle,
                        state,
                    ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.VehicleDashboard_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.VehicleDashboard.isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.VehicleDashboard_OnPrint),
                }),
            };
        }

        case 'VehicleReachOverview': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            return {
                title: 'AutoTrack Analytics',
                body: viewVehicleShell(dispatch, state, vehicle, {
                    pageTitle: 'Bereik',
                    content: loadedModules['VehicleReachOverview']!.view(
                        dispatch,
                        state,
                    ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.VehicleReachOverview_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.VehicleReachOverview.isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: `/voertuig/${vehicleId}/vergelijk/geografisch`,
                            label: 'Geografisch',
                        },
                        next: {
                            href: `/voertuig/${vehicleId}/bereik/prijseffect`,
                            label: 'Prijseffect',
                        },
                    },
                    onPrint: dispatch(Msg.VehicleReachOverview_OnPrint),
                }),
            };
        }

        case 'VehicleReachPriceEffect': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            return {
                title: 'AutoTrack Analytics',
                body: viewVehicleShell(dispatch, state, vehicle, {
                    pageTitle: 'Prijseffect',
                    content: loadedModules['VehicleReachPriceEffect']!.view(
                        dispatch,
                        state,
                        vehicle,
                    ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.VehicleReachPriceEffect_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.VehicleReachPriceEffect
                                .isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: `/voertuig/${vehicleId}/bereik/overzicht`,
                            label: 'Bereik',
                        },
                    },
                    onPrint: dispatch(Msg.VehicleReachPriceEffect_OnPrint),
                }),
            };
        }

        case 'VehicleComparisonOverview': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            return {
                title: 'AutoTrack Analytics',
                body: viewVehicleShell(dispatch, state, vehicle, {
                    pageTitle: "Overzicht auto's vergelijken",
                    content: state.account?.isPremium
                        ? loadedModules['VehicleComparisonOverview']!.view(
                              dispatch,
                              state,
                              vehicle,
                          )
                        : premiumPeak(
                              "Alle vergelijkbare auto's bekijken?",
                              loadedModules['VehicleComparisonOverview']!.view(
                                  dispatch,
                                  state,
                                  vehicle,
                              ),
                          ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.VehicleComparisonOverview_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.VehicleComparisonOverview
                                .isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: `/voertuig/${vehicleId}/dashboard`,
                            label: 'Dashboard',
                        },
                        next: {
                            href: `/voertuig/${vehicleId}/vergelijk/prijsanalyse`,
                            label: 'Prijsanalyse',
                        },
                    },
                    onPrint: dispatch(Msg.VehicleComparisonOverview_OnPrint),
                }),
            };
        }

        case 'VehicleComparisonPriceAnalysis': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            const competitionCount = Async.value(
                state.page.VehicleComparisonPriceAnalysis.vehicleCompetition,
            )?.amountOfVehicles;

            return {
                title: 'AutoTrack Analytics',
                body: viewVehicleShell(dispatch, state, vehicle, {
                    pageTitle:
                        competitionCount !== undefined
                            ? `Prijsanalyse van ${competitionCount} concurrerende auto's`
                            : 'Prijsanalyse',
                    content: state.account?.isPremium
                        ? loadedModules['VehicleComparisonPriceAnalysis']!.view(
                              dispatch,
                              state,
                              vehicle,
                          )
                        : premiumPeak(
                              "De prijsanalyse van alle vergelijkbare auto's bekijken?",
                              loadedModules[
                                  'VehicleComparisonPriceAnalysis'
                              ]!.view(dispatch, state, vehicle),
                          ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.VehicleComparisonPriceAnalysis_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.VehicleComparisonPriceAnalysis
                                .isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: `/voertuig/${vehicleId}/vergelijk/overzicht`,
                            label: "Overzicht auto's vergelijken",
                        },
                        next: {
                            href: `/voertuig/${vehicleId}/vergelijk/prijshistorie`,
                            label: 'Prijshistorie',
                        },
                    },
                    onPrint: dispatch(
                        Msg.VehicleComparisonPriceAnalysis_OnPrint,
                    ),
                }),
            };
        }

        case 'VehicleComparisonPriceHistory': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            const competitionCount = Async.value(
                state.page.VehicleComparisonPriceAnalysis.vehicleCompetition,
            )?.amountOfVehicles;

            return {
                title: 'AutoTrack Analytics',
                body: viewVehicleShell(dispatch, state, vehicle, {
                    pageTitle: competitionCount
                        ? `Prijshistorie van ${competitionCount} concurrerende auto's`
                        : 'Prijshistorie',
                    content: state.account?.isPremium
                        ? loadedModules['VehicleComparisonPriceHistory']!.view(
                              dispatch,
                              state,
                              vehicle,
                          )
                        : premiumPeak(
                              "De prijshistorie van alle vergelijkbare auto's bekijken?",
                              loadedModules[
                                  'VehicleComparisonPriceHistory'
                              ]!.view(dispatch, state, vehicle),
                          ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.VehicleComparisonPriceHistory_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.VehicleComparisonPriceHistory
                                .isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: `/voertuig/${vehicleId}/vergelijk/prijsanalyse`,
                            label: 'Prijsanalyse',
                        },
                        next: {
                            href: `/voertuig/${vehicleId}/vergelijk/geografisch`,
                            label: 'Geografisch',
                        },
                    },
                    onPrint: dispatch(
                        Msg.VehicleComparisonPriceHistory_OnPrint,
                    ),
                }),
            };
        }

        case 'VehicleComparisonGeographic': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            return {
                title: 'AutoTrack Analytics',
                body: viewVehicleShell(dispatch, state, vehicle, {
                    pageTitle: 'Geografisch',
                    content: state.account?.isPremium
                        ? loadedModules['VehicleComparisonGeographic']!.view(
                              dispatch,
                              state,
                              vehicle,
                          )
                        : premiumPeak(
                              "Bekijken waar de vergelijkbare auto's zich bevinden?",
                              loadedModules[
                                  'VehicleComparisonGeographic'
                              ]!.view(dispatch, state, vehicle),
                          ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.VehicleComparisonGeographic_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.VehicleComparisonGeographic
                                .isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: `/voertuig/${vehicleId}/vergelijk/prijshistorie`,
                            label: 'Prijshistorie',
                        },
                        next: {
                            href: `/voertuig/${vehicleId}/bereik/overzicht`,
                            label: 'Bereik',
                        },
                    },
                    onPrint: dispatch(Msg.VehicleComparisonGeographic_OnPrint),
                }),
            };
        }

        case 'VehicleSettings': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            return {
                title: 'AutoTrack Analytics',
                body: viewVehicleShell(dispatch, state, vehicle, {
                    pageTitle: 'Eigenschappen',
                    content: loadedModules['VehicleSettings']!.view(
                        dispatch,
                        state,
                        vehicle,
                    ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.VehicleSettings_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.VehicleSettings.isInfoBlockVisible,
                    },
                }),
            };
        }

        case 'TaxationSearch': {
            return {
                title: '',
                body: loadedModules['TaxationSearch']!.view(dispatch, state),
            };
        }

        case 'TaxationDashboard': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            return {
                title: 'AutoTrack Analytics',
                body: viewTaxationShell(dispatch, state, vehicle, {
                    pageTitle: 'Dashboard',
                    content: loadedModules['TaxationDashboard']!.view(
                        dispatch,
                        vehicle,
                        state,
                    ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.TaxationDashboard_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.TaxationDashboard.isInfoBlockVisible,
                    },
                    onPrint: dispatch(Msg.TaxationDashboard_OnPrint),
                }),
            };
        }

        case 'TaxationComparisonOverview': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            return {
                title: 'AutoTrack Analytics',
                body: viewTaxationShell(dispatch, state, vehicle, {
                    pageTitle: "Overzicht auto's vergelijken",
                    content: loadedModules['TaxationComparisonOverview']!.view(
                        dispatch,
                        vehicle,
                        state,
                    ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.TaxationComparisonOverview_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.TaxationComparisonOverview
                                .isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: `/taxatie/${vehicleId}/dashboard`,
                            label: 'Dashboard',
                        },
                        next: {
                            href: `/taxatie/${vehicleId}/vergelijk/prijsanalyse`,
                            label: 'Prijsanalyse',
                        },
                    },
                    onPrint: dispatch(Msg.TaxationComparisonOverview_OnPrint),
                }),
            };
        }

        case 'TaxationComparisonPriceAnalysis': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            const competitionCount = Async.value(
                state.page.TaxationComparisonPriceAnalysis.vehicleCompetition,
            )?.amountOfVehicles;

            return {
                title: 'AutoTrack Analytics',
                body: viewTaxationShell(dispatch, state, vehicle, {
                    pageTitle: competitionCount
                        ? `Prijsanalyse van ${competitionCount} concurrerende auto's`
                        : 'Prijsanalyse',
                    content: loadedModules[
                        'TaxationComparisonPriceAnalysis'
                    ]!.view(dispatch, vehicle, state),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.TaxationComparisonPriceAnalysis_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.TaxationComparisonPriceAnalysis
                                .isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: `/taxatie/${vehicleId}/vergelijk/overzicht`,
                            label: "Overzicht auto's vergelijken",
                        },
                        next: {
                            href: `/taxatie/${vehicleId}/vergelijk/prijshistorie`,
                            label: 'Prijshistorie',
                        },
                    },
                    onPrint: dispatch(
                        Msg.TaxationComparisonPriceAnalysis_OnPrint,
                    ),
                }),
            };
        }

        case 'TaxationComparisonPriceHistory': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            const competitionCount = Async.value(
                state.page.TaxationComparisonPriceHistory.vehicleCompetition,
            )?.amountOfVehicles;

            return {
                title: 'AutoTrack Analytics',
                body: viewTaxationShell(dispatch, state, vehicle, {
                    pageTitle: competitionCount
                        ? `Prijshistorie van ${competitionCount} concurrerende auto's`
                        : 'Prijshistorie',
                    content: loadedModules[
                        'TaxationComparisonPriceHistory'
                    ]!.view(dispatch, vehicle, state),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.TaxationComparisonPriceHistory_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.TaxationComparisonPriceHistory
                                .isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: `/taxatie/${vehicleId}/vergelijk/prijsanalyse`,
                            label: 'Prijsanalyse',
                        },
                        next: {
                            href: `/taxatie/${vehicleId}/vergelijk/geografisch`,
                            label: 'Geografisch',
                        },
                    },
                    onPrint: dispatch(
                        Msg.TaxationComparisonPriceHistory_OnPrint,
                    ),
                }),
            };
        }

        case 'TaxationComparisonGeographic': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            return {
                title: 'AutoTrack Analytics',
                body: viewTaxationShell(dispatch, state, vehicle, {
                    pageTitle: 'Geografisch',
                    content: loadedModules[
                        'TaxationComparisonGeographic'
                    ]!.view(dispatch, vehicle, state),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.TaxationComparisonGeographic_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.TaxationComparisonGeographic
                                .isInfoBlockVisible,
                    },
                    pageNavigations: {
                        previous: {
                            href: `/taxatie/${vehicleId}/vergelijk/prijshistorie`,
                            label: 'Prijshistorie',
                        },
                    },
                    onPrint: dispatch(Msg.TaxationComparisonGeographic_OnPrint),
                }),
            };
        }

        case 'TaxationSettings': {
            const vehicleId = state.route.params.vehicleId;
            const vehicle = state.cachedVehicles.get(vehicleId)?.[0];

            if (vehicle === undefined) {
                return {
                    title: '',
                    body: viewTransitioningPage(
                        state,
                        html`Voertuig wordt opgehaald`,
                    ),
                };
            }

            return {
                title: 'AutoTrackAnalytics',
                body: viewTaxationShell(dispatch, state, vehicle, {
                    pageTitle: 'Eigenschappen',
                    content: loadedModules['TaxationSettings']!.view(
                        dispatch,
                        state,
                        vehicle,
                    ),
                    infoBlock: {
                        onToggle: dispatch(
                            Msg.TaxationSettings_OnToggleInfoBlock,
                        ),
                        isVisible:
                            state.page.TaxationSettings.isInfoBlockVisible,
                    },
                }),
            };
        }

        case 'SettingsStock': {
            return {
                title: 'AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Voorraad instellingen',
                    content: loadedModules['SettingsStock']!.view(
                        dispatch,
                        state,
                    ),
                    hasSettings: true,
                }),
            };
        }

        case 'SettingsCompetingVehicles': {
            return {
                title: 'AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: "Vergelijkbare auto's",
                    content: loadedModules['SettingsCompetingVehicles']!.view(
                        dispatch,
                        state,
                    ),
                    hasSettings: true,
                }),
            };
        }

        case 'SettingsCompetingCompanies': {
            return {
                title: '',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Profielen vergelijkbare bedrijven',
                    content: loadedModules[
                        'SettingsCompetingCompanies'
                    ]!.viewProfileList(dispatch, state),
                    hasSettings: true,
                }),
            };
        }

        case 'SettingsCompetingCompaniesProfile': {
            return {
                title: '',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Profielen vergelijkbare bedrijven',
                    content: loadedModules[
                        'SettingsCompetingCompanies'
                    ]!.viewProfile(dispatch, state),
                    hasSettings: true,
                }),
            };
        }

        case 'SettingsNotifications': {
            return {
                title: 'AutoTrack Analytics',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Doelstellingen en meldingen',
                    content: loadedModules['SettingsNotifications']!.view(
                        dispatch,
                        state,
                    ),
                    hasSettings: true,
                }),
            };
        }

        case 'SettingsBranches': {
            return {
                title: '',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Rapportage aanpassen',
                    content: loadedModules['SettingsBranches']!.view(
                        dispatch,
                        state,
                    ),
                    hasSettings: true,
                }),
            };
        }

        case 'SettingsScenarios': {
            return {
                title: '',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Clusters aanpassen',
                    content: loadedModules[
                        'SettingsScenarios'
                    ]!.viewScenarioList(dispatch, state),
                    hasSettings: true,
                }),
            };
        }

        case 'SettingsScenario': {
            return {
                title: '',
                body: viewMainShell(dispatch, state, {
                    pageTitle: 'Clusters aanpassen',
                    content: loadedModules['SettingsScenarios']!.viewScenario(
                        dispatch,
                        state,
                    ),
                    hasSettings: true,
                }),
            };
        }

        case 'Logout': {
            return {
                title: 'AutoTrack Analytics',
                body: viewTransitioningPage(state, html`U wordt uitgelogd`),
            };
        }

        default:
            return {
                title: 'Pagina niet gevonden | Autotrack Analytics',
                body: shell(
                    state.deviceLayout,
                    viewNotFoundPage({
                        onNavigateBack: dispatch(Msg.NavigateBack),
                        onNavigate: dispatch(Msg.Navigate),
                    }),
                ),
            };
    }
}

function viewTransitioningPage(state: Model, content: Html): Html {
    return shell(state.deviceLayout, loadingLogo(content));
}

function viewMainShell(
    dispatch: Dispatch<Msg>,
    state: Model,
    config: {
        pageTitle: string;
        content: Html;
        onDownloadExcel?: () => void;
        infoBlock?: {
            onToggle: () => void;
            isVisible: boolean;
        };
        onPrint?: () => void;
        pageNavigations?: {
            previous?: { href: string; label: string };
            next?: { href: string; label: string };
        };
        hasSettings?: boolean;
    },
): Html {
    return mainShell({
        deviceLayout: state.deviceLayout,
        account: state.account!,
        activeRoute: state.route,
        isNavigationVisible: state.isNavigationVisible,
        onNavigate: dispatch(Msg.Navigate),
        onToggleNavigation: dispatch(Msg.ToggleNavigation),
        pageTitle: config.pageTitle,
        pageActions: [
            config.onDownloadExcel === undefined
                ? nothing
                : denseStrokedButton({
                      content: html`${Icon.download()}
                          <span>Download excel</span>`,
                      onClick: config.onDownloadExcel,
                      theme: 'Secondary',
                  }),
            config.infoBlock === undefined
                ? nothing
                : config.infoBlock.isVisible
                  ? denseFilledButton({
                        content: Icon.info(),
                        onClick: config.infoBlock.onToggle,
                        theme: 'Secondary',
                    })
                  : denseStrokedButton({
                        content: Icon.info(),
                        onClick: config.infoBlock.onToggle,
                        theme: 'Secondary',
                    }),
            config.onPrint === undefined
                ? nothing
                : denseStrokedButton({
                      content: Icon.print(),
                      onClick: config.onPrint,
                      theme: 'Secondary',
                  }),
        ],
        content: config.content,
        stockCount: state.settings.stockCount,
        dealerCount: state.settings.dealerCount,
        pageNavigations: config.pageNavigations,
        hasSettings: config.hasSettings,
        taxationWidget: toTaxationWidget(dispatch, state),
    });
}

function viewVehicleShell(
    dispatch: Dispatch<Msg>,
    state: Model,
    vehicle: Vehicle,
    config: {
        pageTitle: string;
        content: Html;
        infoBlock?: {
            onToggle: () => void;
            isVisible: boolean;
        };
        onPrint?: () => void;
        pageNavigations?: {
            previous?: { href: string; label: string };
            next?: { href: string; label: string };
        };
    },
): Html {
    return vehicleShell({
        deviceLayout: state.deviceLayout,
        pageTitle: config.pageTitle,
        pageActions: [
            config.infoBlock === undefined
                ? nothing
                : config.infoBlock.isVisible
                  ? denseFilledButton({
                        content: Icon.info(),
                        onClick: config.infoBlock.onToggle,
                        theme: 'Secondary',
                    })
                  : denseStrokedButton({
                        content: Icon.info(),
                        onClick: config.infoBlock.onToggle,
                        theme: 'Secondary',
                    }),
            config.onPrint === undefined
                ? nothing
                : denseStrokedButton({
                      content: Icon.print(),
                      onClick: config.onPrint,
                      theme: 'Secondary',
                  }),
        ],
        vehicle: vehicle,
        activeRoute: state.route,
        account: state.account!,
        content: config.content,
        isNavigationVisible: state.isNavigationVisible,
        onNavigate: dispatch(Msg.Navigate),
        onToggleNavigation: dispatch(Msg.ToggleNavigation),
        pageNavigations: config.pageNavigations,
        taxationWidget: toTaxationWidget(dispatch, state),
    });
}

function viewTaxationShell(
    dispatch: Dispatch<Msg>,
    state: Model,
    vehicle: Vehicle,
    config: {
        pageTitle: string;
        content: Html;
        infoBlock?: {
            onToggle: () => void;
            isVisible: boolean;
        };
        onPrint?: () => void;
        pageNavigations?: {
            previous?: { href: string; label: string };
            next?: { href: string; label: string };
        };
    },
): Html {
    return taxationShell({
        deviceLayout: state.deviceLayout,
        pageTitle: config.pageTitle,
        pageActions: [
            config.infoBlock === undefined
                ? nothing
                : config.infoBlock.isVisible
                  ? denseFilledButton({
                        content: Icon.info(),
                        onClick: config.infoBlock.onToggle,
                        theme: 'Secondary',
                    })
                  : denseStrokedButton({
                        content: Icon.info(),
                        onClick: config.infoBlock.onToggle,
                        theme: 'Secondary',
                    }),
            config.onPrint === undefined
                ? nothing
                : denseStrokedButton({
                      content: Icon.print(),
                      onClick: config.onPrint,
                      theme: 'Secondary',
                  }),
        ],
        activeRoute: state.route,
        vehicle: vehicle,
        account: state.account!,
        content: config.content,
        isNavigationVisible: state.isNavigationVisible,
        onNavigate: dispatch(Msg.Navigate),
        onToggleNavigation: dispatch(Msg.ToggleNavigation),
        pageNavigations: config.pageNavigations,
        taxationWidget: toTaxationWidget(dispatch, state),
    });
}

//---- Update ----//

function update(msg: Msg, state: Model): [Model, Cmd<Msg>] {
    // console.log('Msg', msg);

    switch (msg.type) {
        case 'DeviceLayout': {
            state.deviceLayout = msg.payload;
            return [state, []];
        }

        case 'NavigateBack': {
            history.back();
            return [state, []];
        }

        case 'Navigate': {
            router.navigate(msg.payload);
            return [state, []];
        }

        case 'OnRoute': {
            const route: Route = msg.payload;

            state.isInitialized = true;
            state.route = route;

            if (
                state.account === null &&
                !state.isAuthenticating &&
                !state.isRetrievingSession &&
                route.id !== 'Login'
            ) {
                return [state, [Promise.resolve(Msg.Navigate('/login'))]];
            } else if (state.account !== null && route.id === 'Login') {
                return [state, [Promise.resolve(Msg.Navigate('/'))]];
            }

            switch (route.id) {
                case 'Login': {
                    return [state, []];
                }

                case 'Logout': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    return [
                        state,
                        [
                            Api.unauthenticate(
                                state.account.accessToken,
                                (isSuccessful) =>
                                    Msg.Application_IncomingLogout({
                                        isSuccessful,
                                    }),
                            ),
                        ],
                    ];
                }

                case 'Dashboard': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    return [
                        state,
                        [
                            Api.retrieveDealerDashboard(
                                state.account.accessToken,
                                Msg.Dashboard_IncomingDealerDashboard,
                            ),
                        ],
                    ];
                }

                case 'Stock': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.Stock.isRetrieving = true;
                    return [
                        state,
                        [
                            retrieveDealerStock(
                                state.account.accessToken,
                                state.page.Stock,
                            ),
                        ],
                    ];
                }

                case 'Reach': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    return [
                        state,
                        [
                            Api.retrieveDealerReach(
                                state.account.accessToken,
                                state.page.Reach.period,
                                Msg.Reach_Incoming,
                            ),
                        ],
                    ];
                }

                case 'Leads': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    return [
                        state,
                        [
                            retrieveDealerLeads(
                                state.account.accessToken,
                                state.page.Leads,
                            ),
                        ],
                    ];
                }

                case 'Prospects': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    return [
                        state,
                        [
                            retrieveDealerProspects(
                                state.account.accessToken,
                                state.page.Prospects,
                            ),
                        ],
                    ];
                }

                case 'AnalysisOverview': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.AnalysisOverview.isRetrieving = true;

                    return [
                        state,
                        [
                            retrieveAnalysisOverview(
                                state.account.accessToken,
                                state.page.AnalysisOverview,
                            ),
                        ],
                    ];
                }

                case 'AnalysisLeadPerformance': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.AnalysisLeadPerformance.isRetrieving = true;

                    return [
                        state,
                        [
                            retrieveAnalysisLeadPerformance(
                                state.account.accessToken,
                                state.page.AnalysisLeadPerformance,
                            ),
                        ],
                    ];
                }

                case 'AnalysisMakeModel': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.AnalysisMakeModel.isRetrieving = true;

                    return [
                        state,
                        [
                            Api.retrieveDealerAnalysisMakeModel(
                                state.account.accessToken,
                                Msg.AnalysisMakeModel_Incoming,
                            ),
                        ],
                    ];
                }

                case 'AnalysisCostBenefit': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.AnalysisCostBenefit.isRetrieving = true;

                    return [
                        state,
                        [
                            Api.retrieveDealerAnalysisCostBenefit(
                                state.account.accessToken,
                                Msg.AnalysisCostBenefit_Incoming,
                            ),
                        ],
                    ];
                }

                case 'ComparisonAdvicePrice': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    if (state.settings.competingDealers === null) {
                        return [state, []];
                    }

                    state.page.ComparisonAdvicePrice.isRetrieving = true;

                    return [
                        state,
                        [
                            retrieveComparisonAdvicePrice(
                                state.account.accessToken,
                                state.settings.competingDealers[0],
                                state.page.ComparisonAdvicePrice,
                            ),
                        ],
                    ];
                }

                case 'ComparisonDaysInStock': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    if (state.settings.competingDealers === null) {
                        return [state, []];
                    }

                    state.page.ComparisonAdvicePrice.isRetrieving = true;

                    return [
                        state,
                        [
                            retrieveComparisonDaysInStock(
                                state.account.accessToken,
                                state.settings.competingDealers[0],
                                state.page.ComparisonDaysInStock,
                            ),
                            retrieveNoticationsSettings(
                                state.account.accessToken,
                                (payload) =>
                                    Msg.Incoming_DaysInStockGoal(
                                        Result.map(
                                            payload,
                                            ({ goaldaysinstock }) =>
                                                goaldaysinstock,
                                        ),
                                    ),
                            ),
                        ],
                    ];
                }

                case 'ComparisonGeographic': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    if (state.settings.competingDealers === null) {
                        return [state, []];
                    }

                    state.page.ComparisonOverview.isRetrieving = true;

                    return [
                        state,
                        [
                            retrieveComparisonGeographic(
                                state.account.accessToken,
                                state.settings.competingDealers[0],
                                state.page.ComparisonGeographic,
                            ),
                        ],
                    ];
                }

                case 'VehicleDashboard': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.VehicleDashboard.data = Async.markAsLoading(
                        state.page.VehicleDashboard.data,
                    );

                    return onRouteToVehiclePage([
                        state,
                        [
                            Api.retrieveVehicleDashboard(
                                state.account.accessToken,
                                route.params.vehicleId,
                                (result) =>
                                    Msg.VehicleDashboard_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'VehicleReachOverview': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.VehicleReachOverview.data = Async.markAsLoading(
                        state.page.VehicleReachOverview.data,
                    );

                    return onRouteToVehiclePage([
                        state,
                        [
                            Api.retrieveVehicleReach(
                                state.account.accessToken,
                                route.params.vehicleId,
                                (result) =>
                                    Msg.VehicleReachOverview_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'VehicleReachPriceEffect': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    const activeTab =
                        state.page.VehicleReachPriceEffect.activeTab;

                    state.page.VehicleReachPriceEffect.data[activeTab] =
                        Async.markAsLoading(
                            state.page.VehicleReachPriceEffect.data[activeTab],
                        );

                    return onRouteToVehiclePage([
                        state,
                        [
                            Api.retrieveVehiclePriceEffect(
                                state.account.accessToken,
                                route.params.vehicleId,
                                activeTab,
                                (result) =>
                                    Msg.VehicleReachPriceEffect_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        type: activeTab,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'VehicleComparisonOverview': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.VehicleComparisonOverview.vehicleCompetition =
                        Async.markAsLoading(
                            state.page.VehicleComparisonOverview
                                .vehicleCompetition,
                        );

                    return onRouteToVehiclePage([
                        state,
                        [
                            Api.retrieveVehicleCompetition(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.VehicleComparison_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'VehicleComparisonPriceAnalysis': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.VehicleComparisonPriceAnalysis.vehicleCompetition =
                        Async.markAsLoading(
                            state.page.VehicleComparisonPriceAnalysis
                                .vehicleCompetition,
                        );

                    return onRouteToVehiclePage([
                        state,
                        [
                            Api.retrieveVehicleCompetition(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.VehicleComparison_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'VehicleComparisonPriceHistory': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.VehicleComparisonPriceHistory.vehicleCompetition =
                        Async.markAsLoading(
                            state.page.VehicleComparisonPriceHistory
                                .vehicleCompetition,
                        );

                    return onRouteToVehiclePage([
                        state,
                        [
                            Api.retrieveVehicleCompetition(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.VehicleComparison_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                            Api.retrieveVehiclePriceHistory(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.VehicleComparisonPriceHistory_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'VehicleComparisonGeographic': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.VehicleComparisonGeographic.vehicleCompetition =
                        Async.markAsLoading(
                            state.page.VehicleComparisonGeographic
                                .vehicleCompetition,
                        );

                    return onRouteToVehiclePage([
                        state,
                        [
                            Api.retrieveVehicleCompetition(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.VehicleComparison_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                            Api.retrieveVehicleGeographic(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.VehicleComparisonGeographic_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'VehicleSettings': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.VehicleSettings.data = Async.markAsLoading(
                        state.page.VehicleSettings.data,
                    );

                    return onRouteToVehiclePage([
                        state,
                        [
                            Api.retrieveVehicleCompetitionSettings(
                                state.account.accessToken,
                                route.params.vehicleId,
                                (result) =>
                                    Msg.VehicleSettings_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'SettingsScenarios': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.SettingsScenarios.data = Async.markAsLoading(
                        state.page.SettingsScenarios.data,
                    );

                    return [
                        state,
                        [
                            Api.retrieveSettingsScenarios(
                                state.account.accessToken,
                                (result) =>
                                    Msg.SettingsScenarios_Incoming(result),
                            ),
                        ],
                    ];
                }

                case 'SettingsScenario': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    if (route.params.scenarioId === null) {
                        state.page.SettingsScenario.data = Async.markAsLoaded(
                            state.page.SettingsScenario.data,
                            null,
                        );
                        return onRouteToPageWithDealers([state, []]);
                    }

                    state.page.SettingsScenario.data = Async.markAsLoading(
                        state.page.SettingsScenario.data,
                    );

                    return onRouteToPageWithDealers([
                        state,
                        [
                            Api.retrieveSettingsScenario(
                                state.account.accessToken,
                                route.params.scenarioId,
                                Msg.SettingsScenario_Incoming,
                            ),
                        ],
                    ]);
                }

                case 'SettingsBranches': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.SettingsBranches.scenarioList =
                        Async.markAsLoading(
                            state.page.SettingsBranches.scenarioList,
                        );

                    return onRouteToPageWithDealers([
                        state,
                        [
                            Api.retrieveDealerFilter(
                                state.account.accessToken,
                                Msg.SettingsBranches_IncomingDealerFilter,
                            ),
                            Api.retrieveSettingsScenarios(
                                state.account.accessToken,
                                Msg.SettingsBranches_IncomingScenarioList,
                            ),
                        ],
                    ]);
                }

                case 'SettingsCompetingCompanies': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.SettingsCompetingCompanies.profiles =
                        Async.markAsLoading(
                            state.page.SettingsCompetingCompanies.profiles,
                        );

                    return [
                        state,
                        [
                            Api.retrieveDealerProfilesSettings(
                                state.account.accessToken,
                                Msg.SettingsCompetingDealers_Incoming,
                            ),
                        ],
                    ];
                }

                case 'SettingsCompetingCompaniesProfile': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.SettingsCompetingCompaniesProfile.filtersMetadata =
                        Async.markAsLoading(
                            state.page.SettingsCompetingCompaniesProfile
                                .filtersMetadata,
                        );

                    state.page.SettingsCompetingCompaniesProfile.profile =
                        Async.markAsLoading(
                            state.page.SettingsCompetingCompaniesProfile
                                .profile,
                        );

                    return [
                        state,
                        [
                            Api.retrieveSettingsDealerProfileFilters(
                                state.account.accessToken,
                                Msg.SettingsCompetingDealersProfile_IncomingFilters,
                            ),
                            Api.retrieveDealerProfilesSettings(
                                state.account.accessToken,
                                (result) =>
                                    Msg.SettingsCompetingDealersProfile_Incoming(
                                        Result.map(
                                            result,
                                            (profiles) =>
                                                profiles.find(
                                                    (profile) =>
                                                        profile.id ===
                                                        route.params.profileId,
                                                ) ?? null,
                                        ),
                                    ),
                            ),
                        ],
                    ];
                }

                case 'SettingsStock': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.SettingsStock.data = Async.markAsLoading(
                        state.page.SettingsStock.data,
                    );

                    return [
                        state,
                        [
                            Api.retrieveStockSettings(
                                state.account.accessToken,
                                (result) =>
                                    Msg.SettingsStock_Incoming({ result }),
                            ),
                            retrieveSettingsStockVehicleCount(
                                state.account.accessToken,
                            ),
                        ],
                    ];
                }

                case 'SettingsNotifications': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.SettingsNotifications.data = Async.markAsLoading(
                        state.page.SettingsNotifications.data,
                    );

                    return [
                        state,
                        [
                            Api.retrieveNoticationsSettings(
                                state.account.accessToken,
                                (result) =>
                                    Msg.SettingsNotifications_Incoming({
                                        result,
                                    }),
                            ),
                        ],
                    ];
                }

                case 'SettingsCompetingVehicles': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.SettingsCompetingVehicles.data =
                        Async.markAsLoading(
                            state.page.SettingsCompetingVehicles.data,
                        );

                    return [
                        state,
                        [
                            Api.retrieveVehicleCompetitionSettings(
                                state.account.accessToken,
                                '0',
                                (result) =>
                                    Msg.SettingsCompetingVehicles_Incoming({
                                        result,
                                    }),
                            ),
                        ],
                    ];
                }

                case 'TaxationDashboard': {
                    if (state.account == null) {
                        return [state, []];
                    }

                    state.page.TaxationDashboard.data = Async.markAsLoading(
                        state.page.TaxationDashboard.data,
                    );

                    return onRouteToTaxationPage([
                        state,
                        [
                            Api.retrieveTaxationDashboard(
                                state.account.accessToken,
                                route.params.vehicleId,
                                (result) =>
                                    Msg.TaxationDashboard_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'TaxationComparisonOverview': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.TaxationComparisonOverview.vehicleCompetition =
                        Async.markAsLoading(
                            state.page.TaxationComparisonOverview
                                .vehicleCompetition,
                        );

                    return onRouteToTaxationPage([
                        state,
                        [
                            Api.retrieveTaxationCompetition(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.TaxationComparison_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'TaxationComparisonPriceAnalysis': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.TaxationComparisonPriceAnalysis.vehicleCompetition =
                        Async.markAsLoading(
                            state.page.TaxationComparisonPriceAnalysis
                                .vehicleCompetition,
                        );

                    return onRouteToTaxationPage([
                        state,
                        [
                            Api.retrieveTaxationCompetition(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.TaxationComparison_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'TaxationComparisonPriceHistory': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.TaxationComparisonPriceHistory.vehicleCompetition =
                        Async.markAsLoading(
                            state.page.TaxationComparisonPriceHistory
                                .vehicleCompetition,
                        );

                    return onRouteToTaxationPage([
                        state,
                        [
                            Api.retrieveTaxationCompetition(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.TaxationComparison_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                            Api.retrieveTaxationPriceHistory(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.TaxationComparisonPriceHistory_Incoming(
                                        {
                                            vehicleId: route.params.vehicleId,
                                            result,
                                        },
                                    ),
                            ),
                        ],
                    ]);
                }

                case 'TaxationComparisonGeographic': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.TaxationComparisonGeographic.vehicleCompetition =
                        Async.markAsLoading(
                            state.page.TaxationComparisonGeographic
                                .vehicleCompetition,
                        );

                    return onRouteToTaxationPage([
                        state,
                        [
                            Api.retrieveTaxationCompetition(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.TaxationComparison_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                            Api.retrieveTaxationGeography(
                                state.account.accessToken,
                                route.params.vehicleId,
                                false,
                                (result) =>
                                    Msg.TaxationComparisonGeographic_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }

                case 'TaxationSettings': {
                    if (state.account === null) {
                        return [state, []];
                    }

                    state.page.TaxationSettings.data = Async.markAsLoading(
                        state.page.TaxationSettings.data,
                    );

                    return onRouteToTaxationPage([
                        state,
                        [
                            Api.retrieveTaxationSettings(
                                state.account.accessToken,
                                route.params.vehicleId,
                                (result) =>
                                    Msg.TaxationSettings_Incoming({
                                        vehicleId: route.params.vehicleId,
                                        result,
                                    }),
                            ),
                        ],
                    ]);
                }
            }

            break;
        }

        case 'ToggleNavigation': {
            state.isNavigationVisible = !state.isNavigationVisible;
            return [state, []];
        }

        case 'TaxationWidget_OnInputLicensePlate': {
            state.taxationWidget.licensePlate = msg.payload;
            return [state, []];
        }

        case 'TaxationWidget_OnInputMileage': {
            state.taxationWidget.mileage = msg.payload;
            return [state, []];
        }

        case 'TaxationWidget_OnSubmit': {
            state.taxationWidget.errorMessage = null;

            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.searchTaxation(
                        state.account.accessToken,
                        state.taxationWidget.licensePlate,
                        Number(state.taxationWidget.mileage),
                        Msg.TaxationWidget_IncomingSubmit,
                    ),
                ],
            ];
        }

        case 'TaxationWidget_OnClearErrorMessage': {
            state.taxationWidget.errorMessage = null;
            return [state, []];
        }

        case 'TaxationWidget_IncomingSubmit': {
            return Result.match(msg.payload, {
                Err: (error) => {
                    switch (error.type) {
                        case 'TaxationNotFound': {
                            state.taxationWidget.errorMessage =
                                'Voertuig niet gevonden';
                            break;
                        }

                        default: {
                            state.taxationWidget.errorMessage =
                                'Er ging iets fout';
                            break;
                        }
                    }

                    return [state, []];
                },
                Ok: ({ id }) => [
                    state,
                    [Promise.resolve(Msg.Navigate(`/taxatie/${id}/dashboard`))],
                ],
            });
        }

        case 'Application_LoginWithToken': {
            const defaultExpirationTime = 7 * 60 * 60 * 1000;

            state.isAuthenticating = true;

            return [
                state,
                [
                    Api.authenticateWithToken(
                        msg.payload,
                        (payload): Msg =>
                            Msg.Incoming_Token({
                                accessToken: payload.accessToken,
                                expiration: new Date(
                                    Date.now() + defaultExpirationTime,
                                ),
                                isMasterAccount: msg.payload.master,
                            }),
                    ),
                ],
            ];
        }

        case 'Application_IncomingLogout': {
            if (msg.payload.isSuccessful) {
                state.account = null;
                sessionStorage.removeItem('authentication');

                return [state, [Promise.resolve(Msg.Navigate('/login'))]];
            } else {
                return [state, []];
            }
        }

        case 'Incoming_Session': {
            state.isRetrievingSession = false;

            return Result.match(msg.payload, {
                Err: () => {
                    return [state, []];
                },
                Ok: (payload) => {
                    state.account = {
                        name: payload.accountName,
                        type: payload.accountType,
                        accessToken: payload.accessToken,
                        isMaster: payload.isMasterAccount,
                        isPremium: payload.isPremiumAccount,
                        lastUpdate: payload.lastUpdate,
                    };

                    state.settings.stockCount = payload.stockCount;

                    return [
                        state,
                        [
                            ...(state.isInitialized
                                ? [Promise.resolve(Msg.OnRoute(state.route))]
                                : []),
                            Api.retrieveDealerProfilesSettings(
                                state.account.accessToken,
                                Msg.Incoming_CompetingDealerProfiles,
                            ),
                            Api.retrieveDealerFilter(
                                state.account.accessToken,
                                (result) =>
                                    Msg.Incoming_DealerFilter(
                                        Result.map(
                                            result,
                                            (data) => data.dealers,
                                        ),
                                    ),
                            ),
                        ],
                    ];
                },
            });
        }

        case 'Incoming_Token': {
            state.isAuthenticating = false;

            if (msg.payload.expiration < new Date()) {
                // console.error('Token expired');
                state.account = null;
                sessionStorage.removeItem('authentication');
                return [state, []];
            }

            sessionStorage.setItem(
                'authentication',
                JSON.stringify(
                    msg.payload.isMasterAccount
                        ? {
                              token: msg.payload.accessToken,
                              expiration: msg.payload.expiration.valueOf(),
                              isMaster: true,
                          }
                        : {
                              token: msg.payload.accessToken,
                              expiration: msg.payload.expiration.valueOf(),
                          },
                ),
            );

            state.isRetrievingSession = true;

            return [
                state,
                [
                    Api.retrieveSession(
                        msg.payload.accessToken,
                        (result): Msg =>
                            Msg.Incoming_Session(
                                Result.map(result, (payload) => ({
                                    accountName: payload.accountName,
                                    accountType: payload.accountType,
                                    accessToken: msg.payload.accessToken,
                                    isPremiumAccount: payload.isPremiumAccount,
                                    isMasterAccount:
                                        msg.payload.isMasterAccount,
                                    stockCount: {
                                        total: payload.totalStock,
                                        selected: payload.filteredStock,
                                    },
                                    lastUpdate: payload.lastUpdate,
                                })),
                            ),
                    ),
                ],
            ];
        }

        case 'Incoming_Vehicle': {
            Result.match(msg.payload, {
                Ok: (vehicle) => {
                    state.cachedVehicles = CacheMap.set(
                        vehicle.id.value,
                        vehicle,
                        new Date(),
                        state.cachedVehicles,
                    );
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'Incoming_CompetingDealerProfiles': {
            Result.match(msg.payload, {
                Ok: (profiles) => {
                    state.settings.competingDealers = profiles;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [
                state,
                [
                    ...(state.route.id === 'ComparisonAdvicePrice' &&
                    state.page.ComparisonAdvicePrice.data === null
                        ? [Promise.resolve(Msg.OnRoute(state.route))]
                        : []),
                    ...(state.route.id === 'ComparisonDaysInStock' &&
                    state.page.ComparisonDaysInStock.data === null
                        ? [Promise.resolve(Msg.OnRoute(state.route))]
                        : []),
                    ...(state.route.id === 'ComparisonGeographic' &&
                    state.page.ComparisonGeographic.data === null
                        ? [Promise.resolve(Msg.OnRoute(state.route))]
                        : []),
                ],
            ];
        }

        case 'Incoming_DaysInStockGoal': {
            state.settings.daysInStockGoal = Async.fromResult(
                msg.payload,
                state.settings.daysInStockGoal,
            );
            return [state, []];
        }

        case 'Incoming_Dealers': {
            state.settings.dealers = Async.fromResult(
                msg.payload,
                state.settings.dealers,
            );

            return [state, []];
        }

        case 'Incoming_DealerFilter': {
            state.settings.dealerFilter = Async.fromResult(msg.payload);

            state.settings.dealerCount = Result.withDefault(
                Result.map(msg.payload, (dealerFilter) => ({
                    total: Object.keys(dealerFilter).length,
                    selected:
                        Object.values(dealerFilter).filter(Boolean).length,
                })),
                null,
            );

            switch (state.route.id) {
                case 'Dashboard': {
                    if (state.account) {
                        state.page.Dashboard.isRetrieving = true;

                        return [
                            state,
                            [
                                Api.retrieveDealerDashboard(
                                    state.account.accessToken,
                                    Msg.Dashboard_IncomingDealerDashboard,
                                ),
                            ],
                        ];
                    }
                }
            }

            return [state, []];
        }

        case 'Incoming_StockSelection': {
            return Result.match(msg.payload, {
                Err: () => [state, []],
                Ok: (payload) => {
                    state.settings.stockCount = payload;
                    return [state, []];
                },
            });
        }

        case 'Login_OnInputUsername': {
            state.page.Login.error = null;
            state.page.Login.username = msg.payload;
            return [state, []];
        }

        case 'Login_OnInputPassword': {
            state.page.Login.error = null;
            state.page.Login.password = msg.payload;
            return [state, []];
        }

        case 'Login_OnInputCaptcha': {
            state.page.Login.error = null;
            state.page.Login.captcha = msg.payload;
            return [state, []];
        }

        case 'Login_OnSubmit': {
            state.isAuthenticating = true;
            return [
                state,
                [
                    Api.authenticateWithCredentials(
                        state.page.Login.username,
                        state.page.Login.password,
                        state.page.Login.captcha,
                        Msg.Login_IncomingAuthentication,
                    ),
                ],
            ];
        }

        case 'Login_IncomingAuthentication': {
            state.isAuthenticating = false;

            return Result.match(msg.payload, {
                Ok: (payload) => {
                    return [
                        state,
                        [
                            Promise.resolve(
                                Msg.Incoming_Token({
                                    accessToken: payload.accessToken,
                                    expiration: payload.expiration,
                                    isMasterAccount: false,
                                }),
                            ),
                        ],
                    ];
                },
                Err: (error) => {
                    switch (error.type) {
                        case 'CaptchaRequired': {
                            if (!state.page.Login.isCaptchaRequired) {
                                state.page.Login.isCaptchaRequired = true;
                            } else {
                                state.page.Login.error = {
                                    type: 'CaptchaNotProvided',
                                };
                            }

                            return [state, []];
                        }

                        case 'LoginWithCredentialsFailed': {
                            state.page.Login.error = {
                                type: 'IncorrectCredentials',
                            };
                            return [state, []];
                        }

                        case 'UnexpectedError': {
                            // TODO: How to inform the user?
                            return [state, []];
                        }

                        default: {
                            return [state, []];
                        }
                    }
                },
            });
        }

        case 'Dashboard_IncomingDealerDashboard': {
            state.page.Dashboard.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (payload) => {
                    state.page.Dashboard.data = payload;
                },
                Err: () => {},
            });

            return [state, []];
        }

        case 'Stock_Incoming': {
            state.page.Stock.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (value) => {
                    state.page.Stock.vehicles = value.vehicles;
                    state.page.Stock.amountOfVehicles = value.totalVehicles;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'Dashboard_OnSortStockSummary': {
            state.page.Dashboard.sorting = msg.payload;
            return [state, []];
        }

        case 'Dashboard_OnToggleInfoBlock': {
            state.page.Dashboard.isInfoBlockVisible =
                !state.page.Dashboard.isInfoBlockVisible;
            return [state, []];
        }

        case 'Dashboard_OnPrint': {
            print();
            return [state, []];
        }

        case 'Dashboard_OnSelectAllDealers': {
            if (state.account === null) {
                return [state, []];
            }

            return Async.match(state.settings.dealerFilter, {
                Loaded: (dealerFilter) => {
                    return [
                        state,
                        [
                            Api.updateDealerFilter(
                                state.account!.accessToken,
                                Object.keys(dealerFilter).reduce(
                                    (
                                        acc: Record<string, boolean>,
                                        dealerId,
                                    ) => {
                                        acc[dealerId] = true;
                                        return acc;
                                    },
                                    {},
                                ),
                                Msg.Incoming_DealerFilter,
                            ),
                        ],
                    ];
                },
                Idle: () => [state, []],
                Loading: () => [state, []],
                Failed: () => [state, []],
            });
        }

        case 'Dashboard_OnSelectDealer': {
            if (state.account === null) {
                return [state, []];
            }

            return Async.match(state.settings.dealerFilter, {
                Loaded: (dealerFilter) => {
                    return [
                        state,
                        [
                            Api.updateDealerFilter(
                                state.account!.accessToken,
                                Object.keys(dealerFilter).reduce(
                                    (
                                        acc: Record<string, boolean>,
                                        dealerId,
                                    ) => {
                                        acc[dealerId] =
                                            dealerId === msg.payload;
                                        return acc;
                                    },
                                    {},
                                ),
                                Msg.Incoming_DealerFilter,
                            ),
                        ],
                    ];
                },
                Idle: () => [state, []],
                Loading: () => [state, []],
                Failed: () => [state, []],
            });
        }

        case 'Stock_OnDownloadExcel': {
            if (state.account) {
                Api.downloadDealerCSV(
                    state.account.accessToken,
                    'stock',
                    null,
                    (success) => success, // identity function
                );
            }

            return [state, []];
        }

        case 'Stock_OnPrint': {
            print();
            return [state, []];
        }

        case 'Stock_OnToggleInfoBlock': {
            state.page.Stock.isInfoBlockVisible =
                !state.page.Stock.isInfoBlockVisible;

            return [state, []];
        }

        case 'Stock_OnInputSearch': {
            state.page.Stock.search = msg.payload;
            return [state, []];
        }

        case 'Stock_OnSearch': {
            state.page.Stock.search = msg.payload;
            return [
                state,
                [
                    retrieveDealerStock(
                        state.account!.accessToken,
                        state.page.Stock,
                    ),
                ],
            ];
        }

        case 'Stock_OnSort': {
            state.page.Stock.sort = msg.payload;
            return [
                state,
                [
                    retrieveDealerStock(
                        state.account!.accessToken,
                        state.page.Stock,
                    ),
                ],
            ];
        }

        case 'Stock_OnSortDirection': {
            state.page.Stock.sortDirection = msg.payload;
            return [
                state,
                [
                    retrieveDealerStock(
                        state.account!.accessToken,
                        state.page.Stock,
                    ),
                ],
            ];
        }

        case 'Stock_OnOnlyWithWarnings': {
            state.page.Stock.onlyWithWarnings = msg.payload;
            return [
                state,
                [
                    retrieveDealerStock(
                        state.account!.accessToken,
                        state.page.Stock,
                    ),
                ],
            ];
        }

        case 'Stock_OnPageChange': {
            state.page.Stock.page = msg.payload;
            return [
                state,
                [
                    retrieveDealerStock(
                        state.account!.accessToken,
                        state.page.Stock,
                    ),
                ],
            ];
        }

        case 'Stock_OnAmountPerPageChange': {
            state.page.Stock.amountPerPage = msg.payload;

            PermanentStorage.save('stockAmountPerPage', msg.payload);

            return [
                state,
                [
                    retrieveDealerStock(
                        state.account!.accessToken,
                        state.page.Stock,
                    ),
                ],
            ];
        }

        case 'Reach_Incoming': {
            state.page.Stock.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (data) => {
                    state.page.Reach.data = data;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'Reach_OnToggleInfoBlock': {
            state.page.Reach.isInfoBlockVisible =
                !state.page.Reach.isInfoBlockVisible;
            return [state, []];
        }

        case 'Reach_OnPrint': {
            print();
            return [state, []];
        }

        case 'Reach_OnDateRangeChange': {
            state.page.Reach.period = {
                startDate: toISODateString(msg.payload[0]),
                endDate: toISODateString(msg.payload[1]),
            };

            return [
                state,
                [
                    Api.retrieveDealerReach(
                        state.account!.accessToken,
                        state.page.Reach.period,
                        Msg.Reach_Incoming,
                    ),
                ],
            ];
        }

        case 'Leads_Incoming': {
            state.page.Leads.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (data) => {
                    state.page.Leads.data = data;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'Leads_OnToggleInfoBlock': {
            state.page.Leads.isInfoBlockVisible =
                !state.page.Leads.isInfoBlockVisible;
            return [state, []];
        }

        case 'Leads_OnToggleFilterVisibility': {
            state.page.Leads.isFilterVisible =
                !state.page.Leads.isFilterVisible;
            return [state, []];
        }

        case 'Leads_OnDownloadExcel': {
            if (state.account) {
                Api.downloadDealerCSV(
                    state.account.accessToken,
                    'leads',
                    [
                        state.page.Leads.period.startDate,
                        state.page.Leads.period.endDate,
                    ],
                    (success) => success,
                );
            }

            return [state, []];
        }

        case 'Leads_OnPrint': {
            print();
            return [state, []];
        }

        case 'Leads_OnSortChange': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.Leads.isRetrieving = true;
            state.page.Leads.sort = msg.payload;
            return [
                state,
                [
                    retrieveDealerLeads(
                        state.account.accessToken,
                        state.page.Leads,
                    ),
                ],
            ];
        }

        case 'Leads_OnAmountPerPageChange': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.Leads.isRetrieving = true;
            state.page.Leads.amountPerPage = msg.payload;

            PermanentStorage.save('leadsAmountPerPage', msg.payload);

            return [
                state,
                [
                    retrieveDealerLeads(
                        state.account.accessToken,
                        state.page.Leads,
                    ),
                ],
            ];
        }

        case 'Leads_OnPageChange': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.Leads.isRetrieving = true;
            state.page.Leads.page = msg.payload;
            return [
                state,
                [
                    retrieveDealerLeads(
                        state.account.accessToken,
                        state.page.Leads,
                    ),
                ],
            ];
        }

        case 'Leads_OnFilterChange': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.Leads.isRetrieving = true;
            state.page.Leads.filter = msg.payload;
            return [
                state,
                [
                    retrieveDealerLeads(
                        state.account.accessToken,
                        state.page.Leads,
                    ),
                ],
            ];
        }

        case 'Leads_OnSearchInput': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.Leads.isRetrieving = true;
            state.page.Leads.search = msg.payload;
            return [
                state,
                [
                    retrieveDealerLeads(
                        state.account.accessToken,
                        state.page.Leads,
                    ),
                ],
            ];
        }

        case 'Leads_OnPeriodChange': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.Leads.isRetrieving = true;
            state.page.Leads.period = {
                startDate: toISODateString(msg.payload[0]),
                endDate: toISODateString(msg.payload[1]),
            };
            return [
                state,
                [
                    retrieveDealerLeads(
                        state.account.accessToken,
                        state.page.Leads,
                    ),
                ],
            ];
        }

        case 'Prospects_Incoming': {
            state.page.Prospects.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (data) => {
                    state.page.Prospects.data = data;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'Prospects_OnDownloadExcel': {
            if (state.account) {
                Api.downloadDealerCSV(
                    state.account.accessToken,
                    'prospects',
                    [
                        state.page.Prospects.period.startDate,
                        state.page.Prospects.period.endDate,
                    ],
                    (success) => success,
                );
            }

            return [state, []];
        }

        case 'Prospects_OnToggleInfoBlock': {
            state.page.Prospects.isInfoBlockVisible =
                !state.page.Prospects.isInfoBlockVisible;
            return [state, []];
        }

        case 'Prospects_OnPrint': {
            print();
            return [state, []];
        }

        case 'Prospects_OnPageChange': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.Prospects.isRetrieving = true;
            state.page.Prospects.page = msg.payload;

            return [
                state,
                [
                    retrieveDealerProspects(
                        state.account.accessToken,
                        state.page.Prospects,
                    ),
                ],
            ];
        }

        case 'Prospects_OnAmountPerPageChange': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.Prospects.isRetrieving = true;
            state.page.Prospects.amountPerPage = msg.payload;

            PermanentStorage.save('prospectsAmountPerPage', msg.payload);

            return [
                state,
                [
                    retrieveDealerProspects(
                        state.account.accessToken,
                        state.page.Prospects,
                    ),
                ],
            ];
        }

        case 'Prospects_OnPeriodChange': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.Prospects.isRetrieving = true;
            state.page.Prospects.period = {
                startDate: toISODateString(msg.payload[0]),
                endDate: toISODateString(msg.payload[1]),
            };

            return [
                state,
                [
                    retrieveDealerProspects(
                        state.account.accessToken,
                        state.page.Prospects,
                    ),
                ],
            ];
        }

        case 'AnalysisOverview_Incoming': {
            state.page.AnalysisOverview.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (data) => {
                    state.page.AnalysisOverview.data = data;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'AnalysisOverview_OnPrint': {
            print();
            return [state, []];
        }

        case 'AnalysisOverview_OnToggleInfoBlock': {
            state.page.AnalysisOverview.isInfoBlockVisible =
                !state.page.AnalysisOverview.isInfoBlockVisible;
            return [state, []];
        }

        case 'AnalysisOverview_OnProfileChange': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.AnalysisOverview.isRetrieving = true;
            state.page.AnalysisOverview.profile = msg.payload;

            return [
                state,
                [
                    retrieveAnalysisOverview(
                        state.account.accessToken,
                        state.page.AnalysisOverview,
                    ),
                ],
            ];
        }

        case 'AnalysisLeadPerformance_Incoming': {
            state.page.AnalysisLeadPerformance.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (data) => {
                    state.page.AnalysisLeadPerformance.data = data;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'AnalysisLeadPerformance_OnPrint': {
            print();
            return [state, []];
        }

        case 'AnalysisLeadPerformance_OnToggleInfoBlock': {
            state.page.AnalysisLeadPerformance.isInfoBlockVisible =
                !state.page.AnalysisLeadPerformance.isInfoBlockVisible;
            return [state, []];
        }

        case 'AnalysisLeadPerformance_OnPeriodChange': {
            if (state.account == null) {
                return [state, []];
            }

            state.page.AnalysisLeadPerformance.isRetrieving = true;
            state.page.AnalysisLeadPerformance.period = {
                startDate: toISODateString(msg.payload[0]),
                endDate: toISODateString(msg.payload[1]),
            };

            return [
                state,
                [
                    retrieveAnalysisLeadPerformance(
                        state.account.accessToken,
                        state.page.AnalysisLeadPerformance,
                    ),
                ],
            ];
        }

        case 'AnalysisMakeModel_Incoming': {
            state.page.AnalysisMakeModel.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (data) => {
                    state.page.AnalysisMakeModel.data = data;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'AnalysisMakeModel_OnToggleInfoBlock': {
            state.page.AnalysisMakeModel.isInfoBlockVisible =
                !state.page.AnalysisMakeModel.isInfoBlockVisible;
            return [state, []];
        }

        case 'AnalysisMakeModel_OnPrint': {
            print();
            return [state, []];
        }

        case 'AnalysisMakeModel_OnChangeTab': {
            state.page.AnalysisMakeModel.tab = msg.payload;
            return [state, []];
        }

        case 'AnalysisCostBenefit_Incoming': {
            state.page.AnalysisCostBenefit.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (data) => {
                    state.page.AnalysisCostBenefit.data = data;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'AnalysisCostBenefit_OnPrint': {
            print();
            return [state, []];
        }

        case 'ComparisonAdvicePrice_Incoming': {
            state.page.ComparisonAdvicePrice.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (data) => {
                    if (
                        state.page.ComparisonAdvicePrice.activeProfile ===
                            null &&
                        data.profiles.length > 0
                    ) {
                        state.page.ComparisonAdvicePrice.activeProfile =
                            data.profiles[0].id;
                    }

                    state.page.ComparisonAdvicePrice.data = data;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'ComparisonAdvicePrice_OnPrint': {
            print();
            return [state, []];
        }

        case 'ComparisonAdvicePrice_OnToggleInfoBlock': {
            state.page.ComparisonAdvicePrice.isInfoBlockVisible =
                !state.page.ComparisonAdvicePrice.isInfoBlockVisible;
            return [state, []];
        }

        case 'ComparisonAdvicePrice_OnProfileChange': {
            state = onComparisonProfileChange(msg.payload, state);

            return [
                state,
                [
                    retrieveComparisonAdvicePrice(
                        state.account!.accessToken,
                        state.settings.competingDealers![0],
                        state.page.ComparisonAdvicePrice,
                    ),
                ],
            ];
        }

        case 'ComparisonAdvicePrice_OnMakeFilterChange': {
            state = onComparisonMakeFilterChange(
                msg.payload,
                state.page.ComparisonAdvicePrice.data?.models ?? [],
                state.page.ComparisonAdvicePrice.modelFilter,
                state,
            );

            return [
                state,
                [
                    retrieveComparisonAdvicePrice(
                        state.account!.accessToken,
                        state.settings.competingDealers![0],
                        state.page.ComparisonAdvicePrice,
                    ),
                ],
            ];
        }

        case 'ComparisonAdvicePrice_OnModelFilterChange': {
            state = onComparisonModelFilterChange(msg.payload, state);

            return [
                state,
                [
                    retrieveComparisonAdvicePrice(
                        state.account!.accessToken,
                        state.settings.competingDealers![0],
                        state.page.ComparisonAdvicePrice,
                    ),
                ],
            ];
        }

        case 'ComparisonDaysInStock_Incoming': {
            state.page.ComparisonDaysInStock.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (data) => {
                    if (
                        state.page.ComparisonDaysInStock.activeProfile ===
                            null &&
                        data.profiles.length > 0
                    ) {
                        state.page.ComparisonDaysInStock.activeProfile =
                            data.profiles[0].id;
                    }

                    state.page.ComparisonDaysInStock.data = data;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'ComparisonDaysInStock_OnPrint': {
            print();
            return [state, []];
        }

        case 'ComparisonDaysInStock_OnToggleInfoBlock': {
            state.page.ComparisonDaysInStock.isInfoBlockVisible =
                !state.page.ComparisonDaysInStock.isInfoBlockVisible;
            return [state, []];
        }

        case 'ComparisonDaysInStock_OnProfileChange': {
            state = onComparisonProfileChange(msg.payload, state);

            return [
                state,
                [
                    retrieveComparisonDaysInStock(
                        state.account!.accessToken,
                        state.settings.competingDealers![0],
                        state.page.ComparisonDaysInStock,
                    ),
                ],
            ];
        }

        case 'ComparisonDaysInStock_OnMakeFilterChange': {
            state = onComparisonMakeFilterChange(
                msg.payload,
                state.page.ComparisonDaysInStock.data?.models ?? [],
                state.page.ComparisonDaysInStock.modelFilter,
                state,
            );

            return [
                state,
                [
                    retrieveComparisonDaysInStock(
                        state.account!.accessToken,
                        state.settings.competingDealers![0],
                        state.page.ComparisonDaysInStock,
                    ),
                ],
            ];
        }

        case 'ComparisonDaysInStock_OnModelFilterChange': {
            state = onComparisonModelFilterChange(msg.payload, state);

            return [
                state,
                [
                    retrieveComparisonDaysInStock(
                        state.account!.accessToken,
                        state.settings.competingDealers![0],
                        state.page.ComparisonDaysInStock,
                    ),
                ],
            ];
        }

        case 'ComparisonGeographic_Incoming': {
            state.page.ComparisonGeographic.isRetrieving = false;

            Result.match(msg.payload, {
                Ok: (data) => {
                    if (
                        state.page.ComparisonGeographic.activeProfile ===
                            null &&
                        data.profiles.length > 0
                    ) {
                        state.page.ComparisonGeographic.activeProfile =
                            data.profiles[0].id;
                    }

                    state.page.ComparisonGeographic.data = data;
                },
                Err: (error) => {
                    console.error(error);
                },
            });

            return [state, []];
        }

        case 'ComparisonGeographic_OnToggleInfoBlock': {
            state.page.ComparisonGeographic.isInfoBlockVisible =
                !state.page.ComparisonGeographic.isInfoBlockVisible;
            return [state, []];
        }

        case 'ComparisonGeographic_OnPrint': {
            print();
            return [state, []];
        }

        case 'ComparisonGeographic_OnTabChange': {
            state.page.ComparisonGeographic.activeTab = msg.payload;
            return [state, []];
        }

        case 'ComparisonGeographic_OnProfileChange': {
            state = onComparisonProfileChange(msg.payload, state);

            return [
                state,
                [
                    retrieveComparisonGeographic(
                        state.account!.accessToken,
                        state.settings.competingDealers![0],
                        state.page.ComparisonGeographic,
                    ),
                ],
            ];
        }

        case 'SettingsScenarios_Incoming': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsScenarios.data = Async.fromResult(msg.payload);

            return [state, []];
        }

        case 'SettingsScenarios_IncomingDelete': {
            if (state.account === null) {
                return [state, []];
            }

            if (!msg.payload.wasSuccessful) {
                return [state, []];
            }

            state.page.SettingsScenarios.data = Async.map(
                state.page.SettingsScenarios.data,
                (scenarios) =>
                    scenarios.filter(
                        (scenario) => scenario.id !== msg.payload.scenario.id,
                    ),
            );

            return [state, []];
        }

        case 'SettingsScenarios_OnDelete': {
            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.removeScenario(
                        state.account.accessToken,
                        msg.payload,
                        (wasSuccessful) =>
                            Msg.SettingsScenarios_IncomingDelete({
                                scenario: msg.payload,
                                wasSuccessful,
                            }),
                    ),
                ],
            ];
        }

        case 'SettingsScenario_Incoming': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsScenario.data = Async.fromResult(msg.payload);

            return [state, []];
        }

        case 'SettingsScenario_IncomingSave': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsScenario.isSaving = false;
            state.page.SettingsScenario.data = Async.fromResult(msg.payload);

            return Result.match(msg.payload, {
                Ok: () => {
                    state.page.SettingsScenario.changes = {};
                    return [
                        state,
                        [
                            Promise.resolve(
                                Msg.Navigate('/instellingen/scenarios'),
                            ),
                        ],
                    ];
                },
                Err: () => [state, []],
            });
        }

        case 'SettingsScenario_OnUpdate': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsScenario.changes = msg.payload;

            return [state, []];
        }

        case 'SettingsScenario_OnSave': {
            if (state.account === null) {
                return [state, []];
            }

            if (!Async.isLoaded(state.page.SettingsScenario.data)) {
                return [state, []];
            }

            const originalScenario = Maybe.fromNullable(
                Async.value(state.page.SettingsScenario.data),
            );

            state.page.SettingsScenario.isSaving = true;

            return Maybe.match(originalScenario, {
                Nothing: () => {
                    const newScenario = {
                        ...ScenarioUtil.create(),
                        ...state.page.SettingsScenario.changes,
                    };

                    return [
                        state,
                        [
                            Api.addSettingsScenario(
                                state.account!.accessToken,
                                newScenario,
                                Msg.SettingsScenario_IncomingSave,
                            ),
                        ],
                    ];
                },
                Just: (scenario) => {
                    const newScenario = {
                        ...scenario,
                        ...state.page.SettingsScenario.changes,
                    };

                    return [
                        state,
                        [
                            Api.updateSettingsScenario(
                                state.account!.accessToken,
                                newScenario,
                                Msg.SettingsScenario_IncomingSave,
                            ),
                        ],
                    ];
                },
            });
        }

        case 'SettingsScenario_OnCancel': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsScenario.changes = {};

            return [
                state,
                [Promise.resolve(Msg.Navigate('/instellingen/scenarios'))],
            ];
        }

        case 'SettingsScenario_OnSelectedAvailableDealerChange': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsScenario.selectedAvailableDealerPerCluster =
                state.page.SettingsScenario.selectedAvailableDealerPerCluster.set(
                    msg.payload.index,
                    msg.payload.dealerId,
                );

            return [state, []];
        }

        case 'SettingsBranches_IncomingScenarioList': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsBranches.scenarioList = Async.fromResult(
                msg.payload,
                state.page.SettingsBranches.scenarioList,
            );

            return [state, []];
        }

        case 'SettingsBranches_IncomingScenario': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsBranches.scenario = Async.fromResult(
                msg.payload,
                state.page.SettingsBranches.scenario,
            );

            return [state, []];
        }

        case 'SettingsBranches_IncomingDealerFilter': {
            if (state.account === null) {
                return [state, []];
            }

            return Result.match(msg.payload, {
                Err: () => {
                    state.page.SettingsBranches.dealerFilter =
                        Async.markAsFailed(
                            state.page.SettingsBranches.dealerFilter,
                        );

                    return [state, []];
                },
                Ok: ({ scenario: scenarioId, dealers }) => {
                    state.page.SettingsBranches.dealerFilter =
                        Async.markAsLoaded(
                            state.page.SettingsBranches.dealerFilter,
                            dealers,
                        );

                    return [
                        state,
                        [
                            Api.retrieveSettingsScenario(
                                state.account!.accessToken,
                                localStorage.getItem('selectedScenario') ??
                                    scenarioId,
                                Msg.SettingsBranches_IncomingScenario,
                            ),
                        ],
                    ];
                },
            });
        }

        case 'SettingsBranches_IncomingSave': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsBranches.isSaving = false;

            state.page.SettingsBranches.dealerFilter = Async.fromResult(
                msg.payload,
                state.page.SettingsBranches.dealerFilter,
            );

            if (Result.isOk(msg.payload)) {
                state.page.SettingsBranches.changes = {};
            }

            Result.match(msg.payload, {
                Err: () => {},
                Ok: (dealerFilter) => {
                    state.settings.dealerCount!.selected = Object.entries(
                        dealerFilter,
                    ).filter(([, x]) => !!x).length;
                },
            });

            return [
                state,
                [
                    Api.retrieveSession(state.account.accessToken, (result) =>
                        Msg.Incoming_StockSelection(
                            Result.map(result, (payload) => ({
                                total: payload.totalStock,
                                selected: payload.filteredStock,
                            })),
                        ),
                    ),
                ],
            ];
        }

        case 'SettingsBranches_OnChangeScenario': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsBranches.scenario = Async.markAsLoading(
                state.page.SettingsBranches.scenario,
            );

            if (msg.payload) {
                localStorage.setItem('selectedScenario', msg.payload);
            }

            return [
                state,
                [
                    Api.retrieveSettingsScenario(
                        state.account.accessToken,
                        msg.payload,
                        Msg.SettingsBranches_IncomingScenario,
                    ),
                ],
            ];
        }

        case 'SettingsBranches_OnUpdate': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsBranches.changes = msg.payload;

            return [state, []];
        }

        case 'SettingsBranches_OnSave': {
            if (state.account === null) {
                return [state, []];
            }

            if (!Async.isLoaded(state.page.SettingsBranches.dealerFilter)) {
                return [state, []];
            }

            state.page.SettingsBranches.isSaving = true;
            state.page.SettingsBranches.dealerFilter = Async.markAsLoading(
                state.page.SettingsBranches.dealerFilter,
            );

            return [
                state,
                [
                    Api.updateDealerFilter(
                        state.account.accessToken,
                        state.page.SettingsBranches.changes,
                        Msg.SettingsBranches_IncomingSave,
                    ),
                ],
            ];
        }

        case 'SettingsCompetingDealers_Incoming': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompanies.profiles = Async.fromResult(
                msg.payload,
                state.page.SettingsCompetingCompanies.profiles,
            );

            return [state, []];
        }

        case 'SettingsCompetingDealers_IncomingRemove': {
            if (state.account === null) {
                return [state, []];
            }

            if (!msg.payload.wasSuccessful) {
                return [state, []];
            }

            state.page.SettingsCompetingCompanies.profiles = Async.map(
                state.page.SettingsCompetingCompanies.profiles,
                (profiles) =>
                    profiles.filter(
                        (profile) => profile.id !== msg.payload.profile.id,
                    ),
            );

            return [state, []];
        }

        case 'SettingsCompetingDealers_OnRemove': {
            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.removeDealerProfile(
                        state.account.accessToken,
                        msg.payload,
                        (wasSuccessful) =>
                            Msg.SettingsCompetingDealers_IncomingRemove({
                                profile: msg.payload,
                                wasSuccessful,
                            }),
                    ),
                ],
            ];
        }

        case 'SettingsCompetingDealersProfile_Incoming': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.profile =
                Async.fromResult(
                    msg.payload,
                    state.page.SettingsCompetingCompaniesProfile.profile,
                );

            return [state, []];
        }

        case 'SettingsCompetingDealersProfile_IncomingFilters': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.filtersMetadata =
                Async.fromResult(
                    msg.payload,
                    state.page.SettingsCompetingCompaniesProfile
                        .filtersMetadata,
                );

            return [state, []];
        }

        case 'SettingsCompetingDealersProfile_IncomingDealers': {
            if (state.account === null) {
                return [state, []];
            }

            if (
                !isEqual(
                    msg.payload.filters,
                    state.page.SettingsCompetingCompaniesProfile.filters,
                )
            ) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.dealers =
                Async.fromResult(
                    msg.payload.result,
                    state.page.SettingsCompetingCompaniesProfile.dealers,
                );

            return [state, []];
        }

        case 'SettingsCompetingDealersProfile_IncomingSave': {
            state.page.SettingsCompetingCompaniesProfile.isSaving = false;

            if (state.account === null) {
                return [state, []];
            }

            if (Result.isErr(msg.payload)) {
                return [state, []];
            }

            return [
                state,
                [
                    Promise.resolve(
                        Msg.Navigate('/instellingen/concurrentiebedrijven'),
                    ),
                ],
            ];
        }

        case 'SettingsCompetingDealersProfile_OnViewChange': {
            state.page.SettingsCompetingCompaniesProfile.activeView =
                msg.payload;

            return [state, []];
        }

        case 'SettingsCompetingDealersProfile_OnChangeName': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.changes.name =
                msg.payload;

            return [state, []];
        }

        case 'SettingsCompetingDealersProfile_OnChangeSearch': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.filters.search =
                msg.payload;

            state.page.SettingsCompetingCompaniesProfile.dealers =
                Async.markAsLoading(
                    state.page.SettingsCompetingCompaniesProfile.dealers,
                );

            return [
                state,
                [retrieveCompetitionDealers(state.account.accessToken, state)],
            ];
        }

        case 'SettingsCompetingDealersProfile_OnChangeDealerFilter': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.filters.dealerTypes =
                msg.payload;

            state.page.SettingsCompetingCompaniesProfile.dealers =
                Async.markAsLoading(
                    state.page.SettingsCompetingCompaniesProfile.dealers,
                );

            return [
                state,
                [retrieveCompetitionDealers(state.account.accessToken, state)],
            ];
        }

        case 'SettingsCompetingDealersProfile_OnChangeProvinceFilter': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.filters.provinces =
                msg.payload;

            state.page.SettingsCompetingCompaniesProfile.dealers =
                Async.markAsLoading(
                    state.page.SettingsCompetingCompaniesProfile.dealers,
                );

            return [
                state,
                [retrieveCompetitionDealers(state.account.accessToken, state)],
            ];
        }

        case 'SettingsCompetingDealersProfile_OnChangeDealerMakeFilter': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.filters.dealerMakes =
                msg.payload;

            state.page.SettingsCompetingCompaniesProfile.dealers =
                Async.markAsLoading(
                    state.page.SettingsCompetingCompaniesProfile.dealers,
                );

            return [
                state,
                [retrieveCompetitionDealers(state.account.accessToken, state)],
            ];
        }

        case 'SettingsCompetingDealersProfile_OnChangeStockMakeFilter': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.filters.stockMakes =
                msg.payload;

            state.page.SettingsCompetingCompaniesProfile.dealers =
                Async.markAsLoading(
                    state.page.SettingsCompetingCompaniesProfile.dealers,
                );

            return [
                state,
                [retrieveCompetitionDealers(state.account.accessToken, state)],
            ];
        }

        case 'SettingsCompetingDealersProfile_OnAddCompetition': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.changes.dealers =
                Changes.add(
                    state.page.SettingsCompetingCompaniesProfile.changes
                        .dealers,
                    (dealer) => dealer.id,
                    msg.payload.value,
                );

            return [state, []];
        }

        case 'SettingsCompetingDealersProfile_OnRemoveCompetition': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.changes.dealers =
                Changes.remove(
                    state.page.SettingsCompetingCompaniesProfile.changes
                        .dealers,
                    (dealer) => dealer.id,
                    msg.payload.value,
                );

            return [state, []];
        }

        case 'SettingsCompetingDealersProfile_OnAddCompetitionAll': {
            if (state.account === null) {
                return [state, []];
            }

            if (
                !Async.isLoaded(
                    state.page.SettingsCompetingCompaniesProfile.dealers,
                )
            ) {
                return [state, []];
            }

            for (const dealer of state.page.SettingsCompetingCompaniesProfile
                .dealers.value) {
                state.page.SettingsCompetingCompaniesProfile.changes.dealers =
                    Changes.add(
                        state.page.SettingsCompetingCompaniesProfile.changes
                            .dealers,
                        (dealer) => dealer.id,
                        dealer,
                    );
            }

            return [state, []];
        }

        case 'SettingsCompetingDealersProfile_OnRemoveCompetitionAll': {
            if (state.account === null) {
                return [state, []];
            }

            if (
                !Async.isLoaded(
                    state.page.SettingsCompetingCompaniesProfile.dealers,
                )
            ) {
                return [state, []];
            }

            for (const dealer of state.page.SettingsCompetingCompaniesProfile
                .dealers.value) {
                state.page.SettingsCompetingCompaniesProfile.changes.dealers =
                    Changes.remove(
                        state.page.SettingsCompetingCompaniesProfile.changes
                            .dealers,
                        (dealer) => dealer.id,
                        dealer,
                    );
            }

            return [state, []];
        }

        case 'SettingsCompetingDealersProfile_OnSave': {
            if (state.account === null) {
                return [state, []];
            }

            if (
                !Async.isLoaded(
                    state.page.SettingsCompetingCompaniesProfile.profile,
                )
            ) {
                return [state, []];
            }

            const originalProfile = Maybe.fromNullable(
                Async.value(
                    state.page.SettingsCompetingCompaniesProfile.profile,
                ),
            );

            return Maybe.match(originalProfile, {
                Nothing: () => {
                    const newProfile = CompetitionProfileUtil.create();

                    if (
                        state.page.SettingsCompetingCompaniesProfile.changes
                            .name
                    ) {
                        newProfile.name =
                            state.page.SettingsCompetingCompaniesProfile.changes.name;
                    }

                    if (
                        hasChanges(
                            state.page.SettingsCompetingCompaniesProfile.changes
                                .dealers,
                        )
                    ) {
                        newProfile.dealers = Changes.applyTo(
                            newProfile.dealers,
                            (dealer) => dealer.id,
                            state.page.SettingsCompetingCompaniesProfile.changes
                                .dealers,
                        );
                    }

                    state.page.SettingsCompetingCompaniesProfile.isSaving =
                        true;

                    return [
                        state,
                        [
                            Api.addSettingsCompetingDealersProfile(
                                state.account!.accessToken,
                                newProfile,
                                Msg.SettingsCompetingDealersProfile_IncomingSave,
                            ),
                        ],
                    ];
                },
                Just: (profile) => {
                    const newProfile = structuredClone(profile);

                    if (
                        state.page.SettingsCompetingCompaniesProfile.changes
                            .name
                    ) {
                        newProfile.name =
                            state.page.SettingsCompetingCompaniesProfile.changes.name;
                    }

                    if (
                        hasChanges(
                            state.page.SettingsCompetingCompaniesProfile.changes
                                .dealers,
                        )
                    ) {
                        newProfile.dealers = Changes.applyTo(
                            newProfile.dealers,
                            (dealer) => dealer.id,
                            state.page.SettingsCompetingCompaniesProfile.changes
                                .dealers,
                        );
                    }

                    state.page.SettingsCompetingCompaniesProfile.isSaving =
                        true;

                    return [
                        state,
                        [
                            Api.updateSettingsCompetingDealersProfile(
                                state.account!.accessToken,
                                newProfile,
                                Msg.SettingsCompetingDealersProfile_IncomingSave,
                            ),
                        ],
                    ];
                },
            });
        }

        case 'SettingsCompetingDealersProfile_OnCancel': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingCompaniesProfile.changes = {
                name: null,
                dealers: Changes.init(),
            };

            return [
                state,
                [
                    Promise.resolve(
                        Msg.Navigate('/instellingen/concurrentiebedrijven'),
                    ),
                ],
            ];
        }

        case 'SettingsBranches_OnCancel': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsBranches.changes = {};

            return [state, []];
        }

        case 'TaxationDashboard_Incoming': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.TaxationDashboard.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, []];
        }

        case 'TaxationDashboard_OnToggleInfoBlock': {
            state.page.TaxationDashboard.isInfoBlockVisible =
                !state.page.TaxationDashboard.isInfoBlockVisible;
            return [state, []];
        }

        case 'TaxationDashboard_OnPrint': {
            print();
            return [state, []];
        }

        case 'TaxationComparisonOverview_OnAmountPerPageChange': {
            state.page.TaxationComparisonOverview.amountPerPage = msg.payload;

            return [state, []];
        }

        case 'TaxationComparisonOverview_OnPageChange': {
            state.page.TaxationComparisonOverview.page = msg.payload;

            return [state, []];
        }

        case 'TaxationComparisonOverview_OnSortChange': {
            state.page.TaxationComparisonOverview.sort = msg.payload.value;

            return [state, []];
        }

        case 'TaxationComparisonOverview_OnSortDirectionChange': {
            state.page.TaxationComparisonOverview.sortDirection =
                msg.payload.value;

            return [state, []];
        }

        case 'TaxationComparisonOverview_OnStatusChange': {
            onTaxationComparisonStatusChange(msg.payload.value, state);

            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.retrieveTaxationCompetition(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.TaxationComparisonOverview.vehicleStatus ===
                            'Sold',
                        (result) =>
                            Msg.TaxationComparison_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'TaxationComparisonOverview_OnToggleInfoBlock': {
            state.page.TaxationComparisonOverview.isInfoBlockVisible =
                !state.page.TaxationComparisonOverview.isInfoBlockVisible;

            return [state, []];
        }

        case 'TaxationComparisonOverview_OnPrint': {
            print();

            return [state, []];
        }

        case 'TaxationComparisonPriceAnalysis_OnPrint': {
            print();

            return [state, []];
        }

        case 'TaxationComparisonPriceAnalysis_OnStatusChange': {
            onTaxationComparisonStatusChange(msg.payload.value, state);

            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.retrieveTaxationCompetition(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.TaxationComparisonPriceAnalysis
                            .vehicleStatus === 'Sold',
                        (result) =>
                            Msg.TaxationComparison_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'TaxationComparisonPriceAnalysis_OnToggleInfoBlock': {
            state.page.TaxationComparisonPriceAnalysis.isInfoBlockVisible =
                !state.page.TaxationComparisonPriceAnalysis.isInfoBlockVisible;

            return [state, []];
        }

        case 'TaxationComparisonPriceHistory_Incoming': {
            state.page.TaxationComparisonPriceHistory.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, []];
        }

        case 'TaxationComparisonPriceHistory_OnStatusChange': {
            onTaxationComparisonStatusChange(msg.payload.value, state);

            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.retrieveTaxationCompetition(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.TaxationComparisonPriceHistory
                            .vehicleStatus === 'Sold',
                        (result) =>
                            Msg.TaxationComparison_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                    Api.retrieveTaxationPriceHistory(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.TaxationComparisonPriceHistory
                            .vehicleStatus === 'Sold',
                        (result) =>
                            Msg.TaxationComparisonPriceHistory_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'TaxationComparisonPriceHistory_OnToggleInfoBlock': {
            state.page.TaxationComparisonPriceHistory.isInfoBlockVisible =
                !state.page.TaxationComparisonPriceHistory.isInfoBlockVisible;

            return [state, []];
        }

        case 'TaxationComparisonPriceHistory_OnPrint': {
            print();

            return [state, []];
        }

        case 'TaxationComparisonGeographic_Incoming': {
            state.page.TaxationComparisonGeographic.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, []];
        }

        case 'TaxationComparisonGeographic_OnStatusChange': {
            onTaxationComparisonStatusChange(msg.payload.value, state);

            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.retrieveTaxationCompetition(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.TaxationComparisonGeographic
                            .vehicleStatus === 'Sold',
                        (result) =>
                            Msg.TaxationComparison_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                    Api.retrieveTaxationGeography(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.TaxationComparisonGeographic
                            .vehicleStatus === 'Sold',
                        (result) =>
                            Msg.TaxationComparisonGeographic_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'TaxationComparisonGeographic_OnToggleInfoBlock': {
            state.page.TaxationComparisonGeographic.isInfoBlockVisible =
                !state.page.TaxationComparisonGeographic.isInfoBlockVisible;

            return [state, []];
        }

        case 'TaxationComparisonGeographic_OnPrint': {
            print();

            return [state, []];
        }

        case 'TaxationSettings_Incoming': {
            state.page.TaxationSettings.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, []];
        }

        case 'TaxationSettings_OnToggleInfoBlock': {
            state.page.TaxationSettings.isInfoBlockVisible =
                !state.page.TaxationSettings.isInfoBlockVisible;

            return [state, []];
        }

        case 'TaxationSettings_IncomingSave': {
            state.page.TaxationSettings.isSaving = false;

            Result.match(msg.payload.result, {
                Ok: (value) => {
                    state.page.TaxationSettings.data = Async.markAsLoaded(
                        state.page.TaxationSettings.data,
                        value,
                    );
                    state.page.TaxationSettings.changes = {};
                },
                Err: () => {},
            });

            return [state, []];
        }

        case 'TaxationSettings_IncomingReset': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.TaxationSettings.isResetting = false;

            if (!msg.payload.wasSuccessful) {
                return [state, []];
            }

            state.page.TaxationSettings.data = Async.markAsLoading(
                state.page.TaxationSettings.data,
            );
            state.page.TaxationSettings.changes = {};

            return [
                state,
                [
                    Api.retrieveTaxationSettings(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        (result) =>
                            Msg.TaxationSettings_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'TaxationSettings_OnChange': {
            state.page.TaxationSettings.changes = msg.payload.value;

            return [state, []];
        }

        case 'TaxationSettings_OnSave': {
            if (state.account === null) {
                return [state, []];
            }

            if (Object.keys(state.page.TaxationSettings.changes).length === 0) {
                return [state, []];
            }

            const originalValue = Async.value(state.page.TaxationSettings.data);

            if (originalValue === undefined) {
                return [state, []];
            }

            const value: TaxationSettings = {
                ...originalValue,
                ...state.page.TaxationSettings.changes,
            };

            state.page.TaxationSettings.isSaving = true;

            return [
                state,
                [
                    Api.updateTaxationSettings(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        value.competingvehicles,
                        (result) =>
                            Msg.TaxationSettings_IncomingSave({
                                vehicleId: msg.payload.vehicleId,
                                result: Result.map(result, () => value),
                            }),
                    ),
                ],
            ];
        }

        case 'TaxationSettings_OnCancel': {
            state.page.TaxationSettings.changes = {};

            return [state, []];
        }

        case 'TaxationSettings_OnReset': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.TaxationSettings.isResetting = true;

            return [
                state,
                [
                    Api.resetTaxationSettings(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        (wasSuccessful) =>
                            Msg.TaxationSettings_IncomingReset({
                                vehicleId: msg.payload.vehicleId,
                                wasSuccessful,
                            }),
                    ),
                ],
            ];
        }

        case 'TaxationComparison_Incoming': {
            const value = Async.fromResult(msg.payload.result);

            state.page.TaxationComparisonOverview.vehicleCompetition = value;
            state.page.TaxationComparisonGeographic.vehicleCompetition = value;
            state.page.TaxationComparisonPriceAnalysis.vehicleCompetition =
                value;
            state.page.TaxationComparisonPriceHistory.vehicleCompetition =
                value;

            return [state, []];
        }

        case 'VehicleDashboard_Incoming': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.VehicleDashboard.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, []];
        }

        case 'VehicleDashboard_OnToggleInfoBlock': {
            state.page.VehicleDashboard.isInfoBlockVisible =
                !state.page.VehicleDashboard.isInfoBlockVisible;
            return [state, []];
        }

        case 'VehicleDashboard_OnPrint': {
            print();
            return [state, []];
        }

        case 'VehicleReachOverview_Incoming': {
            state.page.VehicleReachOverview.data = Async.fromResult(
                msg.payload.result,
            );
            return [state, []];
        }

        case 'VehicleReachOverview_OnToggleInfoBlock': {
            state.page.VehicleReachOverview.isInfoBlockVisible =
                !state.page.VehicleReachOverview.isInfoBlockVisible;
            return [state, []];
        }

        case 'VehicleReachOverview_OnPrint': {
            print();
            return [state, []];
        }

        case 'VehicleReachPriceEffect_Incoming': {
            state.page.VehicleReachPriceEffect.data[msg.payload.type] =
                Async.fromResult(msg.payload.result);
            return [state, []];
        }

        case 'VehicleReachPriceEffect_OnToggleInfoBlock': {
            state.page.VehicleReachPriceEffect.isInfoBlockVisible =
                !state.page.VehicleReachPriceEffect.isInfoBlockVisible;
            return [state, []];
        }

        case 'VehicleReachPriceEffect_OnPrint': {
            print();
            return [state, []];
        }

        case 'VehicleReachPriceEffect_OnTabClick': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.VehicleReachPriceEffect.activeTab = msg.payload.value;
            state.page.VehicleReachPriceEffect.data[msg.payload.value] =
                Async.markAsLoading(
                    state.page.VehicleReachPriceEffect.data[msg.payload.value],
                );

            return [
                state,
                [
                    Api.retrieveVehiclePriceEffect(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        msg.payload.value,
                        (result) =>
                            Msg.VehicleReachPriceEffect_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                type: msg.payload.value,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'VehicleComparison_Incoming': {
            const value = Async.fromResult(msg.payload.result);

            state.page.VehicleComparisonOverview.vehicleCompetition = value;
            state.page.VehicleComparisonPriceAnalysis.vehicleCompetition =
                value;
            state.page.VehicleComparisonPriceHistory.vehicleCompetition = value;
            state.page.VehicleComparisonGeographic.vehicleCompetition = value;

            return [state, []];
        }

        case 'VehicleComparisonOverview_OnToggleInfoBlock': {
            state.page.VehicleComparisonOverview.isInfoBlockVisible =
                !state.page.VehicleComparisonOverview.isInfoBlockVisible;
            return [state, []];
        }

        case 'VehicleComparisonOverview_OnPrint': {
            print();
            return [state, []];
        }

        case 'VehicleComparisonOverview_OnStatusChange': {
            onVehicleComparisonStatusChange(msg.payload.value, state);

            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.retrieveVehicleCompetition(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.VehicleComparisonOverview.vehicleStatus ===
                            'Sold',
                        (result) =>
                            Msg.VehicleComparison_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'VehicleComparisonOverview_OnSortChange': {
            state.page.VehicleComparisonOverview.sort = msg.payload.value;

            return [state, []];
        }

        case 'VehicleComparisonOverview_OnSortDirectionChange': {
            state.page.VehicleComparisonOverview.sortDirection =
                msg.payload.value;

            return [state, []];
        }

        case 'VehicleComparisonOverview_OnPageChange': {
            state.page.VehicleComparisonOverview.page = msg.payload;
            return [state, []];
        }

        case 'VehicleComparisonOverview_OnAmountPerPageChange': {
            state.page.VehicleComparisonOverview.amountPerPage = msg.payload;
            return [state, []];
        }

        case 'VehicleComparisonPriceAnalysis_OnToggleInfoBlock': {
            state.page.VehicleComparisonPriceAnalysis.isInfoBlockVisible =
                !state.page.VehicleComparisonPriceAnalysis.isInfoBlockVisible;
            return [state, []];
        }

        case 'VehicleComparisonPriceAnalysis_OnPrint': {
            print();
            return [state, []];
        }

        case 'VehicleComparisonPriceAnalysis_OnStatusChange': {
            onVehicleComparisonStatusChange(msg.payload.value, state);

            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.retrieveVehicleCompetition(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.VehicleComparisonPriceAnalysis
                            .vehicleStatus === 'Sold',
                        (result) =>
                            Msg.VehicleComparison_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'VehicleComparisonPriceHistory_Incoming': {
            state.page.VehicleComparisonPriceHistory.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, []];
        }

        case 'VehicleComparisonPriceHistory_OnToggleInfoBlock': {
            state.page.VehicleComparisonPriceHistory.isInfoBlockVisible =
                !state.page.VehicleComparisonPriceHistory.isInfoBlockVisible;
            return [state, []];
        }

        case 'VehicleComparisonPriceHistory_OnPrint': {
            print();
            return [state, []];
        }

        case 'VehicleComparisonPriceHistory_OnStatusChange': {
            onVehicleComparisonStatusChange(msg.payload.value, state);

            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.retrieveVehicleCompetition(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.VehicleComparisonPriceHistory
                            .vehicleStatus === 'Sold',
                        (result) =>
                            Msg.VehicleComparison_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                    Api.retrieveVehiclePriceHistory(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.VehicleComparisonPriceHistory
                            .vehicleStatus === 'Sold',
                        (result) =>
                            Msg.VehicleComparisonPriceHistory_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'VehicleComparisonGeographic_Incoming': {
            state.page.VehicleComparisonGeographic.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, []];
        }

        case 'VehicleComparisonGeographic_OnToggleInfoBlock': {
            state.page.VehicleComparisonGeographic.isInfoBlockVisible =
                !state.page.VehicleComparisonGeographic.isInfoBlockVisible;
            return [state, []];
        }

        case 'VehicleComparisonGeographic_OnPrint': {
            print();
            return [state, []];
        }

        case 'VehicleComparisonGeographic_OnStatusChange': {
            onVehicleComparisonStatusChange(msg.payload.value, state);

            if (state.account === null) {
                return [state, []];
            }

            return [
                state,
                [
                    Api.retrieveVehicleCompetition(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.VehicleComparisonGeographic.vehicleStatus ===
                            'Sold',
                        (result) =>
                            Msg.VehicleComparison_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                    Api.retrieveVehicleGeographic(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        state.page.VehicleComparisonGeographic.vehicleStatus ===
                            'Sold',
                        (result) =>
                            Msg.VehicleComparisonGeographic_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'VehicleSettings_Incoming': {
            state.page.VehicleSettings.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, []];
        }

        case 'VehicleSettings_OnToggleInfoBlock': {
            state.page.VehicleSettings.isInfoBlockVisible =
                !state.page.VehicleSettings.isInfoBlockVisible;
            return [state, []];
        }

        case 'VehicleSettings_IncomingSave': {
            state.page.VehicleSettings.isSaving = false;

            Result.match(msg.payload.result, {
                Ok: (value) => {
                    state.page.VehicleSettings.data = Async.markAsLoaded(
                        state.page.VehicleSettings.data,
                        value,
                    );
                    state.page.VehicleSettings.changes = {};
                },
                Err: () => {},
            });

            return [state, []];
        }

        case 'VehicleSettings_IncomingReset': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.VehicleSettings.isResetting = false;

            if (!msg.payload.wasSuccessful) {
                return [state, []];
            }

            state.page.VehicleSettings.data = Async.markAsLoading(
                state.page.VehicleSettings.data,
            );
            state.page.VehicleSettings.changes = {};

            return [
                state,
                [
                    Api.retrieveVehicleCompetitionSettings(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        (result) =>
                            Msg.VehicleSettings_Incoming({
                                vehicleId: msg.payload.vehicleId,
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'VehicleSettings_OnChange': {
            state.page.VehicleSettings.changes = msg.payload.value;

            return [state, []];
        }

        case 'VehicleSettings_OnSave': {
            if (state.account === null) {
                return [state, []];
            }

            if (Object.keys(state.page.VehicleSettings.changes).length === 0) {
                return [state, []];
            }

            const originalValue = Async.value(state.page.VehicleSettings.data);

            if (originalValue === undefined) {
                return [state, []];
            }

            const value: VehicleSettings = {
                ...originalValue,
                ...state.page.VehicleSettings.changes,
            };

            state.page.VehicleSettings.isSaving = true;

            return [
                state,
                [
                    Api.updateVehicleCompetitionSettings(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        value,
                        (result) =>
                            Msg.VehicleSettings_IncomingSave({
                                vehicleId: msg.payload.vehicleId,
                                result: Result.map(result, () => value),
                            }),
                    ),
                ],
            ];
        }

        case 'VehicleSettings_OnCancel': {
            state.page.VehicleSettings.changes = {};

            return [state, []];
        }

        case 'VehicleSettings_OnReset': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.VehicleSettings.isResetting = true;

            return [
                state,
                [
                    Api.resetVehicleCompetitionSettings(
                        state.account.accessToken,
                        msg.payload.vehicleId,
                        (wasSuccessful) =>
                            Msg.VehicleSettings_IncomingReset({
                                vehicleId: msg.payload.vehicleId,
                                wasSuccessful,
                            }),
                    ),
                ],
            ];
        }

        case 'SettingsNotifications_Incoming': {
            state.page.SettingsNotifications.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, []];
        }

        case 'SettingsNotifications_IncomingSave': {
            state.page.SettingsNotifications.isSaving = false;

            Result.match(msg.payload.result, {
                Ok: () => {
                    state.page.SettingsNotifications.changes = {};
                },
                Err: () => {},
            });

            return [state, []];
        }

        case 'SettingsNotifications_IncomingReset': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsNotifications.isResetting = false;

            if (!msg.payload.wasSuccessful) {
                return [state, []];
            }

            state.page.SettingsNotifications.data = Async.markAsLoading(
                state.page.SettingsNotifications.data,
            );
            state.page.SettingsNotifications.changes = {};

            return [
                state,
                [
                    Api.retrieveNoticationsSettings(
                        state.account.accessToken,
                        (result) =>
                            Msg.SettingsNotifications_Incoming({ result }),
                    ),
                ],
            ];
        }

        case 'SettingsNotifications_IncomingSendTestMail': {
            state.page.SettingsNotifications.isSendingTestMail = false;

            return [state, []];
        }

        case 'SettingsNotifications_OnSendTestMail': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsNotifications.isSendingTestMail = true;

            return [
                state,
                [
                    Api.sendTestMail(
                        state.account.accessToken,
                        Msg.SettingsNotifications_IncomingSendTestMail,
                    ),
                ],
            ];
        }

        case 'SettingsNotifications_OnChange': {
            state.page.SettingsNotifications.changes = msg.payload;

            return [state, []];
        }

        case 'SettingsNotifications_OnSave': {
            if (state.account === null) {
                return [state, []];
            }

            if (
                Object.keys(state.page.SettingsNotifications.changes).length ===
                0
            ) {
                return [state, []];
            }

            const originalValue = Async.value(
                state.page.SettingsNotifications.data,
            );

            if (originalValue === undefined) {
                return [state, []];
            }

            const value: NotificationSettings = {
                ...originalValue,
                ...state.page.SettingsNotifications.changes,
            };

            state.page.SettingsNotifications.isSaving = true;

            return [
                state,
                [
                    Api.updateNotificationsSettings(
                        state.account.accessToken,
                        value,
                        (result) =>
                            Msg.SettingsNotifications_IncomingSave({ result }),
                    ),
                ],
            ];
        }

        case 'SettingsNotifications_OnReset': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsNotifications.isResetting = true;

            return [
                state,
                [
                    Api.resetNotificationsSettings(
                        state.account.accessToken,
                        (wasSuccessful) =>
                            Msg.SettingsNotifications_IncomingReset({
                                wasSuccessful,
                            }),
                    ),
                ],
            ];
        }

        case 'SettingsNotifications_OnCancel': {
            state.page.SettingsNotifications.changes = {};

            return [state, []];
        }

        case 'SettingsStock_Incoming': {
            state.page.SettingsStock.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, cacheExcludedVehicles(state)];
        }

        case 'SettingsStock_IncomingVehicleCount': {
            state.page.SettingsStock.stockCount = Async.fromResult(
                msg.payload,
                state.page.SettingsStock.stockCount,
            );

            return [state, []];
        }

        case 'SettingsStock_IncomingSave': {
            state.page.SettingsStock.isSaving = false;

            return Result.match(msg.payload.result, {
                Ok: () => {
                    state.page.SettingsStock.changes = {};

                    return [
                        state,
                        [
                            Api.retrieveStockSettings(
                                state.account!.accessToken,
                                (result) =>
                                    Msg.SettingsStock_Incoming({ result }),
                            ),
                            Api.retrieveSession(
                                state.account!.accessToken,
                                (result) =>
                                    Msg.Incoming_StockSelection(
                                        Result.map(result, (payload) => ({
                                            total: payload.totalStock,
                                            selected: payload.filteredStock,
                                        })),
                                    ),
                            ),
                            retrieveSettingsStockVehicleCount(
                                state.account!.accessToken,
                            ),
                        ],
                    ];
                },
                Err: () => [state, []],
            });
        }

        case 'SettingsStock_IncomingReset': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsStock.isResetting = false;

            if (!msg.payload.wasSuccessful) {
                return [state, []];
            }

            state.page.SettingsStock.data = Async.markAsLoading(
                state.page.SettingsStock.data,
            );
            state.page.SettingsStock.changes = {};

            return [
                state,
                [
                    Api.retrieveStockSettings(
                        state.account.accessToken,
                        (result) => Msg.SettingsStock_Incoming({ result }),
                    ),
                ],
            ];
        }

        case 'SettingsStock_OnChange': {
            state.page.SettingsStock.changes = msg.payload;

            return [state, cacheExcludedVehicles(state)];
        }

        case 'SettingsStock_OnSave': {
            if (state.account === null) {
                return [state, []];
            }

            if (Object.keys(state.page.SettingsStock.changes).length === 0) {
                return [state, []];
            }

            const originalValue = Async.value(state.page.SettingsStock.data);

            if (originalValue === undefined) {
                return [state, []];
            }

            const value: SettingsStock = SettingsStockUtil.fromForm(
                SettingsStockUtil.toForm(
                    originalValue,
                    state.page.SettingsStock.changes,
                ),
            );

            state.page.SettingsStock.isSaving = true;

            return [
                state,
                [
                    Api.updateStockSettings(
                        state.account.accessToken,
                        value,
                        (result) => Msg.SettingsStock_IncomingSave({ result }),
                    ),
                ],
            ];
        }

        case 'SettingsStock_OnCancel': {
            state.page.SettingsStock.changes = {};

            return [state, []];
        }

        case 'SettingsStock_OnReset': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsStock.isResetting = true;

            return [
                state,
                [
                    Api.resetStockSettings(
                        state.account.accessToken,
                        (wasSuccessful) =>
                            Msg.SettingsStock_IncomingReset({ wasSuccessful }),
                    ),
                    retrieveSettingsStockVehicleCount(
                        state.account.accessToken,
                    ),
                ],
            ];
        }

        case 'SettingsStock_OnSearchVehicle': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsStock.searchVehicles = Async.markAsLoading(
                state.page.SettingsStock.searchVehicles,
            );

            return [
                state,
                [
                    Api.retrieveDealerVehicles(
                        state.account.accessToken,
                        {
                            search: msg.payload,
                            page: 1,
                            amountPerPage: 10,
                            sort: 'merk_model_uitv',
                            sortDirection: 'ASC',
                            onlyWithWarnings: false,
                        },
                        (result) =>
                            Msg.SettingsStock_IncomingSearchVehicle(
                                Result.map(result, ({ vehicles }) => vehicles),
                            ),
                    ),
                ],
            ];
        }

        case 'SettingsStock_IncomingSearchVehicle': {
            state.page.SettingsStock.searchVehicles = Async.fromResult(
                msg.payload,
                state.page.SettingsStock.searchVehicles,
            );

            return [state, []];
        }

        case 'SettingsCompetingVehicles_Incoming': {
            state.page.SettingsCompetingVehicles.data = Async.fromResult(
                msg.payload.result,
            );

            return [state, []];
        }

        case 'SettingsCompetingVehicles_IncomingSave': {
            state.page.SettingsCompetingVehicles.isSaving = false;

            Result.match(msg.payload.result, {
                Ok: () => {
                    state.page.SettingsCompetingVehicles.changes = {};
                },
                Err: () => {},
            });

            return [state, []];
        }

        case 'SettingsCompetingVehicles_IncomingReset': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingVehicles.isResetting = false;

            if (!msg.payload.wasSuccessful) {
                return [state, []];
            }

            state.page.SettingsCompetingVehicles.data = Async.markAsLoading(
                state.page.SettingsCompetingVehicles.data,
            );
            state.page.SettingsCompetingVehicles.changes = {};

            return [
                state,
                [
                    Api.retrieveVehicleCompetitionSettings(
                        state.account.accessToken,
                        '0',
                        (result) =>
                            Msg.SettingsCompetingVehicles_Incoming({ result }),
                    ),
                ],
            ];
        }

        case 'SettingsCompetingVehicles_OnChange': {
            state.page.SettingsCompetingVehicles.changes = msg.payload.value;

            return [state, []];
        }

        case 'SettingsCompetingVehicles_OnSave': {
            if (state.account === null) {
                return [state, []];
            }

            if (
                Object.keys(state.page.SettingsCompetingVehicles.changes)
                    .length === 0
            ) {
                return [state, []];
            }

            const originalValue = Async.value(
                state.page.SettingsCompetingVehicles.data,
            );

            if (originalValue === undefined) {
                return [state, []];
            }

            const value: VehicleSettings = {
                ...originalValue,
                ...state.page.SettingsCompetingVehicles.changes,
            };

            state.page.SettingsCompetingVehicles.isSaving = true;

            return [
                state,
                [
                    Api.updateVehicleCompetitionSettings(
                        state.account.accessToken,
                        '0',
                        value,
                        (result) =>
                            Msg.SettingsCompetingVehicles_IncomingSave({
                                result,
                            }),
                    ),
                ],
            ];
        }

        case 'SettingsCompetingVehicles_OnReset': {
            if (state.account === null) {
                return [state, []];
            }

            state.page.SettingsCompetingVehicles.isResetting = true;

            return [
                state,
                [
                    Api.resetVehicleCompetitionSettings(
                        state.account.accessToken,
                        '0',
                        (wasSuccessful) =>
                            Msg.SettingsCompetingVehicles_IncomingReset({
                                wasSuccessful,
                            }),
                    ),
                ],
            ];
        }

        case 'SettingsCompetingVehicles_OnCancel': {
            state.page.SettingsCompetingVehicles.changes = {};

            return [state, []];
        }

        default:
            exhaustiveCheck(msg);
    }

    return [state, []];
}

function onRouteToVehiclePage([state, cmd]: [Model, Cmd<Msg>]): [
    Model,
    Cmd<Msg>,
] {
    if (state.account === null) {
        return [state, cmd];
    }

    switch (state.route.id) {
        case 'VehicleDashboard':
        case 'VehicleSettings':
        case 'VehicleReachOverview':
        case 'VehicleReachPriceEffect':
        case 'VehicleComparisonOverview':
        case 'VehicleComparisonPriceAnalysis':
        case 'VehicleComparisonPriceHistory':
        case 'VehicleComparisonGeographic': {
            state.cachedVehicles = CacheMap.clearExpired(
                vehicleCachePredicate,
                state.cachedVehicles,
            );

            const vehicleId = state.route.params.vehicleId;
            const cachedVehicle = CacheMap.getValue(
                vehicleId,
                state.cachedVehicles,
            );

            if (cachedVehicle === undefined) {
                return [
                    state,
                    [
                        ...cmd,
                        Api.retrieveVehicle(
                            state.account.accessToken,
                            vehicleId,
                            Msg.Incoming_Vehicle,
                        ),
                    ],
                ];
            }
        }
    }

    return [state, cmd];
}

function onRouteToTaxationPage([state, cmd]: [Model, Cmd<Msg>]): [
    Model,
    Cmd<Msg>,
] {
    if (state.account === null) {
        return [state, cmd];
    }

    switch (state.route.id) {
        case 'TaxationDashboard':
        case 'TaxationSettings':
        case 'TaxationComparisonOverview':
        case 'TaxationComparisonPriceAnalysis':
        case 'TaxationComparisonPriceHistory':
        case 'TaxationComparisonGeographic': {
            state.cachedVehicles = CacheMap.clearExpired(
                vehicleCachePredicate,
                state.cachedVehicles,
            );

            const vehicleId = state.route.params.vehicleId;
            const cachedVehicle = CacheMap.getValue(
                vehicleId,
                state.cachedVehicles,
            );

            if (cachedVehicle === undefined) {
                return [
                    state,
                    [
                        ...cmd,
                        Api.retrieveTaxation(
                            state.account.accessToken,
                            vehicleId,
                            Msg.Incoming_Vehicle,
                        ),
                    ],
                ];
            }
        }
    }

    return [state, cmd];
}

function vehicleCachePredicate(_: unknown, createdOn: Date): boolean {
    return createdOn.valueOf() + 24 * 60 * 60 * 1000 < Date.now();
}

function onRouteToPageWithDealers([state, cmd]: [Model, Cmd<Msg>]): [
    Model,
    Cmd<Msg>,
] {
    if (!Async.isIdle(state.settings.dealers)) {
        return [state, cmd];
    }

    state.settings.dealers = Async.markAsLoading(state.settings.dealers);

    return [
        state,
        [
            ...cmd,
            Api.retrieveSettingsScenarioDealers(
                state.account!.accessToken,
                Msg.Incoming_Dealers,
            ),
        ],
    ];
}

function onComparisonProfileChange(payload: string, state: Model): Model {
    state.page.ComparisonAdvicePrice.activeProfile = payload;
    state.page.ComparisonDaysInStock.activeProfile = payload;
    state.page.ComparisonGeographic.activeProfile = payload;

    return state;
}

function onComparisonMakeFilterChange(
    payload: Set<string>,
    availableModels: ReadonlyArray<{ make: string; model: string }>,
    selectedModels: Set<string>,
    state: Model,
): Model {
    const models = new Set(
        availableModels
            .filter((model) => payload.has(model.make))
            .filter((model) => selectedModels.has(model.model))
            .map((model) => model.model),
    );

    state.page.ComparisonAdvicePrice.makeFilter = payload;
    state.page.ComparisonDaysInStock.makeFilter = payload;
    state.page.ComparisonAdvicePrice.modelFilter = models;
    state.page.ComparisonDaysInStock.modelFilter = models;

    return state;
}

function onComparisonModelFilterChange(
    payload: Set<string>,
    state: Model,
): Model {
    state.page.ComparisonAdvicePrice.modelFilter = payload;
    state.page.ComparisonDaysInStock.modelFilter = payload;

    return state;
}

function onVehicleComparisonStatusChange(
    payload: VehicleCompetitionStatus,
    state: Model,
): Model {
    state.page.VehicleComparisonOverview.vehicleStatus = payload;
    state.page.VehicleComparisonPriceAnalysis.vehicleStatus = payload;
    state.page.VehicleComparisonPriceHistory.vehicleStatus = payload;
    state.page.VehicleComparisonGeographic.vehicleStatus = payload;

    return state;
}

function onTaxationComparisonStatusChange(
    payload: VehicleCompetitionStatus,
    state: Model,
): Model {
    state.page.TaxationComparisonOverview.vehicleStatus = payload;
    state.page.TaxationComparisonPriceAnalysis.vehicleStatus = payload;
    state.page.TaxationComparisonPriceHistory.vehicleStatus = payload;
    state.page.TaxationComparisonGeographic.vehicleStatus = payload;

    return state;
}

function cacheExcludedVehicles(state: Model): Cmd<Msg> {
    const isCached = (vehicleId: string): boolean => {
        return CacheMap.getValue(vehicleId, state.cachedVehicles) !== undefined;
    };

    return Async.match(state.page.SettingsStock.data, {
        Loaded: ({ excludedvehicleids }) => {
            if (state.account === null) return [];

            const accessToken = state.account.accessToken;
            const vehicleIds: string[] = [
                ...excludedvehicleids,
                ...Array.from(
                    state.page.SettingsStock.changes.excludedvehicleids ??
                        new Set<string>(),
                ),
            ];

            return vehicleIds
                .filter((x: string): boolean => !isCached(x))
                .map((vehicleId: string) =>
                    Api.retrieveVehicle(
                        accessToken,
                        vehicleId,
                        Msg.Incoming_Vehicle,
                    ),
                );
        },
        Idle: () => [],
        Loading: () => [],
        Failed: () => [],
    });
}

function retrieveDealerStock(
    authentication: string,
    state: Model['page']['Stock'],
): Promise<Msg> {
    return Api.retrieveDealerVehicles(
        authentication,
        {
            page: state.page,
            amountPerPage: state.amountPerPage,
            sort: state.sort,
            sortDirection: state.sortDirection,
            search: state.search === '' ? undefined : state.search,
            onlyWithWarnings: state.onlyWithWarnings,
        },
        Msg.Stock_Incoming,
    );
}

function retrieveDealerLeads(
    authentication: string,
    state: Model['page']['Leads'],
): Promise<Msg> {
    return Api.retrieveDealerLeads(
        authentication,
        {
            sort: state.sort,
            period: state.period,
            search: state.search,
            filter: state.filter,
            page: state.page,
            amountPerPage: state.amountPerPage,
        },
        Msg.Leads_Incoming,
    );
}

function retrieveDealerProspects(
    authentication: string,
    state: Model['page']['Prospects'],
): Promise<Msg> {
    return Api.retrieveDealerProspects(
        authentication,
        { period: state.period },
        Msg.Prospects_Incoming,
    );
}

function retrieveAnalysisOverview(
    authentication: string,
    state: Model['page']['AnalysisOverview'],
): Promise<Msg> {
    return Api.retrieveDealerAnalysisOverview(
        authentication,
        state.profile,
        Msg.AnalysisOverview_Incoming,
    );
}

function retrieveAnalysisLeadPerformance(
    authentication: string,
    state: Model['page']['AnalysisLeadPerformance'],
): Promise<Msg> {
    return Api.retrieveLeadsPerformanceAnalysis(
        authentication,
        state.period,
        Msg.AnalysisLeadPerformance_Incoming,
    );
}

function retrieveComparisonAdvicePrice(
    authentication: string,
    fallbackProfile: CompetitionProfile,
    state: Model['page']['ComparisonAdvicePrice'],
): Promise<Msg> {
    return Api.retrieveComparison(
        authentication,
        {
            profile: state.activeProfile ?? fallbackProfile.id,
            makes: state.makeFilter,
            models: state.modelFilter,
        },
        Msg.ComparisonAdvicePrice_Incoming,
    );
}

function retrieveComparisonDaysInStock(
    authentication: string,
    fallbackProfile: CompetitionProfile,
    state: Model['page']['ComparisonDaysInStock'],
): Promise<Msg> {
    return Api.retrieveComparison(
        authentication,
        {
            profile: state.activeProfile ?? fallbackProfile.id,
            makes: state.makeFilter,
            models: state.modelFilter,
        },
        Msg.ComparisonDaysInStock_Incoming,
    );
}

function retrieveComparisonGeographic(
    authentication: string,
    fallbackProfile: CompetitionProfile,
    state: Model['page']['ComparisonGeographic'],
): Promise<Msg> {
    return Api.retrieveComparison(
        authentication,
        {
            profile: state.activeProfile ?? fallbackProfile.id,
            makes: new Set(),
            models: new Set(),
        },
        Msg.ComparisonGeographic_Incoming,
    );
}

function retrieveCompetitionDealers(
    authentication: string,
    state: Model,
): Promise<Msg> {
    return Api.searchDealers(
        authentication,
        {
            search: state.page.SettingsCompetingCompaniesProfile.filters.search,
            dealerTypes:
                state.page.SettingsCompetingCompaniesProfile.filters
                    .dealerTypes,
            provinces:
                state.page.SettingsCompetingCompaniesProfile.filters.provinces,
            dealerMakes:
                state.page.SettingsCompetingCompaniesProfile.filters
                    .dealerMakes,
            stockMakes:
                state.page.SettingsCompetingCompaniesProfile.filters.stockMakes,
        },
        (result) =>
            Msg.SettingsCompetingDealersProfile_IncomingDealers({
                filters: state.page.SettingsCompetingCompaniesProfile.filters,
                result,
            }),
    );
}

function retrieveSettingsStockVehicleCount(
    authentication: string,
): Promise<Msg> {
    return Api.retrieveDealerVehicles(
        authentication,
        {
            page: 1,
            amountPerPage: 10,
            sort: 'merk_model_uitv',
        },
        (result) =>
            Msg.SettingsStock_IncomingVehicleCount(
                Result.map(result, ({ totalVehicles }) => totalVehicles),
            ),
    );
}

function toTaxationWidget(
    dispatch: Dispatch<Msg>,
    state: Model,
):
    | {
          licensePlate: string;
          mileage: string;
          errorMessage: string | null;
          onLicensePlateChange: (value: string) => void;
          onMileageChange: (value: string) => void;
          onSubmit: () => void;
          onClearErrorMessage: () => void;
      }
    | undefined {
    if (state.deviceLayout !== 'Desktop') return;
    if (state.account?.type !== 'Dealer') return;

    return {
        licensePlate: state.taxationWidget.licensePlate,
        mileage: state.taxationWidget.mileage,
        errorMessage: state.taxationWidget.errorMessage,
        onLicensePlateChange: dispatch(Msg.TaxationWidget_OnInputLicensePlate),
        onMileageChange: dispatch(Msg.TaxationWidget_OnInputMileage),
        onSubmit: dispatch(Msg.TaxationWidget_OnSubmit),
        onClearErrorMessage: dispatch(Msg.TaxationWidget_OnClearErrorMessage),
    };
}

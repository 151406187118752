import {
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    endOfYear,
    formatISO,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    StartOfWeekOptions,
    startOfYear,
    subDays,
    subMonths,
    subQuarters,
    subWeeks,
    subYears,
} from 'date-fns';

type DateCalculator = (currentDate: Date) => Date;

export function toISODateString(date: Date): string {
    return formatISO(date, { representation: 'date' });
}

export const yesterday: DateCalculator = (currentDate) =>
    subDays(currentDate, 1);

//---- DateRange ----//

export type DateRange = [start: Date, end: Date];

type DateRangeCalculator = (currentDate: Date) => DateRange;

const weekStartsOnMonday: StartOfWeekOptions = { weekStartsOn: 1 };

export function hasOverlappingRange(
    range1: DateRange,
    range2: DateRange,
): boolean {
    const [start1, end1] = range1;
    const [start2, end2] = range2;
    return start1 <= end2 && start2 <= end1;
}

export function clampEndDate(range: DateRange, maxDate: Date): DateRange {
    const [startDate, endDate] = range;
    if (endDate > maxDate) {
        return [startDate, maxDate];
    }
    return range;
}

export const thisWeek: DateRangeCalculator = (currentDate) => {
    const startDate = startOfWeek(currentDate, weekStartsOnMonday);
    const endDate = endOfWeek(currentDate, weekStartsOnMonday);
    return [startDate, endDate];
};

export const lastWeek: DateRangeCalculator = (currentDate) => {
    const startDate = startOfWeek(subWeeks(currentDate, 1), weekStartsOnMonday);
    const endDate = endOfWeek(subWeeks(currentDate, 1), weekStartsOnMonday);
    return [startDate, endDate];
};

export const last7Days: DateRangeCalculator = (currentDate) => {
    const startDate = subDays(currentDate, 6);
    const endDate = currentDate;
    return [startDate, endDate];
};

export const thisMonth: DateRangeCalculator = (currentDate) => {
    const startDate = startOfMonth(currentDate);
    const endDate = endOfMonth(currentDate);
    return [startDate, endDate];
};

export const lastMonth: DateRangeCalculator = (currentDate) => {
    const startDate = startOfMonth(subMonths(currentDate, 1));
    const endDate = endOfMonth(subMonths(currentDate, 1));
    return [startDate, endDate];
};

export const last30Days: DateRangeCalculator = (currentDate) => {
    const startDate = subDays(currentDate, 29);
    const endDate = currentDate;
    return [startDate, endDate];
};

export const thisQuarter: DateRangeCalculator = (currentDate) => {
    const startDate = startOfQuarter(currentDate);
    const endDate = endOfQuarter(currentDate);
    return [startDate, endDate];
};

export const lastQuarter: DateRangeCalculator = (currentDate) => {
    const startDate = startOfQuarter(subQuarters(currentDate, 1));
    const endDate = endOfQuarter(subQuarters(currentDate, 1));
    return [startDate, endDate];
};

export const last90Days: DateRangeCalculator = (currentDate) => {
    const startDate = subDays(currentDate, 89);
    const endDate = currentDate;
    return [startDate, endDate];
};

export const thisYear: DateRangeCalculator = (currentDate) => {
    const startDate = startOfYear(currentDate);
    const endDate = endOfYear(currentDate);
    return [startDate, endDate];
};

export const lastYear: DateRangeCalculator = (currentDate) => {
    const startDate = startOfYear(subYears(currentDate, 1));
    const endDate = endOfYear(subYears(currentDate, 1));
    return [startDate, endDate];
};

export const last365Days: DateRangeCalculator = (currentDate) => {
    const startDate = subDays(currentDate, 364);
    const endDate = currentDate;
    return [startDate, endDate];
};
